import React, {Suspense} from 'react';
import Tryfree from './components/Tryfree';
import VideoBanner from './components/VideoBanner';
import ProductService from './components/ProductService';
import Deployment from './components/Deployment';
import Customer from './components/Customer';
const Scenario =  React.lazy(() => import('./components/Scenario'));

function Home() {
    return (
        <div style={{
            position: 'relative',
        }}
        >
            <VideoBanner />
            <ProductService />
            <Suspense fallback={null}>
                <Scenario />
            </Suspense>
            <Deployment />
            <Customer />
            <Tryfree />
        </div>
    );
}
export default Home;
