import { useState } from 'react';
import right from '../../assets/images/components/rightArrow.svg';
import left from '../../assets/images/components/leftArrow.svg';
import rightActive from '../../assets/images/components/rightArrowActive.svg';
import leftActive from '../../assets/images/components/leftArrowActive.svg';
import './style.css';

const SolutionCarousel = ({ title, id, data}) => {
    const [start, setStart] = useState(0);

    const children = data.slice(start, start+2);

    return (
        <div className="SolutionCarousel" id={id}>
            <div className="solutionCarouselTitle">{title}</div>
            <div className="solutionCarouselContent">
                {
                    // start = 0 就不能左走了
                    start > 0 ?
                        <img
                            src={leftActive}
                            className="solutionActiveArrow"
                            alt="left arrow"
                            onClick={() => setStart(start-1)}
                        /> :
                        <img src={left} className="solutionArrow" alt="left arrow" />
                }
                {
                    children.map(item => {
                        return (
                            <div className="solutionCarouselCard" key={item.id}>
                                <div className="solutionCarouselItemTitle">{item.title}</div>
                                <div className="solutionCarouselItemDesc">{item.description}</div>
                                <img src={item.img} alt={item.title} className="solutionCarouselImg" />
                            </div>
                        )
                    })
                }
                {
                    // start = length 就不能右走了
                    start < data.length - 2 ?
                        <img
                            src={rightActive}
                            className="solutionActiveArrow"
                            alt="right arrow"
                            onClick={() => setStart(start+1)}
                        /> :
                        <img src={right} className="solutionArrow" alt="right arrow" />
                }
            </div>
        </div>
    );
};

export default SolutionCarousel;
