import React from 'react';
import {Link} from 'react-router-dom';
import './style.css'

const FloatContact = () => {
    return (
        <div className="float-body">
            <Link target="_blank" to="/contact" className="float-title">联系我们</Link>
        </div>
    );
};

export default FloatContact;
