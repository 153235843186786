import bannerImg from '../../../assets/images/aitrain/banner.png';
import workImg from '../../../assets/images/aitrain/work.png';
import workActiveImg from '../../../assets/images/aitrain/workActive.png';
import modalImg from '../../../assets/images/aitrain/modal.png';
import modalActiveImg from '../../../assets/images/aitrain/modalActive.png';
import manageImg from '../../../assets/images/aitrain/manage.png';
import manageActiveImg from '../../../assets/images/aitrain/manageActive.png';
import estimateImg from '../../../assets/images/aitrain/estimate.png';
import estimateActiveImg from '../../../assets/images/aitrain/estimateActive.png';
import carouselImg1 from '../../../assets/images/aitrain/carousel1.png';
import carouselImg2 from '../../../assets/images/aitrain/carousel2.png';
import carouselImg3 from '../../../assets/images/aitrain/carousel3.png';
import carouselIcon1 from '../../../assets/images/aitrain/carouselIcon1.png';
import carouselIcon2 from '../../../assets/images/aitrain/carouselIcon2.png';
import carouselIcon3 from '../../../assets/images/aitrain/carouselIcon3.png';
import scenariosImg from '../../../assets/images/aitrain/scenarios.png';
// TODO  feature缺一个icon

export const bannerData = {
    title: '模型训练',
    description: ['提供全套模型研发服务，提供了从模型训练、模型评估到模型发布的全生命周期管理服务，便捷的Notebook算法研发环境，帮助用户更快的构建、训练和发布模型。'],
    img: bannerImg,
    contact: true,
};

export const menuData = [
    {
        id: 'feature',
        title: '产品功能介绍',
    },
    {
        id: 'advantage',
        title: '产品优势',
    },
    {
        id: 'scenarios',
        title: '应用场景',
    }
];

export const featureData = {
    id: 'feature',
    title: '产品功能',
    children: [
        {
            id: 0,
            img: workImg,
            activeImg: workActiveImg,
            title: '自定义作业',
            desc: '提供高性能的计算环境，用于进行大规模分布式的模型训练及优化。支持不同框架下编写代码，进行多轮训练和迭代。模型训练成果可存储、可发布。',
        },
        {
            id: 1,
            img: modalImg,
            activeImg: modalActiveImg,
            title: 'notebook模型研发',
            desc: 'Notebook 是一个在线的交互式开发环境，为算法工程师提供数据处理、算法调试与模型训练等支持。',
        },
        {
            id: 2,
            img: manageImg,
            activeImg: manageActiveImg,
            title: '模型管理',
            desc: '提供统一存储的模型仓库，可导入所有训练任务生成的模型并进行统一管理。',
        },
        {
            id: 3,
            img: estimateImg,
            activeImg: estimateActiveImg,
            title: '模型评估',
            desc: '支持常见评估指标如准确率、召回率、mAP等指标计算。',
        }
    ]
};

export const advantageData = {
    title: '产品优势',
    id: 'advantage',
    items: [
        {
            id: 0,
            title: '模型全周期管理',
            desc: '提供从数据处理、模型训练、模型管理到模型部署的全生命周期管理服务，帮助用户更快的构建、训练和部署模型。',
            img: carouselImg1,
            icon: carouselIcon1,
        },
        {
            id: 1,
            title: '专业高效',
            desc: '开发人员无需关注底层资源管理、运维和开发环境准备，通过开箱即用的云端机器学习开发环境，提升开发者的效率，加速汽车领域的智能化进程。',
            img: carouselImg2,
            icon: carouselIcon2,
        },
        {
            id: 2,
            title: '加速迭代',
            desc: '针对量产试运营阶段的智驾模型迭代，专门提供数据驱动的模型研发闭环，支持从运营中发现模型能力弱项，收集并标注数据用以持续训练模型，不断提升模型能力，解决自动驾驶的长尾问题。',
            img: carouselImg3,
            icon: carouselIcon3,
        },
    ]
};

export const scenariosData = {
    title: '应用场景',
    id: 'scenarios',
    img: scenariosImg,
    scenarios: [
        {
            id: 0,
            title: '大规模模型训练',
            img: scenariosImg,
            desc: '当模型需要大规模训练时，可以使用自定义的训练任务，支持多种框架，多机多卡分布式训练，快速输出训练结果。',
        },
        {
            id: 1,
            title: '模型研发初期',
            img: scenariosImg,
            desc: '通过Jupyter Notebooks大力助推模型原型开发阶段。在notebook中，代码按独立单元的形式编写，且可以被独立执行。支持用户测试一个项目中的特定代码块，而无需从项目开始处执行代码。',
        },
    ]
};

