import {Component} from 'react';
import './band.css';

/**
 * 品牌组件
 */
class Band extends Component {
    constructor(props) {
        super(props);
        this.getLabelKlass = this.getLabelKlass.bind(this);
    }

    /**
     * 获取 label 的 klass
     * @param {number} index index
     * @return {string} klass
     */
    getLabelKlass(index) {
        const klassIndex = index % 3;
        switch (klassIndex) {
            case 0:
                return 'c1';
            case 1:
                return 'c1';
            case 2:
                return 'c1';
        }
    }

    render() {
        const itemLen = this.props.items.length;
        return (
            <div className="band-container steps">
                <div className="band-inner">
                    <div>
                        {
                            this.props.items.map((item, itemIndex) => (
                                <div className="band-wrap" key={itemIndex}>
                                    <header className="top-title">{item.topTitle}</header>
                                    <ul className={`${itemLen === 0 || itemLen === 1 ? 'center' : ''}`}>
                                        {
                                            item.data.map((dataItem, dataIndex) => (
                                                <li key={dataIndex}>
                                                    <div className="band-logo">
                                                        <img src={dataItem.icon} alt="" />
                                                    </div>
                                                    <p className="band-title">{dataItem.title}</p>
                                                    <p className={
                                                        [
                                                            'bandLabel',
                                                            this.props.doubleColor && 'labelDoubleColor',
                                                        ].join(' ')
                                                    }
                                                    >
                                                        {
                                                            dataItem.labels.map((labelItem, labelIndex) => (
                                                                <span
                                                                    key={labelIndex}
                                                                    className={this.getLabelKlass(labelIndex)}
                                                                >{labelItem}
                                                                </span>
                                                            ))
                                                        }
                                                    </p>
                                                    <p className="band-desc">{dataItem.desc}</p>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default Band;
