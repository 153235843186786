import dataBg from '../../../../assets/images/home/dataBg.png';
import developBg from '../../../../assets/images/home/developBg.png';
import testBg from '../../../../assets/images/home/testBg.png';
import operationBg from '../../../../assets/images/home/operationBg.png';
import supervisionBg from '../../../../assets/images/home/supervisionBg.png';
import dataVideo from '../../../../assets/images/home/data.mp4';
import developVideo from '../../../../assets/images/home/develop.mp4';
import testVideo from '../../../../assets/images/home/test.mp4';
import operationVideo from '../../../../assets/images/home/operation.mp4';
import supervisionVideo from '../../../../assets/images/home/supervision.mp4';

const ProductServiceData = {
    title: '高效、稳定的产品服务',
    content: [
        {
            icon: dataVideo,
            text: '数据',
            data: {
                title: '数据平台',
                description: '采标存管用的一体化解决方案，提供自动驾驶研发过程中的水电煤。',
                number:[
                    {
                        title: '数据标注',
                        value: '1',
                        unit: '亿帧'
                    },
                    {
                        title: '日均上传数据',
                        value: '400',
                        unit: 'TB'
                    },
                    {
                        title: '数据管理',
                        value: '1000',
                        unit: 'PB'
                    }
                ],
                detail: [
                    {
                        name: '数据采集',
                        description: '专业采集车队及平台服务，满足各种数据采集场景',
                        backgroundImage: dataBg,
                        content: [
                            {
                                name: '采集计划（有采绘资质）',
                                link: '/product/collectionplan'
                            },
                            {
                                name: '采集车队（无采绘资质）',
                                link: ''
                            }
                        ]
                    },
                    {
                        name: '数据传输',
                        description: '支持大规模实时及离线数据上传、分级、清洗',
                        backgroundImage: dataBg,
                        content: [
                            {
                                name: '落盘数据上传',
                                link: '/product/offline'
                            },
                            {
                                name: '实时数据回传',
                                link: ''
                            }
                        ]
                    },
                    {
                        name: '数据标注',
                        description: '高质量、高效率的自动化标注、人工+算法辅助标注能力',
                        backgroundImage: dataBg,
                        content: [
                            {
                                link: '/product/annotationtool',
                                name: '标注工具'
                            },
                            {
                                link: '',
                                name: '算法能力'
                            },
                            {
                                link: '',
                                name: '可视化配置'
                            },
                            {
                                link: '',
                                name: '标注流程自动化'
                            },
                            {
                                link: '',
                                name: '标注管理'
                            },
                        ],
                    },
                    {
                        name: '数据管理',
                        description: '具备亿级别数据管理使用实际经验',
                        backgroundImage: dataBg,
                        content: [
                            {
                                name: '智驾数据集',
                                link: '/product/drivingdataset'
                            },
                            {
                                name: '源数据管理',
                                link: ''
                            },
                            {
                                name: '高精地图',
                                link: ''
                            },
                            {
                                name: '工作流引擎',
                                link: '/product/workflow'
                            },
                            {
                                name: '数据湖管理与分析',
                                link: ''
                            }
                        ]
                    },
                    {
                        name: '数据应用',
                        description: '支持复杂数据可视化，使得数据更好被理解',
                        backgroundImage: dataBg,
                        content: [
                            {
                                name: '数据回放',
                                link: '/product/issuefinder'
                            },
                            {
                                name: '数据闭环',
                                link: ''
                            },
                            {
                                name: '数据挖掘',
                                link: ''
                            }
                        ]
                    },
                ]
            },
        },
        {
            icon: developVideo,
            text: '研发',
            data: {
                title: '研发平台',
                description: '互联网敏捷开发与车企V研发模型的深度融合，基于研发服务，实现天粒度算法上路，周粒度正式OTA。',
                number: [
                    {
                        title: '模型更新频率',
                        value: '14',
                        unit: '天/次'
                    },
                    {
                        title: '模型迭代频率',
                        value: '24',
                        unit: '小时/次'
                    },
                    {
                        title: '模型训练并发量',
                        value: '1000+',
                        unit: '人'
                    }
                ],
                detail: [
                    {
                        name: 'AI训练',
                        description: '提供模型研发全生命周期管理服务，帮助用户更快的构建、训练和发布模型',
                        backgroundImage: developBg,
                        content: [
                            {
                                link: '',
                                name: '模型训练'
                            },
                            {
                                link: '',
                                name: '模型评测'
                            },
                            {
                                link: '',
                                name: '模型仓库'
                            },
                            {
                                link: '',
                                name: '数据集管理'
                            },
                            {
                                link: '',
                                name: 'badcase管理'
                            },
                        ]
                    },
                    {
                        name: 'DevOps',
                        description: '围绕自动驾驶研发流程打造DevOps组件，提升项目管理和代码交付效率',
                        backgroundImage: developBg,
                        content: [
                            {
                                link: '',
                                name: '项目管理'
                            },
                            {
                                link: '',
                                name: '代码服务'
                            },
                            {
                                link: '',
                                name: '持续交付'
                            },
                        ],
                    },
                    {
                        name: 'Apollo研发',
                        description: '提供Apollo研发环境，课基于开源Apollo进行二次开发',
                        backgroundImage: developBg,
                        content: [
                            {
                                link: '',
                                name: '环境运行'
                            },
                            {
                                link: '',
                                name: '代码部署'
                            },
                            {
                                link: '',
                                name: 'OTA'
                            },
                        ],
                    },
                ]
            }
        },
        {
            icon: testVideo,
            text: '仿真',
            data: {
                title: '仿真平台',
                description: '提供最全驾驶能力定义、千级语义场景、千万级case场景库，帮助车企精准刻画智能驾驶能力。',
                number: [
                    {
                        title: '场景覆盖率',
                        value: '98',
                        unit: '%'
                    },
                    {
                        title: '场景库',
                        value: '1100w',
                        unit: ''
                    },
                    {
                        title: '仿真日行',
                        value: '1000w',
                        unit: '公里'
                    }
                ],
                detail: [
                    {
                        name: '自动驾驶综合测试',
                        description: '流式测试，形成闭环迭代链路，保证每个OTA版本经充分验证',
                        backgroundImage: testBg,
                        content: [
                            {
                                link: '/product/sil',
                                name: '软件在环SIL'
                            },
                            {
                                link: '',
                                name: '硬件在环HIL'
                            },
                            {
                                link: '',
                                name: '车辆在环VIL'
                            },
                            {
                                link: '',
                                name: '无人化测试场'
                            },
                        ]
                    },
                    {
                        name: '场景',
                        description: '海量自动驾驶场景，实现精细化分级管理',
                        backgroundImage: testBg,
                        content: [
                            {
                                link: '/product/scenelibrary',
                                name: '场景库'
                            },
                            {
                                link: '',
                                name: '场景挖掘'
                            },
                            {
                                link: '/product/sceneplatform',
                                name: '场景设计平台'
                            },
                        ]
                    },
                    {
                        name: '仿真验证',
                        description: '通过云与仿真能力，记录&复现车辆运行真实数据和软件算法的决策过程',
                        backgroundImage: testBg,
                        content: [
                            {
                                link: '/product/designsimulation',
                                name: '人工设计仿真'
                            },
                            {
                                link: '/product/playback',
                                name: '数据回放仿真'
                            },
                            {
                                link: '',
                                name: '真实数据重建仿真'
                            },
                            {
                                link: '',
                                name: '虚拟城市仿真'
                            },
                            {
                                link: '',
                                name: '传感器仿真'
                            },
                        ],
                    },
                    {
                        name: '测试管理',
                        description: '为测试各项活动制定一个现实可行的、综合的计划，实现周期自动化测试验证',
                        backgroundImage: testBg,
                        content: [
                            {
                                link: '',
                                name: '离线测试管理'
                            },
                            {
                                link: '',
                                name: '封闭场地测试管理'
                            },
                            {
                                link: '',
                                name: '开放道路测试管理'
                            },
                        ],
                    },
                    {
                        name: '评估',
                        description: '提供整车级、模块级指标，可在宏观视角、特征场景实现智能化度量和多维度比对',
                        backgroundImage: testBg,
                        content: [
                            {
                                link: '',
                                name: '能力评估'
                            },
                            {
                                link: '',
                                name: '标准化度量指标'
                            },
                            {
                                link: '',
                                name: '评估报告'
                            },
                        ],
                    },
                ]
            }
        },
        {
            icon: operationVideo,
            text: '运营',
            data: {
                title: '运营平台',
                description: '提供自动驾驶日常的运营服务，助力客户提升MPI、用户体感和订单量。',
                number: [
                    {
                        title: '车企合作',
                        value: '70+',
                        unit: '家'
                    },
                    {
                        title: '车队规模',
                        value: '500',
                        unit: '辆'
                    },
                    {
                        title: 'MPI平均数',
                        value: '29000',
                        unit: '公里'
                    }
                ],
                detail: [
                    {
                        name: '运营资源管理',
                        description: '以项目维度对运营车辆、路侧设备等运营资源进行统一管理、分配、维护',
                        backgroundImage: operationBg,
                        content: [
                            {
                                link: '/product/autocarmanage',
                                name: '无人车资源管理'
                            },
                            {
                                link: '',
                                name: '运营区域信息管理'
                            },
                            {
                                link: '',
                                name: '项目资源管理'
                            },
                        ]
                    },
                    {
                        name: '车辆运营管理',
                        description: '实现运营车辆及路侧设备实时信息上报，支持运营订单信息监控及运营调度',
                        backgroundImage: operationBg,
                        content: [
                            {
                                link: '/product/planmanage',
                                name: '出车计划管理'
                            },
                            {
                                link: '',
                                name: '车辆调度管理'
                            },
                            {
                                link: '',
                                name: '运营订单管理'
                            },
                        ],
                    },
                    {
                        name: '车辆运营统计',
                        description: '以时空维度统计展示运营车辆及设备运营信息',
                        backgroundImage: operationBg,
                        content: [
                            {
                                link: '',
                                name: '车辆使用统计'
                            },
                            {
                                link: '',
                                name: '订单信息统计'
                            },
                            {
                                link: '',
                                name: '运营里程统计'
                            },
                            {
                                link: '',
                                name: '自动驾驶指标统计'
                            },
                        ]
                    },
                ]
            }
        },
        {
            icon: supervisionVideo,
            text: '监管',
            data: {
                title: '监管平台',
                description: '提供车辆监控服务、路侧监控服务、辅助信息融合和管控服务，通过定制化的大屏方案满足多样化监管要求。',
                number: [
                    {
                        title: '覆盖城市',
                        value: '27',
                        unit: '个'
                    },
                    {
                        title: '覆盖范围',
                        value: '391',
                        unit: '平方公里'
                    },
                    {
                        title: '路测里程',
                        value: '1200w',
                        unit: '公里'
                    }
                ],
                detail: [
                    {
                        name: '车辆监控',
                        description: '实现事中、事后的精准监管',
                        backgroundImage: supervisionBg,
                        content: [
                            {
                                link: '',
                                name: '车载单元集成维护'
                            },
                            {
                                link: '',
                                name: '数据获取和上传'
                            },
                        ]
                    },
                    {
                        name: '路侧监控',
                        description: '多变、复杂的路侧情况，便捷可视化的管控',
                        backgroundImage: supervisionBg,
                        content: [
                            {
                                link: '',
                                name: '设备部署及维护'
                            },
                            {
                                link: '',
                                name: '路侧设备监控'
                            },
                            {
                                link: '',
                                name: '路侧数据采集'
                            },
                        ]
                    },
                    {
                        name: '信息融合',
                        description: '支持多种信息融合，让监管效果大幅提升',
                        backgroundImage: supervisionBg,
                        content: [
                            {
                                link: '',
                                name: '高精地图路网管理'
                            },
                            {
                                link: '',
                                name: '交通规则数据管理'
                            },
                            {
                                link: '',
                                name: '测试牌照管理'
                            },
                            {
                                link: '',
                                name: '测试计划管理'
                            },
                        ]
                    },
                    {
                        name: '管控服务',
                        description: '自主可控、安全可靠的管控服务',
                        backgroundImage: supervisionBg,
                        content: [
                            {
                                link: '',
                                name: '测试运营数据分析'
                            },
                            {
                                link: '',
                                name: '行驶效果监控'
                            },
                            {
                                link: '',
                                name: '车辆状态监控'
                            },
                            {
                                link: '',
                                name: '违章行为监控'
                            },
                        ]
                    },
                ]
            }
        }
    ],
};

export default ProductServiceData;
