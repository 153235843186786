import AboutUsData from './data';
import Card from './card';

const FunctionCard = () => {
    return (
        <>
            <div
                className="functionTitle"
                style={{
                    fontFamily: 'PingFangSC-Light',
                    fontSize: '36px',
                    color: '#333',
                    textAlign: 'center',
                    fontWeight: 200,
                    marginTop: '92px',
                }}
            >产品介绍
            </div>
            {
                AboutUsData.map((item, index) => {
                    return <Card key={index} {...item} />
                })
            }
        </>
    );
};

export default FunctionCard;
