import { useEffect, useState } from 'react';
import './style.css';

const menuFixed = () => {
    const menu = document.getElementById('solutionMenu');
    const adjustMenu = function () {
        const currentY = document.documentElement.scrollTop || document.body.scrollTop;
        if (menu.offsetTop <= currentY) {
            menu.style.position = 'fixed';
            menu.style.top = '60px';
            menu.style.background = '#fff';
            menu.style.borderTop = '1px solid #e5e5e5';
            menu.style.zIndex = '9';
        } else {
            menu.style.position = '';
            menu.style.borderTop = 'none';
        }
    };
    window.addEventListener('scroll', adjustMenu);
    adjustMenu();
};

const SolutionMenu = ({ menuData }) => {
    console.log(menuData);
    const [current, setCurrent] = useState(menuData[0].id);
    const [scroll, setScroll] = useState(false);

    const changeMenuItem = (topList) => {
        const adjustMenuItem = function () {
            const currentY = document.documentElement.scrollTop || document.body.scrollTop;
            topList.forEach(item => {
                if ( Math.abs(currentY - item.top) < 200 ) {
                    setCurrent(item.id);
                }
            });
        }
        window.addEventListener('scroll', adjustMenuItem);
    }

    useEffect(() => {
        menuFixed();
        const topList = menuData.map(item => {
            return {
                ...item,
                top: document.getElementById(item.id).offsetTop
            };
        });
        changeMenuItem(topList);
    }, []);

    useEffect(() => {
        if (scroll) {
            const targetY = document.getElementById(current).offsetTop;
            const currentY = document.documentElement.scrollTop || document.body.scrollTop;
            if (Math.abs(targetY - currentY) >= 20) {
                window.scrollTo(0, targetY - 150);
                setScroll(false);
            }
        }
    }, [current, scroll]);

    return (
        <div className="solutionMenu" id="solutionMenu">
            {menuData.map(menu => {
                return (
                    <div
                        className={
                            current === menu.id ? 'activeMenuItem':'solutionMenuItem'
                        }
                        key={menu.id}
                        onClick={() => {
                            setCurrent(menu.id);
                            setScroll(true);
                        }}
                    >
                        {menu.title}
                    </div>
                );
            })}
        </div>
    );
};

export default SolutionMenu;
