import { useNavigate } from 'react-router-dom';
import './style.css';

const SolutionBanner = (data) => {
    const navigate = useNavigate();

    return (
        <div className="newBanner">
            <img src={data.img} alt="" className="solutionBannerImg" />
            <div className="solutionBannerContent">
                <div className="solutionBannerTitle">{data.title}</div>
                <div className="solutionBannerDesc">{data.description}</div>
                {
                    data.contact ? <div className="solutionBannerBtn" onClick={() => navigate('/contact')}>立即咨询</div> : null
                }
            </div>
        </div>
    );
};

export default SolutionBanner;
