import bannerImg from '../../assets/images/search/banner.png';
import dataIcon from '../../assets/images/search/data.png';
import searchIcon from '../../assets/images/search/search.png';
import applicationIcon from '../../assets/images/search/application.png';
import architectureImg from '../../assets/images/search/architecture.png';
import advantageImg from '../../assets/images/search/advantage.jpg';
import imageSearchImg from '../../assets/images/search/imageSearch.png';
import featureImg from '../../assets/images/search/feature.png';
import continuousDataImg from '../../assets/images/search/continuousData.png';
import modelImg from '../../assets/images/search/model.png';
import customer1Img from '../../assets/images/search/customer1.png';

export const bannerData = {
    title: '大模型数据智搜',
    description: '数据智搜，结合百度多年的搜索服务积累，基于文心大模型能力，落地自动驾驶"数据智能搜索引擎"，为用户提供数据的一键检索服务，包括以文搜图、以图搜图、图文结合等检索方式，实现『更准确、更高效、更快速、低成本』的高价值数据挖掘。',
    img: bannerImg,
};

export const menuData = [
    {
        id: 'challenge',
        title: '业务挑战',
    },
    {
        id: 'architecture',
        title: '方案架构',
    },
    {
        id: 'advantage',
        title: '方案优势',
    },
    {
        id: 'product',
        title: '推荐产品',
    },
    {
        id: 'case',
        title: '客户案例',
    }
];

export const challengeData = {
    title: '业务挑战',
    id: 'challenge',
    children: [
        {
            id: 0,
            icon: dataIcon,
            title: '高价值数据提炼',
            description: '伴随自动驾驶产业的高速发展，车路端产生海量的数据信息中存在大量冗余数据，可对其进行精准化搜索提炼。'
        },
        {
            id: 1,
            icon: searchIcon,
            title: '数据搜索边界',
            description: '传统模型可识别场景的有限，通过百度大模型能力，实现模型识别边界指数级别提升，提高整体搜索效率。'
        },
        {
            id: 2,
            icon: applicationIcon,
            title: '长尾数据应用',
            description: '量产阶段数据场景长尾化，使用数据特征识别，可对特定场景定向进行数据挖掘。'
        },
    ]
};

export const architectureData = {
    title: '解决方案架构',
    id: 'architecture',
    img: architectureImg,
    description: [
        '以大模型数据挖掘能力为基础，搭建智能化数据场景挖掘平台，通过建立数据闭环链路，完成车端数据接入、特定场景数据挖掘、标准化数据封装的自动化流程，为自动化标注、仿真场景搭建、智驾模型训练提供数据基础，极大提高量产数据的使用效率，助力自动驾驶行业的整体发展。',
    ]
};

export const advantageData = {
    title: '方案优势',
    id: 'advantage',
    img: advantageImg,
    leftDesc: [
        {
            id: 0,
            title: '冷启动数据生产',
            subtitle: ['特定目标数据挖掘，可从海量的业务数据中快速完成高质量长尾信息的挖掘，实现从0到1新模型能力的产出。']
        },
        {
            id: 1,
            title: '难例挖掘',
            subtitle: ['支持挖掘业务模型价值高的难样本数据，如突发场景数据、复杂天气数据等，以满足不同阶段模型反哺训练的需求，持续提升模型能力。']
        },

    ],
    rightDesc: [
        {
            id: 2,
            title: '伪标注知识迁移',
            subtitle: ['百度的大模型底座可对业务数据进行伪标注处理，处理结果通过伪标注迁移给业务小模型，低成本实现的高精度小模型生成。']
        },
    ]
};

export const productData = {
    title: '推荐产品',
    id: 'product',
    children: [
        {
            id: 0,
            title: '以文搜图-以图搜图',
            description: '对文字图片信息进行语义意图理解，并实现相关静态图片数据信息的智能搜索。',
            img: imageSearchImg,
            link: ''
        },
        {
            id: 1,
            title: '特征搜索',
            description: '针对复杂场景，支持正反例校验搜索，通过大模型的正反例对比验证能力，精准完成制定目标挖掘。',
            img: featureImg,
            link: ''
        },
        {
            id: 2,
            title: '时序场景搜索',
            description: '动态视频数据的定向挖掘能力，通过视频信息的意图识别，挖掘出同类场景的视频数据。',
            img: continuousDataImg,
            link: ''
        },
        {
            id: 3,
            title: '能力延伸',
            description: '对挖掘出的图片、视频等数据进行标准化分装，以任务形态进行自动标注、场景搭建、模型训练业务场景拓展。',
            img: modelImg,
            link: ''
        }
    ]
};

export const caseData = {
    title: '客户案例',
    id: 'case',
    children: [
        {
            id: 0,
            title: '头部车企数据智搜平台',
            description: '支撑某头部车企进行大模型数据搜索挖掘能力落地，完成智搜平台搭建，并建立挖掘数据的应用的完整流程，实现挖掘数据的自动标注、场景搭建、模型训练的数据闭环，推动自动驾驶能力的量产化进程。',
            img: customer1Img
        },
    ]
};
