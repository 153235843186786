import bannerImg from '../../../assets/images/sil/banner.png';
import simulationImg from '../../../assets/images/sil/simulation.png';
import simulationActiveImg from '../../../assets/images/sil/simulationActive.png';
import testImg from '../../../assets/images/sil/test.png';
import testActiveImg from '../../../assets/images/sil/testActive.png';
import closeLoopImg from '../../../assets/images/sil/closeloop.png';
import closeLoopActiveImg from '../../../assets/images/sil/closeloopActive.png';
import carouselImg1 from '../../../assets/images/sil/carousel1.png';
import carouselImg2 from '../../../assets/images/sil/carousel2.png';
import carouselImg3 from '../../../assets/images/sil/carousel3.png';
import carouselImg4 from '../../../assets/images/sil/carousel4.png';
import carouselIcon1 from '../../../assets/images/sil/carouselIcon1.png';
import carouselIcon2 from '../../../assets/images/sil/carouselIcon2.png';
import carouselIcon3 from '../../../assets/images/sil/carouselIcon3.png';
import carouselIcon4 from '../../../assets/images/sil/carouselIcon4.png';
import scenariosImg from '../../../assets/images/sil/scenarios.jpg';

export const bannerData = {
    title: '软件在环（SIL）',
    description: ['通过云与仿真能力，记录&复现车辆运行真实数据和软件算法的决策过程，提供基于云计算和高并发技术的在线高级自动驾驶仿真测试能力及相关服务，以实现感知、预测、规划、控制等多模块算法在系统内测试验证。'],
    img: bannerImg,
    contact: true,
};

export const menuData = [
    {
        id: 'feature',
        title: '产品功能介绍',
    },
    {
        id: 'advantage',
        title: '产品优势',
    },
    {
        id: 'scenarios',
        title: '应用场景',
    }
];

export const advantageData = {
    title: '产品优势',
    id: 'advantage',
    items: [
        {
            id: 0,
            title: '云端大规模仿真',
            desc: '高性能仿真工具和公共云计算资源，可满足大规模测试需求，是安全上路测试必要条件。',
            img: carouselImg1,
            icon: carouselIcon1,
        },
        {
            id: 1,
            title: '海量测试度量工具',
            desc: '包括场景能力、交通安全和体感相关度量指标，基于仿真实现自动驾驶智能度、安全性、舒适性评估。',
            img: carouselImg2,
            icon: carouselIcon2,
        },
        {
            id: 2,
            title: '高仿真置信度',
            desc: '每一帧数据流的顺序与实车严格一致，保障单模块与实车的对比真实度达到90%以上。',
            img: carouselImg3,
            icon: carouselIcon3,
        },
        {
            id: 3,
            title: '高场景复现率',
            desc: '利用历史积累的大规模道路测试数据，对真实道路测试场景的分布深入统计分析，保证高场景复现率。',
            img: carouselImg4,
            icon: carouselIcon4,
        }
    ]
};

export const featureData = {
    id: 'feature',
    title: '产品功能',
    children: [
        {
            id: 0,
            img: simulationImg,
            activeImg: simulationActiveImg,
            title: '仿真执行引擎',
            desc: '提供传感器模型、动力学模型和仿真引擎，以保证自动驾驶算法能够有序高效地运行。',
        },
        {
            id: 1,
            img: testImg,
            activeImg: testActiveImg,
            title: '自动化测试',
            desc: '智驾测试方法论产品化，从Check-in-Daily-Release 逐级测试与验证，核心问题分钟级发现与解决。',
        },
        {
            id: 2,
            img: closeLoopImg,
            activeImg: closeLoopActiveImg,
            title: '场景闭环管理',
            desc: '形成场景设计/场景挖掘-场景库-仿真测试完整闭环，自循环驱动智能驾驶能力持续提升。',
        }
    ]
}

export const scenariosData = {
    title: '应用场景',
    id: 'scenarios',
    img: scenariosImg,
    scenarios: [
        {
            id: 0,
            title: '研发过程自测',
            img: scenariosImg,
            desc: '满足自动驾驶算法的功能验证与测试环节，可集成CI平台以实现代码coding后自动化测试验证，并针对研发所需搭建特定场景做结果验证。',
        },
        {
            id: 1,
            title: 'CICD闭环',
            img: scenariosImg,
            desc: '集DevOps实践、Apollo的研发理念于一体，以流式测试过程保证每个OTA版本经充分验证，加快自动驾驶技术的研发和测试。',
        }
    ]
};

