import bannerImg from '../../../assets/images/planmanage/banner.png';
import featureImg1 from '../../../assets/images/planmanage/featureImg1.png';
import featureActiveImg1 from '../../../assets/images/planmanage/featureActiveImg1.png';
import featureImg2 from '../../../assets/images/planmanage/featureImg2.png';
import featureActiveImg2 from '../../../assets/images/planmanage/featureActiveImg2.png';
import featureImg3 from '../../../assets/images/planmanage/featureImg3.png';
import featureActiveImg3 from '../../../assets/images/planmanage/featureActiveImg3.png';
import carouselImg1 from '../../../assets/images/planmanage/carousel1.png';
import carouselImg2 from '../../../assets/images/planmanage/carousel2.png';
import carouselIcon1 from '../../../assets/images/planmanage/carouselIcon1.png';
import carouselIcon2 from '../../../assets/images/planmanage/carouselIcon2.png';
import scenariosImg1 from '../../../assets/images/planmanage/scenarios1.png';


export const bannerData = {
    title: '出车计划管理',
    description: ['在运营车辆的使用环节，以时间维度制定车辆的出车计划，可按计划维度对车辆进行监控管理，同时支持车辆使用长期计划制定及临时计划插入。'],
    img: bannerImg,
    contact: true,
};

export const menuData = [
    {
        id: 'feature',
        title: '产品功能介绍',
    },
    {
        id: 'advantage',
        title: '产品优势',
    },
    {
        id: 'scenarios',
        title: '应用场景',
    },
    {
        id: 'customer',
        title: '客户案例',
    }
];

export const featureData = {
    id: 'feature',
    title: '产品功能',
    children: [
        {
            id: 0,
            img: featureImg1,
            activeImg: featureActiveImg1,
            title: '用车计划创建',
            desc: '为保障运营车辆的灵活使用，支持长期出车任务的制定及每日车辆出车任务的生成。',
        },
        {
            id: 1,
            img: featureImg2,
            activeImg: featureActiveImg2,
            title: '出车任务分配',
            desc: '运营车辆使用过程中，为运营车辆分配司机（安全员），实现从车辆侧及司机侧全流程线上管理。',
        },
        {
            id: 2,
            img: featureImg3,
            activeImg: featureActiveImg3,
            title: '实际执行监控',
            desc: '对实际执行运营任务进行监控，监控模块支持逐条查询运营任务的数据，实时展示任务进展、接单情况等信息。',
        }
    ]
}

export const advantageData = {
    title: '产品优势',
    id: 'advantage',
    items: [
        {
            id: 0,
            title: '管理灵活',
            desc: '支持长、中、短期运营计划的制定，实时监控计划进度，灵活调整。',
            img: carouselImg1,
            icon: carouselIcon1,
        },
        {
            id: 1,
            title: '场景丰富',
            desc: '根据不同类型运营场景进行出车计划制定，支持站点互达、固定线路等多种模式。',
            img: carouselImg2,
            icon: carouselIcon2,
        },
    ]
};

export const scenariosData = {
    title: '应用场景',
    id: 'scenarios',
    img: scenariosImg1,
    scenarios: [
        {
            id: 0,
            title: '运营车辆长期计划制定',
            img: scenariosImg1,
            desc: '根据实际业务使用场景，创建并管理长期用车计划，支持对已创建的用车计划进行编辑或删除。',
        },
        {
            id: 1,
            title: '每日计划管理',
            img: scenariosImg1,
            desc: '将用车计划拆解成每日的出车任务，可查看出车任务的详情信息，并对当日需要执行任务的车辆进行司机（安全员）分配，已分配出车任务的司机可在个人工作台查看待执行的出车任务。',
        },
    ]
};

export const customerData = {
    title: '客户案例',
    id: 'customer',
    name: '某地方交管部门',
    desc: '为了缓解交通压力，方便市民出行，推进城市智能交通建设。建设试点区域，探索、研究智能网联云控平台以智能网联车辆作为采集终端的自动化驾驶无人车辆的城市道路运营。'
}


