import c from './style.module.css'
const ProductCustomerCase = ({ title, id, name, desc }) => {
    return (
        <div className={c.productCustomerCase} id={id}>
            <div className={c.title}>{title}</div>
            <div className={c.content}>
                <div className={c.name}>{name}</div>
                <div className={c.desc}>{desc}</div>
            </div>
        </div>
    );
}

export default ProductCustomerCase;
