import bannerImg from '../../assets/images/annotation/banner.png';
import securityIcon from '../../assets/images/annotation/security.png';
import qualityIcon from '../../assets/images/annotation/quality.png';
import efficiencyIcon from '../../assets/images/annotation/efficiency.png';
import costIcon from '../../assets/images/annotation/cost.png';
import architectureImg from '../../assets/images/annotation/architecture.png';
import advantageImg from '../../assets/images/develop/advantage.jpg';
import notebookImg from '../../assets/images/annotation/2d.png';
import repositoryImg from '../../assets/images/annotation/3d.png';
import annotationImg from '../../assets/images/annotation/annotation.png';
import channelImg from '../../assets/images/annotation/channel.png';
import manageImg from '../../assets/images/annotation/manage.png';
import customer1Img from '../../assets/images/annotation/customer1.png';
import customer2Img from '../../assets/images/annotation/customer2.png';

export const bannerData = {
    title: '数据采集标注解决方案',
    description: '使用低成本高效率的众包模式满足客户对AI数据的需求，可采集大量的原始数据，通过数据加工，为客户交付标准化、结构化的可用数据，帮助客户训练算法模型、开展机器学习，提高AI领域的竞争力。',
    img: bannerImg,
};

export const menuData = [
    {
        id: 'challenge',
        title: '业务挑战',
    },
    {
        id: 'architecture',
        title: '方案架构',
    },
    {
        id: 'advantage',
        title: '方案优势',
    },
    {
        id: 'product',
        title: '推荐产品',
    },
    {
        id: 'case',
        title: '客户案例',
    }
];

export const challengeData = {
    title: '业务挑战',
    id: 'challenge',
    children: [
        {
            id: 0,
            icon: securityIcon,
            title: '数据安全脆弱',
            description: '人工智能数据的获取有安全合规要求，需用户授权允许采集和训练，若滥用或通过非合法手段获取，易产生法务风险。'
        },
        {
            id: 1,
            icon: qualityIcon,
            title: '数据质量难保障',
            description: '训练数据的质量严重影响算法有效性，作坊企业缺乏人员管理和质检手段，数据质量参差不齐，数据质量难以得到有效保障。'
        },
        {
            id: 2,
            icon: efficiencyIcon,
            title: '数据处理效率低',
            description: '自有团队难以快速扩张，外部小型代理商管理混乱，整体缺乏科学的项目管理流程，数据处理效率明显不足。'
        },
        {
            id: 3,
            icon: costIcon,
            title: '投入成本高',
            description: '自建数据采集、标注团队模式过重，且需要一套完整的工具和流程支撑，人力、技术和工具投入成本过高。'
        }
    ]
};

export const architectureData = {
    title: '解决方案架构',
    id: 'architecture',
    img: architectureImg,
    description: [
        '高效率提供海量标注数据，用于算法研发、训练评估、仿真测试，打造数据智能化标注驱动的研发闭环和数据场景挖掘为主线的仿真测试闭环。推动高阶自动驾驶算法迭代和研发新模式的安全上路。'
    ]
};

export const advantageData = {
    title: '方案优势',
    id: 'advantage',
    img: advantageImg,
    leftDesc: [
        {
            id: 0,
            title: '数据保障更安全',
            subtitle: ['严格的法务监管流程，安全的私有化数据部署，防数据泄漏的答题管理机制，实时监控和加密的标注设备，保证数据安全无风险。']
        },
        {
            id: 1,
            title: '数据质量更精准',
            subtitle: ['严格的人员培训作业机制和三轮数据审核机制，并辅以智能审核算法和智能化管理平台，保障数据质量远高于行业平均水平。']
        }
    ],
    rightDesc: [
        {
            id: 2,
            title: '处理速度更高效',
            subtitle: ['百位项目方案专家，两千百度山西基地全职标注，两万签约外场专职标注，3万名众包在线标注用户，实现百万级数据处理能力。']
        },
        {
            id: 3,
            title: '支付费用更优惠',
            subtitle: ['凭借自建标注基地、科学众包任务分发模式、智能化数据采集与标注工具，实现规模效应和高效作业，降低成本使付费客户受益。']
        },
    ]
};

export const productData = {
    title: '推荐产品',
    id: 'product',
    children: [
        {
            id: 0,
            title: '2D工具',
            description: '支持筛选、标记、框选、标点、线、区域标注类型等，支持连续帧标注，支持数据自动切片。',
            img: notebookImg,
            link: ''
        },
        {
            id: 1,
            title: '3D工具',
            description: '支持标脑补框、紧致框、多边形等，连续帧标注，支持数据自动切片，自由旋转、俯视旋转、主车中心等功能；支持任意角度标框，3d框、3d语义支持图片投影辅助标注，支持俯视、后视、侧视的三视图调框，简化调框操作。',
            img: repositoryImg,
            link: ''
        },
        {
            id: 2,
            title: '融合标注',
            description: '单一工具支持复杂融合关联标注，点云、图片、radar融合标注同时完成，3d、2d标注工具界面自由切换，方便3d、2d 数据同时标注',
            img: annotationImg,
            link: ''
        },
        {
            id: 3,
            title: '通道管理',
            description: '标注工具配置、标注属性配置、辅助标注配置、标注流程配置、标注人力配置、标注模板管理等。',
            img: channelImg,
            link: ''
        },
        {
            id: 4,
            title: '标注管理',
            description: '通过接口形式，提供数据导入功能；后台会进行相应处理，结果下载、接口查询功能，返回标注结果信息，需求增删改查等。',
            img: manageImg,
            link: ''
        }
    ]
};

export const caseData = {
    title: '客户案例',
    id: 'case',
    children: [
        {
            id: 0,
            title: '国内某头部车企自动驾驶数据平台项目',
            description: '支撑某头部车企打造全自主高阶自动驾驶平台开发，为海量数据的标注应用和域内管理提供平台和流程化技术支持，助力研发事业快速发展。',
            img: customer1Img
        },
        {
            id: 1,
            title: 'Apollo Scape数据集',
            description: '支撑百度内部Apollo平台完成海量车端数据训练验证，实现汽车智能化升级及无人驾驶的多场景应用，打通数据生产、共享和云端数据应用闭环，面向开发者提供完整的自动驾驶数据解决方案，加速创新迭代效率。',
            img: customer2Img
        }
    ]
};
