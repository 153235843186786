import SolutionBanner from '../../../components/SolutionBanner';
import SolutionMenu from '../../../components/SolutionMenu';
import ProductFeatures from '../../../components/ProductFeatures';
import ProductCarousel from '../../../components/ProductCarousel';
import ProductScenarios from '../../../components/ProductScenarios';
import ProductCustomerCase from '../../../components/ProductCustomerCase';
import {bannerData, menuData, featureData, advantageData, scenariosData, customerData} from './data';

const AutoCarManage = () => {
    return (
        <div>
            <SolutionBanner {...bannerData} />
            <SolutionMenu menuData={menuData} />
            <ProductFeatures {...featureData} />
            <ProductCarousel {...advantageData} />
            <ProductScenarios {...scenariosData} />
            <ProductCustomerCase {...customerData} />
        </div>
    );
};

export default AutoCarManage;

