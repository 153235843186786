var _hmt = _hmt || [];
if (window.location.href.includes('baidu-int')) {
    // 有baidu-int
    (function () {
        var hm = document.createElement("script");
        hm.src = "https://hm.baidu.com/hm.js?9a0d7e1812304302de8c38cb090dda74";
        var s = document.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(hm, s);
    })();
} else {
    // baidu
    (function () {
        var hm = document.createElement("script");
        hm.src = "https://hm.baidu.com/hm.js?a8d9f36efa52c5de46462c8205c30a1f";
        var s = document.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(hm, s);
    })();
}
