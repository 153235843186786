export const footerData = [
    {
        name: '热门产品',
        data: [
            {
                title: '数据处理',
                link: '/product/workflow'
            },
            {
                title: '标注工具',
                link: '/product/annotationtool'
            },
            {
                title: '模型训练',
                link: '/product/aitrain'
            },
            {
                title: '软件在环SIL',
                link: '/product/sil'
            },
            {
                title: '出车计划管理',
                link: '/product/planmanage'
            }
        ],
        // noHighlight: true
    },
    {
        name: '解决方案',
        data: [
            {
                title: '数据闭环解决方案',
                link: '/solution/data'
            },
            {
                title: 'AI研发解决方案',
                link: '/solution/develop'
            },
            {
                title: '数据采集标注解决方案',
                link: '/solution/annotation'
            },
            {
                title: '云仿真测试解决方案',
                link: '/solution/test'
            },
            {
                title: '运营监管解决方案',
                link: '/solution/operation'
            },
        ]
    },
    {
        name: '关于',
        data: [
            {
                title: '关于Apollo驾驶云',
                link: '/about'
            },
            {
                title: '联系我们',
                link: '/contact'
            }
        ]
    }
];
