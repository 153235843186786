import './style.css';

const SolutionChallenge = ({ title, id, children }) => {
    return (
        <div className="solutionChallenge" id={id}>
            <div className="challengeTitle">{title}</div>
            <div className="challengeData">{
                children.map(item => {
                    return (
                        <div className="challengeItem" key={item.id}>
                            <img className="challengeItemIcon" src={item.icon} alt='icon' />
                            <div className="challengeItemDetail">
                                <div className="challengeItemTitle">{item.title}</div>
                                <div className="challengeItemContent">{item.description}</div>
                            </div>
                        </div>
                    );
                })
            }
            </div>
        </div>
    )

};

export default SolutionChallenge;
