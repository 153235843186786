import style from './style.module.css';

const SolutionCase = ({ title, id, children}) => {
    return (
        <div className={style.solutionCase} id={id}>
            <div className={style.solutionCaseTitle}>{title}</div>
            <div className={style.solutionCaseContent}>
                {
                    children.map(item => {
                        return (
                            <div className={style.solutionCaseCard} key={item.id}>
                                <div className={style.solutionCaseItemTitle}>{item.title}</div>
                                <div className={style.solutionCaseItemDesc}>{item.description}</div>
                                <img src={item.img} alt={item.title} className={style.solutionCaseImg} />
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
};

export default SolutionCase;
