import icvdBg from '../../../../assets/images/home/icvdbg.jpg';
import neolixBg from '../../../../assets/images/home/neolixbg.jpg';
import apolloBg from '../../../../assets/images/home/apollobg.jpg';
import apolloLogo from '../../../../assets/images/home/apollo.svg';
import neolixLogo from '../../../../assets/images/home/neolix.svg';
import icvdLogo from '../../../../assets/images/home/icvd.svg';
import icvLogoNo from '../../../../assets/images/home/icvd1.svg';
import neolixLogoNo from '../../../../assets/images/home/neolix1.svg';
import apolloLogoNo from '../../../../assets/images/home/apollo1.svg';

const customerData = [
    {
        title: "上海国际汽车城",
        logo: icvdLogo,
        logoNo: icvLogoNo,
        desc: "支撑汽车城建设上海市智能网联汽车公共数据中心，打造全球首个智能网联领域公共数据平台，建立以车、路、云一体化的智能汽车数据综合管理和运行监管系统，为车辆测试与示范应用提供技术服务与安全保障，持续提升智能网联汽车领域数据服务能级，服务实现车联网应用，赋能网联汽车产业创新发展。",
        bgSrc: icvdBg
    },
    {
        title: "新石器",
        logo: neolixLogo,
        logoNo: neolixLogoNo,
        desc: "合作搭建仿真云平台，实现资产适配下的在线仿真、数据回灌仿真、大规模自动化仿真、孪生城市仿真模块场景化应用，协同并行建立满足产品开发需求的自动驾驶场景库，计划三年实现1000w 公里/天仿真量支撑。",
        bgSrc: neolixBg
    },
    {
        title: "Apollo Scape数据集",
        logo: apolloLogo,
        logoNo: apolloLogoNo,
        desc: "支撑百度内部Apollo 平台完成数据采标任务，覆盖了来自三个城市站点的周围10KM的地域，包括感知分类和路网数据等数十万帧逐像素语义分割标注的高分辨率图像数据，以及与其对应的逐像素语义标注，最终形成目前行业内环境最复杂、标注最精准、数据量最大的三维自动驾驶公开数据集。",
        bgSrc: apolloBg
    },
];

export default customerData;
