import bannerImg from '../../assets/images/operation/banner.jpg';
import supervisionIcon from '../../assets/images/operation/supervision.png';
import returnIcon from '../../assets/images/operation/return.png';
import operationIcon from '../../assets/images/operation/operation.png';
import architectureImg from '../../assets/images/operation/architecture.png';
import advantageImg from '../../assets/images/develop/advantage.jpg';
import carImg from '../../assets/images/operation/car.png';
import realtimeImg from '../../assets/images/operation/realtime.png';
import manageImg from '../../assets/images/operation/manage.png';
import customer1Img from '../../assets/images/operation/customer1.png';
import customer2Img from '../../assets/images/operation/customer2.png';

export const bannerData = {
    title: '运营监管解决方案',
    description: '以智能网联车辆和第三方平台作为数据采集终端，全方位、全区域、多层次采集和汇聚在测试或运营的智能网联车辆以及车路协同设备的实时运行检测数据，实现对所有智能网联车辆的运行和安全监管，并提供相应指导和技术服务。',
    img: bannerImg,
};

export const menuData = [
    {
        id: 'challenge',
        title: '业务挑战',
    },
    {
        id: 'architecture',
        title: '方案架构',
    },
    {
        id: 'advantage',
        title: '方案优势',
    },
    {
        id: 'product',
        title: '推荐产品',
    },
    {
        id: 'case',
        title: '客户案例',
    }
];

export const challengeData = {
    title: '业务挑战',
    id: 'challenge',
    children: [
        {
            id: 0,
            icon: supervisionIcon,
            title: '监管公信力难保证',
            description: '监管过程要求真实客观，设备具备独立采集各项数据的能力，适应复杂的车载环境。'
        },
        {
            id: 1,
            icon: returnIcon,
            title: '海量数据回传困难',
            description: '运营过程产生海量数据，数据回传需要兼顾实时性和经济性。全部回传带来巨大成本和延迟，需要精准提取、传输价值数据。'
        },
        {
            id: 2,
            icon: operationIcon,
            title: '车辆运营智能化程度低',
            description: '需要基于运营车辆原始数据进行解析挖掘和智能分析。驾驶云提供交规、风险识别、数据挖掘、运营效率等综合分析能力。'
        },
    ]
};

export const architectureData = {
    title: '解决方案架构',
    id: 'architecture',
    img: architectureImg,
    description: [
        '通过“车载终端+云平台”一体化的集成，实时回传车辆状态和数据，帮助客户实现智能网联车辆运营的全方位监管，持续优化乘坐体验和驾驶能力，提升车辆MPI、体感及订单量，是自动驾驶的加速器。',
    ]
};

export const advantageData = {
    title: '方案优势',
    id: 'advantage',
    img: advantageImg,
    leftDesc: [
        {
            id: 0,
            title: '合规可靠',
            subtitle: ['拥有甲级测绘资质保障过程合规，成熟的车载终端和云平台经过Apollo长期规模化工程验证。']
        },
        {
            id: 1,
            title: '精准回传',
            subtitle: ['配置化的回传策略，精准收集数据片段，支持在线或离线方式回传车端数据。']
        },
    ],
    rightDesc: [
        {
            id: 2,
            title: '智能分析',
            subtitle: ['基于百度强大AI能力进行数据自动化处理和智能分析，挖掘价值场景。']
        }
    ]
};

export const productData = {
    title: '推荐产品',
    id: 'product',
    children: [
        {
            id: 0,
            title: '车辆监管',
            description: '实时监控测试车辆位置和行驶状态，上报车辆异常信息，即时下发管控指令，多维度数据统计分析报表。',
            img: carImg,
            link: ''
        },
        {
            id: 1,
            title: '实时数据回传',
            description: '打造车端到云端的实时数据回传能力，帮助企业提升量产阶段的算力及算法模型效果。',
            img: realtimeImg,
            link: ''
        },
        {
            id: 2,
            title: '车辆运营管理',
            description: '提供智能驾驶车队管理、订单服务、安全规范、路线规划、车辆维护、约车解决方案，合理调配资源，客观展现运营情况。',
            img: manageImg,
            link: ''
        },
    ]
};

export const caseData = {
    title: '客户案例',
    id: 'case',
    children: [
        {
            id: 0,
            title: '某智能网联示范区测试监管平台',
            description: '智能网联车辆监控与测试平台是智能网联创新应用中心的重要支撑，服务于示范区各级部门日常运营，主要场内流程、车辆信息、测试进展、测试报告等全周期管理。',
            img: customer1Img
        },
        {
            id: 1,
            title: '大连Robotaxi车辆运营平台',
            description: '百度 Apollo 在大连金马路区域部署了Robotaxi为社会公众提供 L4 级自动驾驶载客示范运营服务，实现一站叫车、站点互达，在车流高度密集的路线进行公开运营。',
            img: customer2Img
        },
    ]
};
