import bannerImg from '../../../assets/images/annotationtool/banner.png';
import featureImg1 from '../../../assets/images/annotationtool/featureImg1.png';
import featureActiveImg1 from '../../../assets/images/annotationtool/featureActiveImg1.png';
import featureImg2 from '../../../assets/images/annotationtool/featureImg2.png';
import featureActiveImg2 from '../../../assets/images/annotationtool/featureActiveImg2.png';
import featureImg3 from '../../../assets/images/annotationtool/featureImg3.png';
import featureActiveImg3 from '../../../assets/images/annotationtool/featureActiveImg3.png';
import carouselImg1 from '../../../assets/images/annotationtool/carousel1.png';
import carouselImg2 from '../../../assets/images/annotationtool/carousel2.png';
import carouselImg3 from '../../../assets/images/annotationtool/carousel3.png';
import carouselIcon1 from '../../../assets/images/annotationtool/carouselIcon1.png';
import carouselIcon2 from '../../../assets/images/annotationtool/carouselIcon2.png';
import carouselIcon3 from '../../../assets/images/annotationtool/carouselIcon3.png';
import scenariosImg1 from '../../../assets/images/annotationtool/scenarios1.png';
import scenariosImg2 from '../../../assets/images/annotationtool/scenarios2.png';
import scenariosImg3 from '../../../assets/images/annotationtool/scenarios3.png';

export const bannerData = {
    title: '标注工具',
    description: ['提供自动驾驶场景下采集数据的标注工具，支持对2D图片、3D点云、多传感器融合等多类型数据进行标注。'],
    img: bannerImg,
    contact: true,
};

export const menuData = [
    {
        id: 'feature',
        title: '产品功能介绍',
    },
    {
        id: 'advantage',
        title: '产品优势',
    },
    {
        id: 'scenarios',
        title: '应用场景',
    }
];

export const featureData = {
    id: 'feature',
    title: '产品功能',
    children: [
        {
            id: 0,
            img: featureImg1,
            activeImg: featureActiveImg1,
            title: '视觉图片标注',
            desc: '针对图片类数据，提供2D矩形框、折线、多边型区域等多种类型的标注模式进行不同场景的标注作业。',
        },
        {
            id: 1,
            img: featureImg2,
            activeImg: featureActiveImg2,
            title: '3D点云数据标注',
            desc: '针对3D数据，进行3D空间可视化呈现，并支持对点云数据进行3D立体框，立体多边形等模式的标注作业。',
        },
        {
            id: 2,
            img: featureImg3,
            activeImg: featureActiveImg3,
            title: '多传感器融合标注',
            desc: '2D图片及3D点云数据关联标注，并支持关联障碍信息自动映射标注。',
        }
    ]
}

export const advantageData = {
    title: '产品优势',
    id: 'advantage',
    items: [
        {
            id: 0,
            title: '标注规则灵活配置',
            desc: '本产品针对不同标注场景及业务需求，可对标注工具进行灵活配置，使标注工具具有高扩展性。',
            img: carouselImg1,
            icon: carouselIcon1,
        },
        {
            id: 1,
            title: '辅助标注算法',
            desc: '在连续标注等场景下，对障碍物信息进行预测标注，提高标注效率。',
            img: carouselImg2,
            icon: carouselIcon2,
        },
        {
            id: 2,
            title: '标注信息自动关联',
            desc: '可对多传感器信息进行数据融合，在同一环节内完成多传感器数据标注，提高标注效率。',
            img: carouselImg3,
            icon: carouselIcon3,
        },
    ]
};

export const scenariosData = {
    title: '应用场景',
    id: 'scenarios',
    img: scenariosImg1,
    scenarios: [
        {
            id: 0,
            title: '障碍物标注',
            img: scenariosImg1,
            desc: '在交通场景下，对需要识别的障碍物信息进行选择标注，并对选中的障碍物进行属性信息标识。',
        },
        {
            id: 1,
            title: '语义分割场景',
            img: scenariosImg2,
            desc: '在交通场景，对2D/3D进行区域分割，将标注对象按制定区进行全景分割，并分别进行属性信息标识。',
        },
        {
            id: 2,
            title: '时序关联标注',
            img: scenariosImg3,
            desc: '在连续数据的标注场景下，对不同时序中相同障碍物进行标注。',
        },
    ]
};

