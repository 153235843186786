import SolutionBanner from '../../components/SolutionBanner';
import SolutionMenu from '../../components/SolutionMenu';
import SolutionChallenge from '../../components/SolutionChallenge';
import Architecture from '../../components/Architecture';
import SolutionAdvantage from '../../components/SolutionAdvantage';
import SolutionProducts from '../../components/SolutionProducts';
import SolutionCase from '../../components/SolutionCase';
import {bannerData, menuData, challengeData, architectureData, advantageData, productData, caseData} from './data';

const Search = () => {
    return (
        <div>
            <SolutionBanner {...bannerData} />
            <SolutionMenu menuData={menuData} />
            <SolutionChallenge {...challengeData} />
            <Architecture {...architectureData} />
            <SolutionAdvantage {...advantageData} />
            <SolutionProducts {...productData} />
            <SolutionCase {...caseData} />
        </div>
    );
};

export default Search;

