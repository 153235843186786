import bannerImg from '../../../assets/images/standardmetrics/banner.png';
import featureImg1 from '../../../assets/images/standardmetrics/featureImg1.png';
import featureActiveImg1 from '../../../assets/images/standardmetrics/featureActiveImg1.png';
import featureImg2 from '../../../assets/images/standardmetrics/featureImg2.png';
import featureActiveImg2 from '../../../assets/images/standardmetrics/featureActiveImg2.png';
import carouselImg1 from '../../../assets/images/standardmetrics/carousel1.png';
import carouselImg2 from '../../../assets/images/standardmetrics/carousel2.png';
import carouselImg3 from '../../../assets/images/standardmetrics/carousel3.png';
import carouselImg4 from '../../../assets/images/standardmetrics/carousel4.png';
import carouselImg5 from '../../../assets/images/standardmetrics/carousel5.png';
import carouselIcon1 from '../../../assets/images/standardmetrics/carouselIcon1.png';
import scenariosImg1 from '../../../assets/images/standardmetrics/scenarios1.png';
import carouselIcon2 from '../../../assets/images/standardmetrics/carouselIcon2.png';
import carouselIcon3 from '../../../assets/images/standardmetrics/carouselIcon3.png';
import carouselIcon4 from '../../../assets/images/standardmetrics/carouselIcon4.png';
import carouselIcon5 from '../../../assets/images/standardmetrics/carouselIcon5.png';


export const bannerData = {
    title: '标准化度量指标',
    description: ['经过内部一万+次迭代，形成200+个标准化度量指标，包括安全、法规、体验等类型且在行业内可高度复用。'],
    img: bannerImg,
    contact: true,
};

export const menuData = [
    {
        id: 'feature',
        title: '产品功能介绍',
    },
    {
        id: 'advantage',
        title: '产品优势',
    },
    {
        id: 'scenarios',
        title: '应用场景',
    }
];

export const featureData = {
    id: 'feature',
    title: '产品功能',
    children: [
        {
            id: 0,
            img: featureImg1,
            activeImg: featureActiveImg1,
            title: '自定义指标模版&管理',
            desc: '提供近百项评价工具，满足整车下宏观性能、特征场景，模块级 感知、预测、规划、控制智能化度量，支持根据不同测试需要自定义指标模版，对Metric做多维组合，形成通过型【通过/不通过 结论】或【数值型】数值结果计算。',
        },
        {
            id: 1,
            img: featureImg2,
            activeImg: featureActiveImg2,
            title: '自定义指标参数',
            desc: '支持对各评测算子的参数进行自定义操作配置，并可参照给出的参数合理范围进行配置，满足不同测试程度需要。',
        }
    ]
}

export const advantageData = {
    title: '产品优势',
    id: 'advantage',
    items: [
        {
            id: 0,
            title: '评测范围覆盖度高',
            desc: '评测算法涵盖了整车安全性、舒适性、智能性、交通合规性、道路通行效率和场景合理性等多方面的评测需求。评测指标可细分到整车级和算法模块级，满足不同算法模块研发人员和整车性能测试人员在不同阶段的测试需求。',
            img: carouselImg1,
            icon: carouselIcon1,
        },
        {
            id: 1,
            title: '评测结果准召率高',
            desc: '评测算法经历了大量实车路测数据的反复校验，大量的实测数据和人工评测的结果为自动化评测算法的训练提供了丰富的可靠数据集，评测结果的准确率和召回率达到90%以上。',
            img: carouselImg2,
            icon: carouselIcon2,
        },
        {
            id: 2,
            title: '评测指标配置灵活',
            desc: '评测算法向用户最大程度地开放可配置参数，并给出配置参数的合理范围。评测体系提供了逻辑维度、时间维度、交通参与者维度和空间维度的组件式度量配置方式，方便用户基于原子化的度量算子任意组合实现自定义的评测配置项。',
            img: carouselImg3,
            icon: carouselIcon3,
        },
        {
            id: 3,
            title: '评测算法运行高效',
            desc: '评测算法通过多线程并行化的程序架构设计，使得同一时刻不同评测项之间并发运算，大大提升了程序运行的效率，将典型自动驾驶场景数据的度量评测运行时间限制在一分钟以内。',
            img: carouselImg4,
            icon: carouselIcon4,
        },
        {
            id: 4,
            title: '评测报表友好性高',
            desc: '评测报表体系直观地向用户展示多维度的数据指标，反映自动驾驶算法各个模块之间的复杂联系，以充分和确凿的数据来衡量自动驾驶算法在安全、交规、体感、智能等各个维度上的性能表现。',
            img: carouselImg5,
            icon: carouselIcon5,
        },
    ]
};

export const scenariosData = {
    title: '应用场景',
    id: 'scenarios',
    img: scenariosImg1,
    scenarios: [
        {
            id: 0,
            title: '自动驾驶能力测试量化',
            img: scenariosImg1,
            desc: '对自动驾驶的开发与测试提供可量化指标与验收标准，便于进行数据分析与优化。',
        },
    ]
};

