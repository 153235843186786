import bannerImg from '../../../assets/images/collectionplan/banner.png';
import featureImg1 from '../../../assets/images/collectionplan/featureImg1.png';
import featureActiveImg1 from '../../../assets/images/collectionplan/featureActiveImg1.png';
import featureImg2 from '../../../assets/images/collectionplan/featureImg2.png';
import featureActiveImg2 from '../../../assets/images/collectionplan/featureActiveImg2.png';
import featureImg3 from '../../../assets/images/collectionplan/featureImg3.png';
import featureActiveImg3 from '../../../assets/images/collectionplan/featureActiveImg3.png';
import carouselImg1 from '../../../assets/images/collectionplan/carousel1.png';
import carouselImg2 from '../../../assets/images/collectionplan/carousel2.png';
import carouselIcon1 from '../../../assets/images/collectionplan/carouselIcon1.png';
import carouselIcon2 from '../../../assets/images/collectionplan/carouselIcon2.png';
import scenariosImg1 from '../../../assets/images/collectionplan/scenarios1.png';


export const bannerData = {
    title: '采集计划',
    description: ['采集计划管理系统，可将用户车队及采集任务进行平台化管理。对资源合理有效的利用和运维，实现采集任务的规范化、流程化。'],
    img: bannerImg,
    contact: true,
};

export const menuData = [
    {
        id: 'feature',
        title: '产品功能介绍',
    },
    {
        id: 'advantage',
        title: '产品优势',
    },
    {
        id: 'scenarios',
        title: '应用场景',
    },
];

export const featureData = {
    id: 'feature',
    title: '产品功能',
    children: [
        {
            id: 0,
            img: featureImg1,
            activeImg: featureActiveImg1,
            title: '采集监控',
            desc: '提供采集员及采集任务两个纬度的监控视角，实现线上线下统一化管理，满足用户对采集流程的全方位管理。',
        },
        {
            id: 1,
            img: featureImg2,
            activeImg: featureActiveImg2,
            title: '采集任务',
            desc: '支持制图、感知等采集需求的任务拆解，任务可分配给不同的采集员及车辆，采集完成的数据与任务形成关联，帮助用户追溯数据来源。',
        },
        {
            id: 2,
            img: featureImg3,
            activeImg: featureActiveImg3,
            title: '资源管理',
            desc: '提供采集车辆、采集员、采集区域的信息化管理，支持车辆维修、人员请假、区域下线等特殊情况处理，确保行车安全、人员及车辆不带病上岗。',
        }
    ]
}

export const advantageData = {
    title: '产品优势',
    id: 'advantage',
    items: [
        {
            id: 0,
            title: '采集经验丰富',
            desc: '基于百度内部多业务实践打磨的采集管理流程，帮助用户提升采集管理效率。',
            img: carouselImg1,
            icon: carouselIcon1,
        },
        {
            id: 1,
            title: '流程可追溯',
            desc: '数据从采集到应用的全生命周期内，都可追溯跟踪。',
            img: carouselImg2,
            icon: carouselIcon2,
        },
    ]
};

export const scenariosData = {
    title: '应用场景',
    id: 'scenarios',
    img: scenariosImg1,
    scenarios: [
        {
            id: 0,
            title: '自动驾驶数据采集',
            img: scenariosImg1,
            desc: '对自动驾驶车辆、道路等数据进行采集，可用于感知算法迭代、高精地图制作等业务。',
        },
    ]
};


