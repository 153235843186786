/* eslint-disable react/no-render-return-value */
import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import successIcon from '../../assets/images/contact/success.svg';
import errorIcon from '../../assets/images/contact/error.svg';

import "./style.css";

const div = document.createElement("div");
document.body.appendChild(div);

function notice(args) {
    return ReactDOM.render(<Message {...args} />, div);
}

let timer;

function Message(props) {
    const [msgs, setMsgs] = useState([]);
    const { content, type } = props;

    const iconObj = {
        success: successIcon,
        error: errorIcon,
    };

    useEffect(() => {
        setMsgs([...msgs, props]);
    }, [props]);

    useEffect(() => {
        if (msgs.length) {
            let msgscopy = JSON.parse(JSON.stringify(msgs));

            // setInterval 写法
            clearInterval(timer);
            timer = setInterval(
                (setMsgs) => {
                    msgscopy.shift();
                    setMsgs(JSON.parse(JSON.stringify(msgscopy)));
                    if (!msgscopy.length) {
                        clearInterval(timer);
                    }
                },
                props.duration * 1000,
                setMsgs
            );

            // clearTimeout 写法
            // clearTimeout(timer);
            // let fn = (setMsgs) => {
            //   msgscopy.shift();
            //   setMsgs(JSON.parse(JSON.stringify(msgscopy)));
            //   if (msgscopy.length) {
            //     timer = setTimeout(fn, props.duration * 1000, setMsgs);
            //   }
            // };
            // timer = setTimeout(fn, props.duration * 1000, setMsgs);
        }
    }, [msgs]);

    return (
        <div className="message">
            {msgs.map((item, index) => {
                return (
                    <div className="messageContent" key={index}>
                        <img src={`${iconObj[type]}`} alt="icon" className="messageIcon" />
                        <span className="messageText">{content}</span>
                    </div>
                );
            })}
        </div>
    );
}

let api = {};

["success", "error"].forEach((type) => {
    api[type] = (content, duration = 5) => {
        return notice({ content, duration, type });
    };
});

export default api;

// 目前该组件只包含了 "success", "error" 这几种，其他的按需自行添加即可；
// `duration` 为时长；
// 具体可参照 ant design 完善；
