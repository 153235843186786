import bannerImg from '../../../assets/images/scenemining/banner.png';
import tagImg from '../../../assets/images/scenemining/tag.png';
import tagActiveImg from '../../../assets/images/scenemining/tagActive.png';
import splitImg from '../../../assets/images/scenemining/split.png';
import splitActiveImg from '../../../assets/images/scenemining/splitActive.png';
import clusterImg from '../../../assets/images/scenemining/cluster.png';
import clusterActiveImg from '../../../assets/images/scenemining/clusterActive.png';
import perfectImg from '../../../assets/images/scenemining/perfect.png';
import perfectActiveImg from '../../../assets/images/scenemining/perfectActive.png';
import carouselImg1 from '../../../assets/images/scenemining/carousel1.png';
import carouselImg2 from '../../../assets/images/scenemining/carousel2.png';
import carouselIcon1 from '../../../assets/images/scenemining/carouselIcon1.png';
import carouselIcon2 from '../../../assets/images/scenemining/carouselIcon2.png';
import scenariosImg from '../../../assets/images/scenemining/scenarios.png';

export const bannerData = {
    title: '场景挖掘',
    description: ['利用路采数据，对数据中的动态、静态元素进行特征元素提取，并将有价值的场景片段进行切割，后进行质检、入库等系列环节，从而泛化生成新的动态场景案例，以场景复刻真实世界。'],
    img: bannerImg,
    contact: true,
};

export const menuData = [
    {
        id: 'feature',
        title: '产品功能介绍',
    },
    {
        id: 'advantage',
        title: '产品优势',
    },
    {
        id: 'scenarios',
        title: '应用场景',
    }
];

export const featureData = {
    id: 'feature',
    title: '产品功能',
    children: [
        {
            id: 0,
            img: tagImg,
            activeImg: tagActiveImg,
            title: 'tag挖掘',
            desc: '基于主车行为、主车驾驶环境、地图、障碍物类型位置和相对关系等特定标签挖掘，同时提供一套事件表达式，可满足各类tag自定义组合。',
        },
        {
            id: 1,
            img: splitImg,
            activeImg: splitActiveImg,
            title: '场景切割入库',
            desc: '为保证主车完整性，对场景进行智能化切割，并将合理场景保存到场景库内，并通过格式转化提升场景的可使用性及复用性。',
        },
        {
            id: 2,
            img: clusterImg,
            activeImg: clusterActiveImg,
            title: '场景聚类',
            desc: '为提高场景的测试效率，对具备相同特征信息的场景进行聚类，可对场景集合、场景做标签分类管理。',
        },
        {
            id: 3,
            img: perfectImg,
            activeImg: perfectActiveImg,
            title: '场景完美化',
            desc: '通过对感知噪声的去除，提升策略实现的合理性和可靠性，完成场景描述、场景挖掘的逻辑实现。',
        }
    ]
};

export const advantageData = {
    title: '产品优势',
    id: 'advantage',
    items: [
        {
            id: 0,
            title: '日度场景挖掘',
            desc: '基于百度千万公里自动驾驶实际道路采集数据验证，可满足日级挖掘，驱动场景覆盖度逐步逼近到完美态。',
            img: carouselImg1,
            icon: carouselIcon1,
        },
        {
            id: 1,
            title: '语意场景描述',
            desc: '提供一套事件描述语言，可基于事件描述语言直接检索出所需场景。',
            img: carouselImg2,
            icon: carouselIcon2,
        },
    ]
};

export const scenariosData = {
    title: '应用场景',
    id: 'scenarios',
    img: scenariosImg,
    scenarios: [
        {
            id: 0,
            title: '路采数据应用',
            img: scenariosImg,
            desc: '对智驾车辆路采数据做挖掘应用，以实现高价值数据闭环应用，提高测试验证真实性。',
        },
        {
            id: 1,
            title: 'Badcase场景库',
            img: scenariosImg,
            desc: '随着智驾车辆不断面市，海量路侧问题暴露。该产品通过数据回传方案对路侧问题数据进行定向挖掘，生成问题场景合集，以满足后续回归测试。',
        },
    ]
};

