import advantageData from './data';
import './style.css';
import linkTo from '../../../../assets/images/home/linkto.svg';

const Advantage = () => {
    return (
        <div className="advantage">
            <div className="advantageTitle">核心优势</div>
            <div className="advantageContent">
                {
                    advantageData.map((item, index) => {
                        return (
                            <div className="advantageCard" key={index}>
                                <div className="advantageCardCon">
                                    <img className="advantageCardLogo" src={item.src} />
                                    {item.title.map((title, index) => (
                                        <div className="advantageCardTitle" key={index}>
                                            {title}
                                        </div>
                            ))}
                                    <div className="advantageCardDetail">{item.con}</div>
                                </div>
                                <img className="advantageIcon" src={linkTo} alt="icon" />
                            </div>
                        )

                    })
                }
            </div>
        </div>
    );
}

export default Advantage;
