import Banner from './components/Banner';
import Advantage from './components/Advantage';
import FunctionCard from './components/FunctionCard';


const AboutUs = () => {
    return (
        <div className="aboutUs">
            <Banner />
            <Advantage />
            <FunctionCard />
        </div>
    )
};

export default AboutUs;
