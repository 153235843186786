import React from 'react';
import {Link} from 'react-router-dom';

class SliderList extends React.PureComponent {
    render () {
        let bannerIndex = this.props.index;
        // 动态计算 width
        let percent = (100 / this.props.items.length).toFixed(2);
        let bannerItemStyle = {width: percent + '%'};
        let style = {
            width: this.props.items.length * 100 + '%',
            transform: bannerIndex === this.props.items.length ? 'translateX(0)' : 'translateX(' + bannerIndex * -percent + '%)',
            transition: `left ${this.props.speed}s linear`
        };
        return (
            <>
                <div className="component-wrap" style={style}>
                    <div>
                        {
                            this.props.items.map((item, itemIndex) => (
                                <div className={[this.props.items[0].program ?'xcloud-landpage-banner': 'xcloud-banner', itemIndex === this.props.index ? 'active' : ''].join(' ')} key={itemIndex} style={bannerItemStyle}>
                                    <img className="banner-background-image" src={item.src} alt="" />
                                    <div className={['home-banner-wrap', itemIndex === this.props.index ? 'xcloud-view-center' : ''].join(' ')}>
                                        <div className="home-banner bottom">
                                            <div className="home-banner-box">
                                                <div className="banner-main">
                                                    <div className="home-banner-title">
                                                        {item.title}
                                                    </div>
                                                    <div className="home-banner-desc">
                                                        {item.desc}
                                                    </div>
                                                    {
                                                        // 此处的jumpBtn是判断banner上是否有按钮；home是判断banner是在主页还是有落地页，主页home为true，其他页未设置home，则为false。如果home为true，按钮采用hmoe-ask样式，普通的ask样式
                                                        typeof (item.jumpBtn) !== 'undefined' ?
                                                            <Link
                                                                target="_blank"
                                                                to={item.href}
                                                                className={[item.jumpBtn.length !== 0 ? 'ask' : 'no-ask', item.home ? 'home-ask' : ''].join(' ')}
                                                            >{item.jumpBtn}
                                                            </Link> : ''
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </>
        );
    }
}

export default SliderList;
