import bannerImg from '../../../assets/images/badcase/banner.png';
import miningImg from '../../../assets/images/badcase/mining.png';
import miningActiveImg from '../../../assets/images/badcase/miningActive.png';
import labelImg from '../../../assets/images/badcase/label.png';
import labelActiveImg from '../../../assets/images/badcase/labelActive.png';
import sceneImg from '../../../assets/images/badcase/scene.png';
import sceneActiveImg from '../../../assets/images/badcase/sceneActive.png';
import carouselImg1 from '../../../assets/images/badcase/carousel1.png';
import carouselImg2 from '../../../assets/images/badcase/carousel2.png';
import carouselIcon1 from '../../../assets/images/badcase/carouselIcon1.png';
import carouselIcon2 from '../../../assets/images/badcase/carouselIcon2.png';
import scenariosImg from '../../../assets/images/badcase/scenarios.png';

export const bannerData = {
    title: 'Badcase闭环',
    description: ['以Badcase数据为驱动，通过数据采集、挖掘、标注、投喂模型训练，不断迭代提升自动驾驶模型能力，解决自动驾驶的长尾问题。'],
    img: bannerImg,
    contact: true,
};

export const menuData = [
    {
        id: 'feature',
        title: '产品功能介绍',
    },
    {
        id: 'advantage',
        title: '产品优势',
    },
    {
        id: 'scenarios',
        title: '应用场景',
    }
];

export const featureData = {
    id: 'feature',
    title: '产品功能',
    children: [
        {
            id: 0,
            img: miningImg,
            activeImg: miningActiveImg,
            title: 'badcase挖掘',
            desc: '通过标签挖掘，语义挖掘，图像挖掘等技术，寻找和提取海量数据中的有效场景数据。',
        },
        {
            id: 1,
            img: labelImg,
            activeImg: labelActiveImg,
            title: 'badcase标注',
            desc: '内置自动驾驶专用的识别模型，实现badcase数据的自动标注。',
        },
        {
            id: 2,
            img: sceneImg,
            activeImg: sceneActiveImg,
            title: 'badcase场景',
            desc: '通过收集整理不同场景信息，建设模型的badcase场景数据集。',
        }
    ]
};

export const advantageData = {
    title: '产品优势',
    id: 'advantage',
    items: [
        {
            id: 0,
            title: 'badcase全流程',
            desc: '打通模型上车，通过对模型badcase的挖掘，标注，形成对模型数据驱动的迭代闭环。',
            img: carouselImg1,
            icon: carouselIcon1,
        },
        {
            id: 1,
            title: '自动化',
            desc: '通过定制模型，实现数据挖掘自动化，标注自动化。',
            img: carouselImg2,
            icon: carouselIcon2,
        },
    ]
};

export const scenariosData = {
    title: '应用场景',
    id: 'scenarios',
    img: scenariosImg,
    scenarios: [
        {
            id: 0,
            title: '量产优化',
            img: scenariosImg,
            desc: '自动驾驶车辆量产后，在现实路跑运营环境中会遇到更多未知未解决的场景，通过badcase闭环迭代，能够不断的将这些场景数据进行收集和学习，纳入到自动驾驶的能力范围内。',
        },
    ]
};

