import bannerImg from '../../../assets/images/issuefinder/banner.png';
import featureImg1 from '../../../assets/images/issuefinder/featureImg1.png';
import featureActiveImg1 from '../../../assets/images/issuefinder/featureActiveImg1.png';
import featureImg2 from '../../../assets/images/issuefinder/featureImg2.png';
import featureActiveImg2 from '../../../assets/images/issuefinder/featureActiveImg2.png';
import featureImg3 from '../../../assets/images/issuefinder/featureImg3.png';
import featureActiveImg3 from '../../../assets/images/issuefinder/featureActiveImg3.png';
import carouselImg1 from '../../../assets/images/issuefinder/carousel1.png';
import carouselImg2 from '../../../assets/images/issuefinder/carousel2.png';
import carouselIcon1 from '../../../assets/images/issuefinder/carouselIcon1.png';
import carouselIcon2 from '../../../assets/images/issuefinder/carouselIcon2.png';
import scenariosImg1 from '../../../assets/images/issuefinder/scenarios1.png';
import scenariosImg2 from '../../../assets/images/issuefinder/scenarios2.png';



export const bannerData = {
    title: '数据回放issue finder',
    description: ['数据回放用于采集车辆的路测数据回放，对视频和点云数据在线3D渲染和播放，提供路况仿真、信息记录、线下调试等能力，帮助测试人员和算法开发人员复原场景，进行问题定位分析。'],
    img: bannerImg,
    contact: true,
};

export const menuData = [
    {
        id: 'feature',
        title: '产品功能介绍',
    },
    {
        id: 'advantage',
        title: '产品优势',
    },
    {
        id: 'scenarios',
        title: '应用场景',
    },
];

export const featureData = {
    id: 'feature',
    title: '产品功能',
    children: [
        {
            id: 0,
            img: featureImg1,
            activeImg: featureActiveImg1,
            title: '感知物模型',
            desc: '支持自车模型及周围不同类型的目标物模型，目标物包括行人、非机动车、小汽车、大型汽车、障碍物等类型，目标物的尺寸、朝向、速度等信息呈现。不同类型类型之间可以用形状、颜色、标志来区分。',
        },
        {
            id: 1,
            img: featureImg2,
            activeImg: featureActiveImg2,
            title: '高精地图信息',
            desc: '地图信息，可视化呈现本车周围的车道线、交通标线、交通标志、交通信号等，兼容OpenDrive或百度Apollo等地图格式。',
        },
        {
            id: 2,
            img: featureImg3,
            activeImg: featureActiveImg3,
            title: '传感器数据回放',
            desc: '支持回放摄像头画面、激光雷达的点云等，可切换传感器或开启关闭传感器数据。',
        }
    ]
}

export const advantageData = {
    title: '产品优势',
    id: 'advantage',
    items: [
        {
            id: 0,
            title: '数据可视化',
            desc: '提供采集数据的完整播放，车辆的速度、加速和方向盘等信息随着轨迹会动态可视化变化，方便排查和定位路测数据存在的问题。',
            img: carouselImg1,
            icon: carouselIcon1,
        },
        {
            id: 1,
            title: '数据提取',
            desc: '回放过程中发现特殊/典型的数据场景，支持自定义提取数据片段，用于训练模型或场景库搭建等。',
            img: carouselImg2,
            icon: carouselIcon2,
        },
    ]
};

export const scenariosData = {
    title: '应用场景',
    id: 'scenarios',
    img: scenariosImg1,
    scenarios: [
        {
            id: 0,
            title: '算法测试',
            img: scenariosImg1,
            desc: '算法实车测试时会把感知、预测、规控和控制的结构打包成数据包，上传至数据回放工具进行解析及可视化，供测试和算法人员进行bug定位。',
        },
        {
            id: 1,
            title: '场景复现',
            img: scenariosImg2,
            desc: '落盘数据上传后，可以通过数据回放了解复现当时发生了什么，哪些地方不符合预期，然后进一步分析不符合预期原因。',
        },
    ]
};


