import tenImg from '../../../../assets/images/aboutus/ten.png';
import autoDriveImg from '../../../../assets/images/aboutus/autodrive.png';
import applicationImg from '../../../../assets/images/aboutus/application.png';
import securityImg from '../../../../assets/images/aboutus/security.png';

const advantageData = [
    {
        title: ["十多年", "自动驾驶基因"],
        con: "深厚的基础框架，10多年的自动驾驶技术基因，经过海量业务的技术锤炼，为客户提供高可控、弹性化、可定制等云服务。",
        src: tenImg,
    },
    {
        title: ["智慧管理", "安全可靠"],
        con: "严格的数据安全保护措施，一体化智慧安全管理体系，助力企业建立系统性的安全防御机制。",
        src: securityImg,
    },
    {
        title: ["丰富的", "应用场景"],
        con: "从基础设施到行业应用领域，提供完善的产品体系，赋能各种业务场景，实现车辆产线化智能造。",
        src: applicationImg,
    },
    {
        title: ["健全的", "服务保障"],
        con: "7*24小时高效服务保障，提供即时响应式服务，全程为开发加速，确保企业研发效率、智能化升级。",
        src: autoDriveImg,
    }
]

export default advantageData;
