import React from 'react';
import Banner from '../components/Banner';
import Band from '../components/Band';
import {partnerBannerItems, partnerBandItems} from '../utils/contants';

/**
 * Partner
 */
function Partner() {
    return (
        <div>
            <Banner items={partnerBannerItems} />
            <Band items={partnerBandItems} doubleColor />
        </div>
    );
}

export default Partner;
