import './style.css';

const SolutionAdvantage = ({ title, id, img, leftDesc, rightDesc }) => {
    return (
        <div
            className="solutionAdvantage"
            id={id}
            style={{ backgroundImage: `url(${img})` }}
        >
            <div className="advantageName">{title}</div>
            <div className="advantageData">
                <div className="advantageLeft">
                    {
                        leftDesc.map(item => {
                            return (
                                <div className="advantageItem" key={item.id}>
                                    <div className="advantageItemTitlePart">
                                        <div className="advantageItemTitle">{item.title}</div>
                                        <div className="advantageLink">
                                            <div className="advantagePoint"></div>
                                            <div className="advantageLine"></div>
                                        </div>
                                    </div>
                                    {/* <div className="advantageItemContent">{item.subtitle}</div> */}
                                    {
                                        item.subtitle.map((subitem, i) => <div className="advantageItemContent" key={i}>{subitem}</div>)
                                    }
                                </div>
                            )
                        })
                    }
                </div>
                <div className="advantageRight">
                    {
                        rightDesc.map(item => {
                            return (
                                <div className="advantageRightItem" key={item.id}>
                                    <div className="advantageLink">
                                        <div className="advantageRightLine"></div>
                                        <div className="advantagePoint"></div>
                                    </div>
                                    <div>
                                        <div className="advantageItemTitle">{item.title}</div>
                                        <div className="advantageItemContent">{item.subtitle}</div>
                                    </div>

                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    );
};

export default SolutionAdvantage;


