import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import right from '../../assets/images/components/rightArrow.svg';
import left from '../../assets/images/components/leftArrow.svg';
import rightActive from '../../assets/images/components/rightArrowActive.svg';
import leftActive from '../../assets/images/components/leftArrowActive.svg';
import style from './style.module.css';

const SolutionProducts = ({ id, title, children }) => {
    const navigate = useNavigate();
    const [start, setStart] = useState(0);

    const itemData = children.slice(start, start+4);

    return (
        <div className={style.solutionProducts} id={id}>
            <div className={style.solutionProductsTitle}>{title}</div>
            <div className={style.solutionProductsContent}>
                {
                    // start = 0 就不能左走了
                    start > 0 ?
                        <img
                            src={leftActive}
                            className={style.solutionActiveArrow}
                            alt="left arrow"
                            onClick={() => setStart(start-1)}
                        /> :
                        <img src={left} className={style.solutionArrow} alt="left arrow" />
                }
                {
                    itemData.map(item => {
                        return (
                            <div className={style.solutionProductsCard} key={item.id}>
                                <div className={style.solutionProductsCardCon}>
                                    <img className={style.solutionProductsCardLogo} src={item.img} />
                                    <div className={style.solutionProductsCardTitle}>
                                        {item.title}
                                    </div>
                                    <div className={style.solutionProductsCardDetail}>{item.description}</div>
                                </div>
                                <div
                                    className={
                                        item.link ? style.solutionProductsLink : style.solutionProductsLinkDisabled
                                    }
                                    onClick={() => {
                                        if (item.link) {
                                            navigate(item.link);
                                        }
                                    }}
                                >
                                    <div className={style.solutionProductsCardLinkName}>查看产品</div>
                                    <div className={style.solutionProductsIcon}></div>
                                </div>
                            </div>
                        )

                    })
                }
                {
                    // start = length 就不能右走了
                    start < children.length - 4 ?
                        <img
                            src={rightActive}
                            className={style.solutionActiveArrow}
                            alt="right arrow"
                            onClick={() => setStart(start+1)}
                        /> :
                        <img src={right} className={style.solutionArrow} alt="right arrow" />
                }
            </div>
        </div>
    );
}

export default SolutionProducts;
