import bannerImg from '../../../assets/images/playback/banner.png';
import featureImg1 from '../../../assets/images/playback/featureImg1.png';
import featureActiveImg1 from '../../../assets/images/playback/featureActiveImg1.png';
import featureImg2 from '../../../assets/images/playback/featureImg2.png';
import featureActiveImg2 from '../../../assets/images/playback/featureActiveImg2.png';
import featureImg3 from '../../../assets/images/playback/featureImg3.png';
import featureActiveImg3 from '../../../assets/images/playback/featureActiveImg3.png';
import carouselImg1 from '../../../assets/images/playback/carousel1.png';
import carouselImg2 from '../../../assets/images/playback/carousel2.png';
import carouselIcon1 from '../../../assets/images/playback/carouselIcon1.png';
import carouselIcon2 from '../../../assets/images/playback/carouselIcon2.png';
import scenariosImg1 from '../../../assets/images/playback/scenarios1.png';


export const bannerData = {
    title: '数据回放仿真',
    description: ['提供完整的路采回放仿真处理能力，满足路采数据从回放、处理、完美化、场景转换的全流程，让智驾算法在路采回归和主线研发的双向驱动下快速迭代。'],
    img: bannerImg,
    contact: true,
};

export const menuData = [
    {
        id: 'feature',
        title: '产品功能介绍',
    },
    {
        id: 'advantage',
        title: '产品优势',
    },
    {
        id: 'scenarios',
        title: '应用场景',
    }
];

export const featureData = {
    id: 'feature',
    title: '产品功能',
    children: [
        {
            id: 0,
            img: featureImg1,
            activeImg: featureActiveImg1,
            title: '原始数据可视化回放',
            desc: '用户可根据实际测试需求回放传感器原始数据、车辆算法感知数据、车控数据等车端数据。',
        },
        {
            id: 1,
            img: featureImg2,
            activeImg: featureActiveImg2,
            title: '多种仿真模式',
            desc: '支持路采数据的整体算法的回放仿真评价，同时可满足单个算法组件的开环验证。',
        },
        {
            id: 2,
            img: featureImg3,
            activeImg: featureActiveImg3,
            title: '多视角的三维立体渲染',
            desc: '提供既美观又能准确表达自动驾驶数据信息的可视化交互平台， 呈现仿真回放过程。',
        }
    ]
}

export const advantageData = {
    title: '产品优势',
    id: 'advantage',
    items: [
        {
            id: 0,
            title: '全要素展示',
            desc: '提供全要素的路测数据的准确复现与可视化展现能力，实现路测问题的快速定位与解决。',
            img: carouselImg1,
            icon: carouselIcon1,
        },
        {
            id: 1,
            title: '准确复现',
            desc: '准确回放感知数据、规控数据、定位数据，实现问题复现率：≥90%。',
            img: carouselImg2,
            icon: carouselIcon2,
        },
    ]
};

export const scenariosData = {
    title: '应用场景',
    id: 'scenarios',
    img: scenariosImg1,
    scenarios: [
        {
            id: 0,
            title: '路采数据高效利用',
            img: scenariosImg1,
            desc: '大幅提高自动驾驶场景库的建设速度，同时路采和运营过程中发现的问题需要及时快速的定位、复现以及修复验证。',
        },
        {
            id: 1,
            title: '自动驾驶算法迭代',
            img: scenariosImg1,
            desc: '用户可通过对路测数据的仿真回放，迭代验证Bug是否修复，算法表现是否更优等问题。',
        },
    ]
};

