import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import linkTo from '../../../../assets/images/home/linkto.svg';
import customerData from './data';
import './style.css';

const Customer = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    return (
        <div className="customer">
            <div className="customerTitle">客户案例</div>
            <div className="customerContent">
                <div className="customerContentImg">
                    <div className="blueBar"></div>
                    <div className="triangle"></div>
                    <img
                        src={customerData[currentIndex].bgSrc}
                        alt="picture"
                        className="customerImg"
                    />
                </div>
                <div className="customerContentRight">
                    <div className="customerContentMenus">
                        {
                            customerData.map((item, index) => {
                                return (
                                    <div className={`customerContentMenu${currentIndex === index ? 'Active' : ''}`} key={index} onClick={() => setCurrentIndex(index)}>
                                        <img src={currentIndex === index ? item.logo : item.logoNo} alt="" className="menuLogo" />
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="customerDetail">
                        <div className="customerDetailTitle">{customerData[currentIndex].title}</div>
                        <div className="customerDetailContent">{customerData[currentIndex].desc}</div>
                        <Link to="/partner" target="_blank">
                            <div className="customerDetailBtn">了解详情</div>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="seeMore">
                <Link to="/partner" target="_blank" className="seeMoreLink">
                    <div>查看更多客户案例</div>
                    <img src={linkTo} alt="linkTo" className="seeMoreLinkTo" />
                </Link>
            </div>
        </div>
    );
}

export default Customer;
