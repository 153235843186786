import React, {Component} from 'react';
import BannerList from './bannerlist';
import BannerDots from './bannerdot';

class BannerBox extends Component {
    constructor() {
        super();
        this.state = {
            index: 0,
        };
    }

    /**
     * go step 处理
     */
    go = step => {
        let index = this.state.index + step;
        if (index >= this.props.items.length) {
            index = 0;
        }
        if (index < 0) {
            index = this.props.items.length - 1;
        }
        this.setState({
            index: index,
        });
    }

    /**
     * 轮播定时器
     */
    turn = () => {
        if (this.props.autoplay) {
            this.timer = setInterval(() => {
                this.go(1);
            }, this.props.delay * 1000);
        }
    }

    /**
     * 页面加载完毕之后，判断是否自动播放
     */
    componentDidMount() {
        if (this.props.autoplay) {
            this.turn();
        }
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    render() {
        return (
            <div
                className={['banner-module', this.props.cancleTransition ? 'no-transition' : ''].join(' ')}
                onMouseOver={() => clearInterval(this.timer)}
                onMouseOut={this.turn}
            >
                <BannerList
                    index={this.state.index}
                    {...this.props}
                />
                {
                    this.props.dots && this.props.items.length > 1
                        ? <BannerDots index={this.state.index} {...this.props} go={this.go} />
                        : null
                }
            </div>
        );
    }
}

export default BannerBox;
