import bannerImg from '../../assets/images/test/banner.jpg';
import dimensionIcon from '../../assets/images/test/dimension.png';
import sceneIcon from '../../assets/images/test/scene.png';
import efficientIcon from '../../assets/images/test/efficient.png';
import scaleIcon from '../../assets/images/test/scale.png';
import architectureImg from '../../assets/images/test/architecture.png';
import advantageImg from '../../assets/images/develop/advantage.jpg';
import excavateImg from '../../assets/images/test/excavate.png';
import simulationImg from '../../assets/images/test/simulation.png';
import taskImg from '../../assets/images/test/task.png';
import libraryImg from '../../assets/images/test/library.png';
import customer1Img from '../../assets/images/test/customer1.png';
import customer2Img from '../../assets/images/test/customer2.png';
import customer3Img from '../../assets/images/test/customer3.png';

export const bannerData = {
    title: '云仿真测试解决方案',
    description: ['云仿真平台提供海量场景库、度量指标和自动化场景挖掘与泛化引擎，基于真实道路测试数据生成的海量测试场景，可以支持大尺度空间范围的里程测试，轻松实现"日行百万公里"，让自动驾驶技术的研发更安全、更严谨、更高效。'],
    img: bannerImg,
};

export const menuData = [
    {
        id: 'challenge',
        title: '业务挑战',
    },
    {
        id: 'architecture',
        title: '方案架构',
    },
    {
        id: 'advantage',
        title: '方案优势',
    },
    {
        id: 'product',
        title: '推荐产品',
    },
    {
        id: 'case',
        title: '客户案例',
    }
];

export const challengeData = {
    title: '业务挑战',
    id: 'challenge',
    children: [
        {
            id: 0,
            icon: dimensionIcon,
            title: '测试维度单一',
            description: '百度云仿真平台提供近1万次迭代积累形成的度量指标，提供超百项评价工具，满足整车级宏观性能、特征场景，模块级多维度分析各层次评测需求。'
        },
        {
            id: 1,
            icon: sceneIcon,
            title: '场景覆盖不全',
            description: '云仿真平台可提供千级语义场景和千万级数据场景，依托百度2000+万公里的真实道路测试数据转化的千万级高价值场景数据，可为行业可高度复用，大幅节约研发周期和测试成本。'
        },
        {
            id: 2,
            icon: efficientIcon,
            title: '迭代效率不足',
            description: '以仿真为基础的DevOps工具链解耦“实车”强依赖，可轻松实现天级别算法验证、周级别版本发布、千人级别并行研发，迭代效率提升百倍。'
        },
        {
            id: 3,
            icon: scaleIcon,
            title: '测试规模较小',
            description: '基于云平台之上提供多种仿真执行方式，支撑大规模的仿真测试验证，轻松实现“日行十万、百万公里”，为自动驾驶研发阶段算法准出，提供充分验证和数据保障！'
        }
    ]
};

export const architectureData = {
    title: '解决方案架构',
    id: 'architecture',
    img: architectureImg,
    description: [
        '基于云端部署方式，涵盖仿真引擎、场景编辑、车辆动力学、场景挖掘与泛化、高性能任务执行与评价引擎，并提供场景库及数据支撑。真正以算法开放测试为目标，满足不同层级与不同划分的感知、预测、规划与控制算法测试。',
    ]
};

export const advantageData = {
    title: '方案优势',
    id: 'advantage',
    img: advantageImg,
    leftDesc: [
        {
            id: 0,
            title: '经验足',
            subtitle: ['云仿真平台在百度内部经过8年沉淀，已具备业内最强工程实践验证，最懂自动驾驶，拥有丰富的实战经验。']
        },
        {
            id: 1,
            title: '覆盖广',
            subtitle: ['基于人工正向设计和道路测试数据逆向补充的完整场景库构建体系，内部积累了千万的数据场景。']
        },
        {
            id: 2,
            title: '执行快',
            subtitle: ['百度云仿真平台效率国际领先，机器资源利用率能达到50%~80%。']
        }
    ],
    rightDesc: [
        {
            id: 3,
            title: '模仿真',
            subtitle: ['云仿真平台提供细致、全面的真实性度量，仿真一致性能达到99.8%。']
        },
        {
            id: 4,
            title: '规模大',
            subtitle: ['百度内部供仿真使用的机器数量超过5000台，支持10w级并发，内部积累的仿真测试里程数超过10亿公里。']
        },
        {
            id: 5,
            title: '判断准',
            subtitle: ['内部上万次研发测试迭代积累的200多项度量指标，助力仿真产品的准确率达到95%，召回率达到90%。']
        }
    ]
};

export const productData = {
    title: '推荐产品',
    id: 'product',
    children: [
        {
            id: 0,
            title: '场景挖掘与转化',
            description: '提供场景过滤、场景切割、场景入库、场景完美化等一系列自动化场景挖掘与转化处理，支持通过Tag场景主车行为、主车驾驶环境、地图元素及障碍物类型等信息进行组合挖掘和场景生成。',
            img: excavateImg,
            link: ''
        },
        {
            id: 1,
            title: '人工设计仿真',
            description: '提供主车、障碍车、自行车、行人和路侧等多种类交通设置，满足用户拖拽式动态场景的搭建、参数化交通行为触发和动作设置，简单易用。并批量化通过泛化方式生成海量场景。',
            img: simulationImg,
            link: '/product/designsimulation'
        },
        {
            id: 2,
            title: '测试任务与度量',
            description: '提供批量化场景导入和评价指标设置，设置开环、闭环、评价多种类覆盖的仿真运行跑法。满足并行千级与万级别任务运行，可生成单体测试报告、报告比对、度量评价与统计分析。并基于车型管理和计划管理等模块，支撑海量测试的有序高效应用。',
            img: taskImg,
            link: ''
        },
        {
            id: 3,
            title: '场景库与评价体系',
            description: '基于百度8年以上持续积累的千级别语义场景库管理，可通过自动驾驶能力、标签等维度管理和提取与语义级场景信息。并支持用户自定义设计与积累，加快自建场景评价体系的步伐。',
            img: libraryImg,
            link: ''
        }
    ]
};

export const caseData = {
    title: '客户案例',
    id: 'case',
    data: [
        {
            id: 0,
            title: 'Apollo L3仿真案例',
            description: '支撑百度内部实现面向自动驾驶车辆测试的虚拟静态场景，场景可覆盖支撑L3/L4算法训练，包括道路场景、泊车场景、交通元素、周边元素、交通参与者等。具备基于高精地图的场景编辑能力并自动导入渲染引擎。',
            img: customer1Img
        },
        {
            id: 1,
            title: 'Apollo L4仿真案例',
            description: '支撑百度内部Apollo平台完成海量车端数据训练验证，实现汽车智能化升级及无人驾驶的多场景应用，打通数据生产、共享和云端数据应用闭环，面向开发者提供完整的自动驾驶数据解决方案，加速创新迭代效率。',
            img: customer2Img
        },
        {
            id: 2,
            title: '某新势力仿真案例',
            description: '百度提供公有云仿真测试平台，开放场景编辑、场景库、场景数据和评价体系，协助用户算法团队进行SIL级闭环仿真测试，快速适配用户自动驾驶框架。支撑用户在研发初期，即拥有行业领先水平的仿真测试能力和评价体系，切实加快算法测试与迭代效率！',
            img: customer3Img
        }
    ]
};
