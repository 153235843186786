import bannerImg from '../../assets/images/data/banner.jpg';
import bigDataIcon from '../../assets/images/data/bigdata.png';
import effectiveIcon from '../../assets/images/data/effective.png';
import closeLoopIcon from '../../assets/images/data/closeloop.png';
import architectureImg from '../../assets/images/data/architecture.png';
import advantageImg from '../../assets/images/data/advantage.jpg';
import collectionImg from '../../assets/images/data/collection.png';
import processImg from '../../assets/images/data/process.png';
import uploadImg from '../../assets/images/data/upload.png';
import storageImg from '../../assets/images/data/storage.png';
import customer1Img from '../../assets/images/data/customer1.png';
import customer2Img from '../../assets/images/data/customer2.png';

export const bannerData = {
    title: '数据闭环解决方案',
    description: '数据中台主要完成数据采集、处理、标注、存储和管理，以供自动驾驶研发和测试使用。针对路测车辆/量产车辆采集的原始数据，经过数据预处理后上传到云端的数据中台进行统一管理和应用。通过一站式平台服务，助力车企实现自动驾驶智能化产线，打造更安全、更智能的自动驾驶车辆。',
    img: bannerImg,
};

export const menuData = [
    {
        id: 'challenge',
        title: '业务挑战',
    },
    {
        id: 'architecture',
        title: '方案架构',
    },
    {
        id: 'advantage',
        title: '方案优势',
    },
    {
        id: 'product',
        title: '推荐产品',
    },
    {
        id: 'case',
        title: '客户案例',
    }
];

export const challengeData = {
    title: '业务挑战',
    id: 'challenge',
    children: [
        {
            id: 0,
            icon: bigDataIcon,
            title: '自动驾驶数据量庞大，管理成本高',
            description: '自动驾驶车辆预计每天产生TB级数据，传统数据存管模式无法应对大规模复杂数据的快速处理、各种传感器数据及外部数据的适配接入。'
        },
        {
            id: 1,
            icon: effectiveIcon,
            title: '有效数据量少，难以直接利用',
            description: '自动驾驶研发测试运营过程中涉及庞大的数据量，但数据价值密度低，存在大量低质、重复的数据，导致有效数据量少，研发效率低，减缓自动驾驶汽车投入批量生产的速度。'
        },
        {
            id: 2,
            icon: closeLoopIcon,
            title: '数据无法高效流转，形成数据闭环',
            description: '自动驾驶感知、决策、控制等算法要依靠海量数据进行研发迭代，仿真评测需依赖数据的挖掘实现场景库搭建，各环节对数据都有共性的存管用需求，亟待打通数据闭环链路，促使数据高效流转并循环使用，加快研发迭代实现快速落地。'
        },
    ]
};

export const architectureData = {
    title: '解决方案架构',
    id: 'architecture',
    img: architectureImg,
    description: [
        '通过构建完整的数据闭环链路来加速自动驾驶研发，以数据中台为基础解决数据的采集、传输、存储和管理等问题，同时为研发工程师提供训练数据帮助算法开发，为测试工程师提供仿真测试用的各类场景，极大提高了自动驾驶开发效率。通过数据闭环解决方案，可实现天级别的算法更新和周级别的算法OTA上路。'
    ]
};

export const advantageData = {
    title: '方案优势',
    id: 'advantage',
    img: advantageImg,
    leftDesc: [
        {
            id: 0,
            title: '全链打通',
            subtitle: ['数据驱动的数据闭环、业务闭环和商业闭环。'],
        },
        {
            id: 1,
            title: '积累最深',
            subtitle: ['8年1600万公里的测试里程；', '10亿帧以上的标注数据量；', '日常500+以上规模车队的海量数据。']
        },
    ],
    rightDesc: [
        {
            id: 2,
            title: '复杂数据可视化',
            subtitle: ['支持rosbag、record等复杂的数据格式可视化，使得数据能够更好被理解。']
        }
    ]
};

export const productData = {
    title: '推荐产品',
    id: 'product',
    children: [
        {
            id: 0,
            title: '数据采集',
            description: '提供专业采集车队及采集管理平台，满足感知、标注、制图等数据采集需求。',
            img: collectionImg,
            link:  '/product/collectionplan'
        },
        {
            id: 1,
            title: '数据处理',
            description: '基于百度智能驾驶业务多产品线数据处理经验，打造灵活可复用的数据处理系统，打通数据生产环节。',
            img: processImg,
            link: '/product/workflow'
        },
        {
            id: 2,
            title: '数据上传通路',
            description: '打造从车端落盘数据传输到云端进行管理使用，有效帮助企业提升自动驾驶的研发能力。',
            img: uploadImg,
            link: '/product/offline'
        },
        {
            id: 3,
            title: '数据存储',
            description: '具备PB级数据存储和亿级别数据管理使用实际经验，同时支持灵活的数据存储扩展能力。',
            img: storageImg,
            link: '/product/drivingdataset'
        }
    ]
};

export const caseData = {
    title: '客户案例',
    id: 'case',
    children: [
        {
            id: 0,
            title: '头部车企自动驾驶数据平台',
            description: '支撑某头部车企打造全球首个智能网联汽车公共数据中心，搭建车、路、云一体化的智能汽车数据综合管理和运行监管系统，持续提升数据服务能级，为车辆测试与示范应用提供技术服务与安全保障，实现车联网应用，赋能网联汽车产业创新发展。',
            img: customer1Img
        },
        {
            id: 1,
            title: 'Apollo Scape数据集',
            description: '支撑百度内部Apollo平台完成海量车端数据训练验证，实现汽车智能化升级及无人驾驶的多场景应用，打通数据生产、共享和云端数据应用闭环，面向开发者提供完整的自动驾驶数据解决方案，加速创新迭代效率。',
            img: customer2Img
        }
    ]
};
