import { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
// 主要页面
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import Contact from './pages/Contact';
import FloatContact from './components/FloatContact';
// solutions
import Develop from './pages/Develop';
import Data from './pages/Data';
import Test from './pages/Test';
import Operation from './pages/Operation';
import DataAnnotation from './pages/DataAnnotation';
import Search from './pages/Search';
import Legal from './pages/Legal';
// products
import SIL from './pages/products/SIL';
import BadCase from './pages/products/BadCase';
import AiTrain from '././pages/products/AiTrain';
import SceneMining from './pages/products/SceneMining';
import Visualization from './pages/products/Visualization';
import AnnotationTool from './pages/products/AnnotationTool';
import StandardMetrics from './pages/products/StandardMetrics';
import DataPlayback from './pages/products/DataPlayback';
import SceneLibrary from './pages/products/SceneLibrary';
import ScenePlatform from './pages/products/ScenePlatform';
import AutoCarManage from './pages/products/AutoCarManage';
import PlanManage from './pages/products/PlanManage';
import DesignSimulation from './pages/products/DesignSimulation';
import IssueFinder from './pages/products/IssueFinder';
import OfflineDataUpload from './pages/products/OfflineDataUpload';
import CollectionPlan from './pages/products/CollectionPlan';
import WorkflowEngine from './pages/products/WorkflowEngine';
import DrivingDataset from './pages/products/DrivingDataset';
// 后续改造
import Partner from './pages/partner';
import { navFixed } from './utils';

function App() {
    useEffect(() => {
        navFixed();
    }, []);

    return (
        <div className="App">
            <BrowserRouter>
                <Header />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/contact" exact='true' element={<Contact />} />
                    <Route path="/partner" exact='true' element={<Partner />} />
                    <Route path="/about" element={<AboutUs />} />
                    {/* solutions */}
                    <Route
                        path="/solution/develop"
                        exact='true'
                        element={<Develop />}
                    />
                    <Route
                        path="/solution/data"
                        exact='true'
                        element={<Data />}
                    />
                    <Route
                        path="/solution/test"
                        exact='true'
                        element={<Test />}
                    />
                    <Route
                        path="/solution/operation"
                        exact='true'
                        element={<Operation />}
                    />
                    <Route
                        path="/solution/annotation"
                        exact='true'
                        element={<DataAnnotation />}
                    />
                    <Route
                        path="/solution/search"
                        exact='true'
                        element={<Search />}
                    />
                    <Route
                        path="/solution/legal"
                        exact='true'
                        element={<Legal />}
                    />
                    {/* products */}
                    <Route path="/product/sil" exact='true' element={<SIL />} />
                    <Route path="/product/badcase" exact='true' element={<BadCase />} />
                    <Route path="/product/aitrain" exact='true' element={<AiTrain />} />
                    <Route path="/product/scenemining" exact='true' element={<SceneMining />} />
                    <Route path="/product/visualization" exact='true' element={<Visualization />} />
                    <Route path="/product/annotationtool" exact='true' element={<AnnotationTool />} />
                    <Route path="/product/standardmetrics" exact='true' element={<StandardMetrics />} />
                    <Route path="/product/playback" exact='true' element={<DataPlayback />} />
                    <Route path="/product/scenelibrary" exact='true' element={<SceneLibrary />} />
                    <Route path="/product/sceneplatform" exact='true' element={<ScenePlatform />} />
                    <Route path="/product/autocarmanage" exact='true' element={<AutoCarManage />} />
                    <Route path="/product/planmanage" exact='true' element={<PlanManage />} />
                    <Route path="/product/designsimulation" exact='true' element={<DesignSimulation />} />
                    <Route path="/product/issuefinder" exact='true' element={<IssueFinder />} />
                    <Route path="/product/offline" exact='true' element={<OfflineDataUpload />} />
                    <Route path="/product/collectionplan" exact='true' element={<CollectionPlan />} />
                    <Route path="/product/workflow" exact='true' element={<WorkflowEngine />} />
                    <Route path="/product/drivingdataset" exact='true' element={<DrivingDataset />} />
                </Routes>
                <FloatContact />
                <Footer />
            </BrowserRouter>
        </div>
    );
}

export default App;
