import bannerImg from '../../assets/images/legal/banner.png';
import lawIcon from '../../assets/images/legal/law.png';
import dataIcon from '../../assets/images/legal/data.png';
import carIcon from '../../assets/images/legal/car.png';
import locationIcon from '../../assets/images/legal/location.png';
import architectureImg from '../../assets/images/legal/architecture.png';
import advantageImg from '../../assets/images/legal/advantage.jpg';
import engineImg from '../../assets/images/legal/engine.png';
import taskImg from '../../assets/images/legal/task.png';
import legalDataImg from '../../assets/images/legal/legalData.png';
import reviewImg from '../../assets/images/legal/review.png';
import customer1Img from '../../assets/images/legal/customer1.png';
import customer2Img from '../../assets/images/legal/customer2.png';

export const bannerData = {
    title: '智驾合规解决方案',
    description: '云合规平台提供多样化的数据接入方式，同时支持多类型数据格式的脱敏脱密合规化处理。深入至实际合规工作场景的处理全链路，云端合规平台囊括合规处理引擎、合规算法模型、人工审校等模块，着力保障合规链路的可观、可管、可控，强力赋能智驾汽车企业安全有序发展。',
    img: bannerImg,
};

export const menuData = [
    {
        id: 'challenge',
        title: '业务挑战',
    },
    {
        id: 'architecture',
        title: '方案架构',
    },
    {
        id: 'advantage',
        title: '方案优势',
    },
    {
        id: 'product',
        title: '推荐产品',
    },
    {
        id: 'case',
        title: '客户案例',
    }
];

export const challengeData = {
    title: '业务挑战',
    id: 'challenge',
    children: [
        {
            id: 0,
            icon: lawIcon,
            title: '法律对智驾车企数据使用的监管日趋细化',
            description: '智能驾驶是车企的重要发展方向，发展智能驾驶离不开海量数据的采集，包括测绘、隐私数据等。国家监管日益严格，通过法规政策设置红线，智驾车企合规区域建设势在必行。'
        },
        {
            id: 1,
            icon: dataIcon,
            title: '测绘数据必须由具备测绘资质的图商保存使用管理',
            description: '按照国家法律法规的要求，具备特殊性的测绘数据仅允许由具备测绘资质的图商保存、使用和处理，不允许图商共享数据给第三方。'
        },
        {
            id: 2,
            icon: carIcon,
            title: '车端数据如果泄露将严重威胁国家安全',
            description: '车端数据采集与回传的数据内容包括GNSS轨迹，对于GNSS轨迹信息来说，需要采用3D偏转插件保密技术处理。'
        },
        {
            id: 3,
            icon: locationIcon,
            title: '无法高效定位合规问题，且难以直观感知合规进展',
            description: '待合规数据具备数据格式多样化、量级大的特点，而整个合规工作链路也涉及多类工作流，如果缺少统筹管理的平台，则无法高效定位合规任务的问题与进度管控，也缺少人工二次审校的双重合规保障。'
        }
    ]
};

export const architectureData = {
    title: '解决方案架构',
    id: 'architecture',
    img: architectureImg,
    description: [
        '深度理解智驾数据流转链路和应用需求，完整应用测科院安全自主可控专项技术成果，采用自研高性能车端脱敏AI模型、测科院认证的国密加密技术，实现量产车数据安全合规回传。待合规数据接入至云端后，由平台一站式监管合规链路，高效产出合规数据，渗透式保障智驾链路强合规。'
    ]
};

export const advantageData = {
    title: '方案优势',
    id: 'advantage',
    img: advantageImg,
    leftDesc: [
        {
            id: 0,
            title: '接入场景全覆盖',
            subtitle: ['提供采集落盘、量产实时回传、远程文件多种数据接入方式，覆盖智驾车企数据接入全场景。']
        },
        {
            id: 1,
            title: '数据格式可扩展',
            subtitle: ['支持接入多种源数据格式，自动化流转至不同合规链路进行针对性解析处理与分门别类管理。']
        },
    ],
    rightDesc: [
        {
            id: 2,
            title: '链路风险严把控',
            subtitle: ['支持根据客户要求设置运维访问权限，限制运维账号数量，并结合法律法规以确保在最小实现单元内规范化开展工作。']
        },
        {
            id: 3,
            title: '操作行为可追溯',
            subtitle: ['对运维操作日志实现全留存，可按需配置审计策略从而形成审计报告，支持溯源，所记录日志仅供客户及监管单位进行审计使用。']
        }
    ]
};

export const productData = {
    title: '推荐产品',
    id: 'product',
    children: [
        {
            id: 0,
            title: '合规处理引擎',
            description: '通过数据处理算子的组合与调度，支持处理不同类型的源数据，接入后的数据会由AI模型对人脸、车牌、敏感区域等数据做脱敏处理。',
            img: engineImg,
            link: ''
        },
        {
            id: 1,
            title: '合规任务管理',
            description: '支持查看合规任务总集，提供任务运行控制操作功能。同时提供待审核作业专区，以人工审校保障数据的强合规性。',
            img: taskImg,
            link: ''
        },
        {
            id: 2,
            title: '合规数据总管',
            description: '提供权限分配管理功能，支持分类管理与下载已合规数据。',
            img: legalDataImg,
            link: ''
        },
        {
            id: 3,
            title: '合规内容审计',
            description: '提供数据审计能力，所有用户的访问与操作行为均会记录日志，配合国家的审计工作并形成审计报告。',
            img: reviewImg,
            link: ''
        }
    ]
};

export const caseData = {
    title: '客户案例',
    id: 'case',
    children: [
        {
            id: 0,
            title: '头部车企·智能化数据合规和管理平台',
            description: '支撑该车企量产车型数据闭环所需的云平台基础设施建设改造业务、数据合规及数据管理业务。通过合规化数据平台的建设，实现量产车型全生命周期的数据埋点与分析，为新型商业模式提供数据支撑。',
            img: customer1Img
        },
        {
            id: 1,
            title: '龙头车企·自动驾驶测绘数据合规平台',
            description: '支撑该车企针对智能驾驶研发数据采集及量产车数据回传等场景的合规能力建设，为后续车型的研发提供数据合规化处理支持。百度经过智能驾驶与地图数据处理的长期工程实践，在保障安全合规前提下实现车企对智驾数据的应用需求。',
            img: customer2Img
        }
    ]
};
