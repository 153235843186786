import { footerData } from './data';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logos/logowhite.svg';
import newlogo from '../../assets/images/logos/newlogowhite.png';
import './footer.css';


const Footer = () => {

    return (
        <div className="xcloud-footer">
            <div className="inner">
                <div className="footer-logo">
                    <img src={newlogo} alt="logo" />
                </div>
                <div className="xcloud-footer-main-services">
                    {
                        footerData.map((category, categoryIndex) => (
                            <div
                                className={['main-services', category.noHighlight && 'noHighlight'].join(' ')}
                                key={categoryIndex}
                            >
                                <p className="title">{category.name}</p>
                                {
                                    category.data.map((dataItem, dataIndex) => {
                                        if (dataItem.link) {
                                            return (
                                                <span className="xcloud-link-box" key={dataIndex}>
                                                    <Link
                                                        // target="_blank"
                                                        to={dataItem.link}
                                                        className="xcloud-link"
                                                    >{dataItem.title}
                                                    </Link>
                                                </span>
                                            );
                                        } else {
                                            return (
                                                <span className="xcloud-link-box" key={dataIndex}>
                                                    <span className="xcloud-link">{dataItem.title}</span>
                                                </span>
                                            );
                                        }
                                    })
                                }
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className="separateLine"></div>
            <div className="copyright">
                <span>©{new Date().getFullYear()} Baidu</span>
                <span className="read">
                    <a
                        href="https://www.baidu.com/duty/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        使用百度前必读
                    </a>
                </span>
                <span>京ICP证030173号</span>
            </div>
        </div>
    );
}

export default Footer;
