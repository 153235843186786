import { useState,useEffect } from 'react';
import { NavLink, useNavigate, useLocation} from 'react-router-dom';
import classNames from 'classnames';
import ProductsHeader from './components/ProductsHeader';
import {productData, solutionData} from './data';
import logo from '../../assets/images/logos/logo.svg';
import newlogo from '../../assets/images/logos/newlogo.png';
import './style.css';

const jumpTo = ()=> {
    const w = window.open('_black') //这里是打开新窗口
    let url = 'http://engine.xcloud.baidu.com/'
    w.location.href = url //这样就可以跳转了
}

// 切换路由时改变菜单栏样式
const changeHeaderStyle = () => {
    const navBody = document.getElementById('header');
    const navBodyBar = document.getElementById('headerBar');
    if (window.location.pathname !== '/') {
        navBody.style.position = 'fixed';
        navBodyBar.style.background = '#fff';
        navBodyBar.style.color = '#16181a';
    } else {
        navBody.style.position = 'absolute';
        navBodyBar.style.background = '';
        navBodyBar.style.color = '';
    }
};

const Header = () => {
    let navigate = useNavigate();
    let location = useLocation();
    // 展示产品下拉菜单
    const [showProducts, setShowProducts] = useState(false);
    // 展示解决方案下拉
    const [showSolution, setShowSolution] = useState(false);
    // 顶部菜单栏变白
    const [whiteHeader, setWhiteHeader] = useState(false);
    // 顶部菜单选项
    const [active, setActive] = useState('');
    // 切换白色logo
    // const [whiteLogo, setWhiteLogo] = useState(true);

    useEffect(() => changeHeaderStyle(), [location]);

    return (
        <div
            className="header"
            id="header"
            onMouseLeave={() => {
                setShowProducts(false);
                setShowSolution(false);
                setWhiteHeader(false);
            }}
        >
            <div id="headerBar" className={classNames("headerBar", whiteHeader ? "whiteHeader" : "")}>
                <div className="headerBarLeft">
                    <NavLink exact to="/">
                        <img id="apollologo" src={newlogo} alt="logo" className="logo" />
                    </NavLink>
                    <div
                        className={classNames("headerItem", active === 0 ? "activeItem" : "")}
                        onMouseOver={() => {
                            setShowProducts(true);
                            setWhiteHeader(true);
                            setActive(0);
                        }}
                    >
                        产品
                    </div>
                    <div
                        className={classNames("headerItem", active === 1 ? "activeItem" : "")}
                        onMouseOver={() => {
                            setShowProducts(false);
                            setShowSolution(true);
                            setWhiteHeader(true);
                            setActive(1);
                        }}
                    >
                        解决方案
                        {
                            showSolution ?
                                <div className="solution" onMouseLeave={() => setShowSolution(false)}>
                                    {
                                    solutionData.map(solution => {
                                        return (
                                            <div
                                                className="solutionItem"
                                                key={solution.id}
                                                onClick={() => {
                                                    if (solution.link) {
                                                        navigate(solution.link);
                                                    }
                                                }}
                                            >
                                                <div className="solutionTitle">{solution.title}</div>
                                                <div className="solutionDesc">{solution.description}</div>
                                            </div>
)
                                    })
                                }
                                </div> : <></>
                        }
                    </div>
                    {/* <div
                        className={classNames("headerItem", active === 2 ? "activeItem" : "")}
                        onMouseOver={() => {
                            setActive(2);
                            setShowSolution(false);
                        }}
                        onClick={() => jumpTo()}
                    >
                        AI市场
                    </div> */}
                    <div
                        className={classNames("headerItem", active === 3 ? "activeItem" : "")}
                        onMouseOver={() => setActive(3)}
                        onClick={() => navigate('/partner')}
                    >
                        客户案例
                    </div>
                    <div
                        className={classNames("headerItem", active === 4 ? "activeItem" : "")}
                        onMouseOver={() => setActive(4)}
                        onClick={() => navigate('/about')}
                    >
                        关于我们
                    </div>
                </div>
                <div className="headerBarRight"></div>
            </div>
            {
                showProducts ?
                    <ProductsHeader
                        setShowProducts={setShowProducts}
                        setWhiteHeader={setWhiteHeader}
                        setActive={setActive}
                        HeaderData={productData}
                    /> : <></>
            }
        </div>

    )

}

export default Header;
