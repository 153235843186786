import bannerImg from '../../../assets/images/drivingdataset/banner.png';
import featureImg1 from '../../../assets/images/drivingdataset/featureImg1.png';
import featureActiveImg1 from '../../../assets/images/drivingdataset/featureActiveImg1.png';
import featureImg2 from '../../../assets/images/drivingdataset/featureImg2.png';
import featureActiveImg2 from '../../../assets/images/drivingdataset/featureActiveImg2.png';
import featureImg3 from '../../../assets/images/drivingdataset/featureImg3.png';
import featureActiveImg3 from '../../../assets/images/drivingdataset/featureActiveImg3.png';
import featureImg4 from '../../../assets/images/drivingdataset/featureImg4.png';
import featureActiveImg4 from '../../../assets/images/drivingdataset/featureActiveImg4.png';
import carouselImg1 from '../../../assets/images/drivingdataset/carousel1.png';
import carouselImg2 from '../../../assets/images/drivingdataset/carousel2.png';
import carouselImg3 from '../../../assets/images/drivingdataset/carousel3.png';
import carouselImg4 from '../../../assets/images/drivingdataset/carousel4.png';
import carouselIcon1 from '../../../assets/images/drivingdataset/carouselIcon1.png';
import carouselIcon2 from '../../../assets/images/drivingdataset/carouselIcon2.png';
import carouselIcon3 from '../../../assets/images/drivingdataset/carouselIcon3.png';
import carouselIcon4 from '../../../assets/images/drivingdataset/carouselIcon4.png';
import scenariosImg1 from '../../../assets/images/drivingdataset/scenarios1.png';

export const bannerData = {
    title: '智驾数据集',
    description: ['智驾数据集可用来存储结构、非结构化数据，为客户提供数据可视化、数据共享、统计分析、多维查询等数据管理能力，满足客户各类数据管理场景诉求。'],
    img: bannerImg,
    contact: true,
};

export const menuData = [
    {
        id: 'feature',
        title: '产品功能介绍',
    },
    {
        id: 'advantage',
        title: '产品优势',
    },
    {
        id: 'scenarios',
        title: '应用场景',
    },
];

export const featureData = {
    id: 'feature',
    title: '产品功能',
    children: [
        {
            id: 0,
            img: featureImg1,
            activeImg: featureActiveImg1,
            title: '数据可视化',
            desc: '系统对传感器、摄像头等设备产生的复杂数据，经过图片提取、点云解算、多传感器融合等处理，向用户呈现2D、3D、融合数据的可视化，提升用户理解、分析、使用数据的效率。',
        },
        {
            id: 1,
            img: featureImg2,
            activeImg: featureActiveImg2,
            title: '数据精细化管理',
            desc: '支持项目、数据集、子集、视图等多层级管理数据，每个层级都可配置权限进行独立管控，满足多业务团队协作使用数据的场景。',
        },
        {
            id: 2,
            img: featureImg3,
            activeImg: featureActiveImg3,
            title: '数据共享',
            desc: '提供全量数据集样例数据，用户可申请符合要求的全量数据，通过共享、申请、审批的完善机制，让数据价值发挥到最大。',
        },
        {
            id: 3,
            img: featureImg4,
            activeImg: featureActiveImg4,
            title: '数据应用',
            desc: '基于数据集内海量数据，持续萃取高价值、场景化数据，用于研发训练、仿真场景搭建。通过数据驱动自动驾驶效果提升的闭环，帮助车企完成汽车智能化升级。',
        }
    ]
}

export const advantageData = {
    title: '产品优势',
    id: 'advantage',
    items: [
        {
            id: 0,
            title: '数据管理经验丰富',
            desc: '基于百度内部庞大的自动驾驶数据集管理和训练经验，对外提供数据集能力，实现数据高效的存储、管理和使用。',
            img: carouselImg1,
            icon: carouselIcon1,
        },
        {
            id: 1,
            title: '数据高效流转闭环',
            desc: '打通自动驾驶开发工具链每一环节所需数据，通过数据驱动自动驾驶效果提升，持续循环数据链路，提升数据流转效率。',
            img: carouselImg2,
            icon: carouselIcon2,
        },
        {
            id: 2,
            title: '自定义数据标签',
            desc: '支持对不同数据进行连续标记，如标识出未标注、已标注、高速道路等场景数据，便于数据标签化管理。',
            img: carouselImg3,
            icon: carouselIcon3,
        },
        {
            id: 3,
            title: '数据管理工具',
            desc: '提供Client客户端工具，支持对数据进行批量下载、删除、迁移、复制、导入等操作，同时支持开发工作流算子。',
            img: carouselImg4,
            icon: carouselIcon4,
        },
    ]
};

export const scenariosData = {
    title: '应用场景',
    id: 'scenarios',
    img: scenariosImg1,
    scenarios: [
        {
            id: 0,
            title: '感知模型',
            img: scenariosImg1,
            desc: '从海量数据中筛选送标场景数据，对接至标注平台进行数据标注，标注后的数据生成数据集传输给研发中台进行模型训练。通过数据循环迭代的方式，帮助模型持续学习，提升感知效果。',
        },
        {
            id: 1,
            title: '测试场景库',
            img: scenariosImg1,
            desc: '从海量数据挖掘高价值数据，生成数据集传输至测试中台，通过一个个场景数据的供给，帮助测试场景库的搭建，提升测试验证的丰富度及效率。',
        },
    ]
};


