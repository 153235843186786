import { useState } from 'react';
import video from '../../../../assets/apollocloud.mp4';
import Apollo from '../../../../assets/apollo.mp4';
import cover from '../../../../assets/images/videocover.png';
import closeIcon from '../../../../assets/images/home/close.svg';
import './style.css';

const VideoBanner = () => {
    const [showModal, setShowModal] = useState(false);
    const [play, setPlay] = useState(false);

    function videoControl(e) {
        e.stopPropagation();
        let video = document.getElementById('apollo');
        if (play) {
            video.play();
            setPlay(false);
        } else {
            video.pause();
            setPlay(true);

        }
    }
    return (
        <div className="videoBanner">
            <video className='videoPart' preload="auto" autoPlay loop muted>
                <source src={video} type='video/mp4' />
            </video>
            <div className="homeBannerTitle">
                <h1 className="bigTitle">Apollo Cloud</h1>
                <h1 className="bigTitle">为自动驾驶而生</h1>
                <div className="titleContentTop">基于百度十年的自动驾驶实践沉淀，打造行业领先的自动驾驶引擎，</div>
                <div className="titleContent">助力车企搭建智能驾驶研发测试工具链，让智能自主可控</div>
                <div className="titleButton">
                    <button className="showVideo" onClick={() => setShowModal(true)}>查看视频</button>
                </div>
            </div>
            {
                !showModal ? <></> :
                <div className="videoModel" onClick={() => setShowModal(false)}>
                    {/* <img className="closeVideo" src={closeIcon} alt="close" /> */}
                    <video
                        className='videoTag'
                        preload="auto"
                        autoPlay={false}
                        poster={cover}
                        id="apollo"
                        onClick={(e) => videoControl(e)}
                        loop
                        muted
                        controls
                    >
                        <source src={Apollo} type='video/mp4' />
                    </video>
                </div>
            }
        </div>
    )
};

export default VideoBanner;
