import { useState } from 'react';
import upActiveImg from '../../assets/images/components/upActive.png';
import upImg from '../../assets/images/components/up.png';
import downActiveImg from '../../assets/images/components/downActive.png';
import downImg from '../../assets/images/components/down.png';
import './style.css';

const ProductScenarios = ({ title, id, scenarios }) => {
    const [index, setIndex] = useState(0);
    const [cards, setCards] = useState(scenarios.length > 2 ? scenarios.slice(index, index + 2) : scenarios);

    return (
        <div className="productScenarios" id={id}>
            <div className="scenariosName">{title}</div>
            <div className="scenariosContent">
                <img src={scenarios[index].img} className="scenariosImg" />
                <div className="scenariosDesc">
                    {
                        cards.map(item => {
                            return (
                                <div
                                    className={index === item.id ? "scenariosItem scenariosActive" : "scenariosItem"}
                                    key={item.id}
                                    onClick={() => setIndex(parseInt(item.id, 10))}
                                >
                                    <div className="scenariosTitle">{item.title}</div>
                                    <div className="scenariosSubtitle">{item.desc}</div>
                                </div>
                            )
                        })
                    }
                </div>
                {
                    scenarios.length > 2 ?
                        <div className="scenariosButton">
                            <img
                                src={index > 0 ? upActiveImg : upImg}
                                className="scenariosButtonImg"
                                onClick={() => {
                                    if (index > 0) {
                                        setIndex(index - 1);
                                        setCards(scenarios.slice(index - 1, index + 1));
                                    }
                                }}
                            />
                            <img
                                src={index < scenarios.length - 1 ? downActiveImg : downImg}
                                className="scenariosButtonImg"
                                onClick={() => {
                                    if (index < scenarios.length - 1) {
                                        setIndex(index + 1);
                                        setCards(scenarios.slice(index, index + 2));
                                    }
                                }}
                            />
                        </div> : <></>
                }
            </div>
        </div>
    );
};

export default ProductScenarios;
