import React, {Component} from 'react';

class SliderDot extends Component {
    render() {
        return (
            <div className="banner-tab">
                <div>
                    <div className="banner-tab-wrap">
                        <ul>
                            {
                                this.props.items.map((item, itemIndex) => (
                                    <li
                                        key={itemIndex}
                                        className={itemIndex === this.props.index ? 'active' : ''}
                                        onClick={() => this.props.go(itemIndex - this.props.index)}
                                    >
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

export default SliderDot;
