import bannerImg from '../../assets/images/develop/banner.jpg';
import dataIcon from '../../assets/images/develop/data.png';
import algorithmIcon from '../../assets/images/develop/algorithm.png';
import sourceIcon from '../../assets/images/develop/source.png';
import versionIcon from '../../assets/images/develop/version.png';
import architectureImg from '../../assets/images/develop/architecture.png';
import advantageImg from '../../assets/images/develop/advantage.jpg';
import notebookImg from '../../assets/images/develop/notebook.png';
import repositoryImg from '../../assets/images/develop/repository.png';
import evaluationImg from '../../assets/images/develop/evaluation.png';
import devopsImg from '../../assets/images/develop/devops.png';
import customer1Img from '../../assets/images/develop/customer1.png';
import customer2Img from '../../assets/images/develop/customer2.png';

export const bannerData = {
    title: 'AI研发解决方案',
    description: '互联网敏捷开发与车企研发模型的深度融合，助力于自动驾驶算法的快速更新迭代，基于百度研发中台，打通数据到研发训练的完整通路，为车企提供一站式自动驾驶算法研发服务，实现天粒度算法上路，周粒度正式OTA。',
    img: bannerImg,
};

export const menuData = [
    {
        id: 'challenge',
        title: '业务挑战',
    },
    {
        id: 'architecture',
        title: '方案架构',
    },
    {
        id: 'advantage',
        title: '方案优势',
    },
    {
        id: 'product',
        title: '推荐产品',
    },
    {
        id: 'case',
        title: '客户案例',
    }
];

export const challengeData = {
    title: '业务挑战',
    id: 'challenge',
    children: [
        {
            id: 0,
            icon: dataIcon,
            title: '海量路采数据，存储管理困难',
            description: '自动驾驶的算法研发需要海量的路采数据作为支撑，而路采数据包括非结构化的行人、车辆、建筑物等2D图像以及3D点云数据，给存储和管理都带来了巨大挑战。'
        },
        {
            id: 1,
            icon: algorithmIcon,
            title: '算法专业门槛高，开发难度大',
            description: '自动驾驶算法包括环境感知、路径规划、决策控制，各阶段均具备一定的专业门槛，开发过程涉及不同的环境及框架，使得开发难度大，环境准备周期长。'
        },
        {
            id: 2,
            icon: sourceIcon,
            title: '资源和算力需求大，扩展要求高',
            description: '模型训练需要的算力资源巨大，以满足复杂任务的运行要求，且不同任务对资源的需求也在动态变化中，给云服务资源的管理与调度提出了很高的要求。'
        },
        {
            id: 3,
            icon: versionIcon,
            title: '模型版本多，迭代周期长',
            description: '单个算法模型往往要经由往复的迭代验证才能满足实际场景需求，自动驾驶的算法模型种类多、数量大，难以进行统一纳管与评估优化，导致版本迭代周期长。'
        }
    ]
};

export const architectureData = {
    title: '解决方案架构',
    id: 'architecture',
    img: architectureImg,
    description: [
        'AI研发平台借助与百度AI技术积淀和DevOps工具链，为自动驾驶算法开发提供一站式服务，最大限度降低用户在环境部署中的时间成本。',
        '提供丰富的预训练模型和多样性建模方式，降低开发门槛，底层由百度智能云提供灵活可扩展的计算存储能力以及大数据组件，助力自动驾驶业务创新。'
    ]
};

export const advantageData = {
    title: '方案优势',
    id: 'advantage',
    img: advantageImg,
    leftDesc: [
        {
            id: 0,
            title: '算力灵活扩展',
            subtitle: ['百度智能云提供可灵活扩展的计算和存储资源，为海量数据存储及复杂训练任务提供强大的底层支撑。']
        },
        {
            id: 1,
            title: '提供数据支撑',
            subtitle: ['提供在线/离线数据标注服务，支持标注数据集上传导入，为研发训练提供高质量数据支撑。']
        },
        {
            id: 2,
            title: '模型统管镜像部署',
            subtitle: ['对模型进行统一纳管、共享及发布，支持以镜像文件形式将模型打包推送至仿真平台进行仿真评测。']
        }
    ],
    rightDesc: [
        {
            id: 3,
            title: '兼容主流框架',
            subtitle: ['支持各类开发环境以及主流计算框架，开发者只需关注核心算法研发部分即可，极大地降低环境部署中的时间成本。']
        },
        {
            id: 4,
            title: '灵活建模敏捷开发',
            subtitle: ['预置丰富的预训练模型，并提供多样式建模方式，帮助用户快速创建训练任务，大大降低用户研发训练门槛。']
        }
    ]
};

export const productData = {
    title: '推荐产品',
    id: 'product',
    children: [
        {
            id: 0,
            title: 'Notebook建模',
            description: '基于Jupyter在线的交互式开发环境，帮助开发者降低环境搭建时间成本，支持paddlepaddle、pytorch、TensorFlow等主流框架，为用户提供高效便捷的模型研发服务。',
            img: notebookImg,
            link: ''
        },
        {
            id: 1,
            title: '模型仓库',
            description: 'AI研发平台输出模型的中央存储仓库，对平台训练生成的模型以及本地上传模型的统一纳管，支持模型共享、模型打包以及一键式模型发布服务。',
            img: repositoryImg,
            link: ''
        },
        {
            id: 2,
            title: '模型评测',
            description: '基于高质量评测集以及多维的度量体系，对模型训练结果进行全面评测。',
            img: evaluationImg,
            link: ''
        },
        {
            id: 3,
            title: 'DevOps',
            description: '打通自动驾驶研发交付过程中全套工具链，构建更加快捷、高效及可靠的自动化研发流程。',
            img: devopsImg,
            link: ''
        }
    ]
};

export const caseData = {
    title: '客户案例',
    id: 'case',
    children: [
        {
            id: 0,
            title: '某头部车企',
            description: '使用百度AI研发平台进行自动驾驶算法研发，全面接入车企原有数据平台，帮助车企实现从数据采集-标注-研发-评估的全套功能落地，为车企提供了一站式研发训练服务。',
            img: customer1Img
        },
        {
            id: 1,
            title: '某国家智能网联测试场',
            description: '基于AI研发平台进行一站式感知算法训练及模型评估，并进行模型一键发布，大大节省整个研发训练时间成本，助力该测试场实现天粒度算法更新。',
            img: customer2Img
        }
    ]
};
