import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import ProductServiceData from './data';
import linkTo from '../../../../assets/images/home/linkto.svg';
import linkNone from '../../../../assets/images/home/linknone.svg'
import './style.css';

const ProductService = () => {
    const [pageIndex, setPageIndex] = useState(0);
    const pageData = ProductServiceData;
    let detailData = pageData.content[pageIndex].data;

    function videoPlay(videoIndex) {
        let video = document.getElementById(videoIndex);
        video.setAttribute("autoplay", "autoplay");
        video.play();
    }

    function videoPause(videoIndex) {
        // let video = document.getElementById(videoIndex);
        // video.pause();
    }

    return (
        <div className="productService">
            <div className="productServiceTitlePart">
                <div className="productServiceTitle">{pageData.title}</div>
                <div className="productServiceIconPart">
                    {
                        pageData.content.map((item, index) => {
                            return (
                                <div
                                    className="productServiceIcon"
                                    key={index}
                                    onClick={() => setPageIndex(index)}
                                >
                                    <div
                                        className="productServiceImg"
                                    >
                                        <video
                                            className='productServiceImgUrl'
                                            preload="auto"
                                            id={`video${index}`}
                                            muted
                                            onMouseOver={() => videoPlay(`video${index}`)}
                                            onMouseOut={() => videoPause(`video${index}`)}
                                            src={item.icon}
                                            type='video/mp4'
                                        >
                                        </video>
                                    </div>
                                    <div className="productServiceIconText">{item.text}</div>
                                    {
                                        index === pageIndex ?
                                            <div className="productServiceUnderLine"></div> :
                                            <div className="productServiceNoLine"></div>
                                    }
                                </div>
                            )
                        })
                    }
                </div>
                <div className="productServiceData">
                    <div className="productServiceDataNum">
                        <div className="productServiceDataNumTop">
                            <div className="productServiceDataNumTitle">{detailData.title}</div>
                            <div className="productServiceDataNumDesc">{detailData.description}</div>
                        </div>
                        <div className="productServiceDataNumBottom">
                            {
                                detailData.number.map((item, index) => {
                                    return (
                                        <div key={index}>
                                            <div className="productServiceDataNumLine"></div>
                                            <div className="productServiceDataNumItem">
                                                <div className="productServiceDataNumItemTitle">
                                                    <div className="productServiceDataNumItemCount">{item.value}</div>
                                                    <div className="productServiceDataNumItemUnit">{item.unit}</div>
                                                </div>
                                                <div className="productServiceDataNumName">{item.title}</div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="productServiceDataDetail">
                        {
                            detailData.detail.map((item, index) => {
                                return (
                                    <div
                                        className="productServiceDataCard"
                                        key={index}
                                        style={{
                                            backgroundImage: `url(${item.backgroundImage})`
                                        }}
                                    >
                                        <div className="productCardTitle">
                                            <div className="productCardTitleName">
                                                {item.name}
                                            </div>
                                            <div className="productCardTitleDesc">
                                                {item.description}
                                            </div>
                                        </div>
                                        <div className="productCardContent">
                                            {
                                                item.content.map((child, index) => {
                                                    return (
                                                        child.link !== '' ?
                                                            <Link key={index} to={child.link} target="_blank">
                                                                <div
                                                                    className="productCardContentItem"
                                                                >
                                                                    <div>{child.name}</div>
                                                                    <div className="productCardContentItemLink"></div>
                                                                </div>
                                                            </Link>
                                                            :
                                                            <div
                                                                className="productCardContentItemNone"
                                                                key={index}
                                                            >
                                                                <div className="productCardContentItemNoneName">{child.name}</div>
                                                                <img src={linkNone} alt="link" className="productCardContentItemLink" />
                                                            </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                )

                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProductService;
