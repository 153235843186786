import './style.css';

const Architecture = ({ title, img, id, description }) => {
    return (
        <div className="architecture" id={id}>
            <div className="architectureTitle">{title}</div>
            <div className="architectureDetail">
                <img src={img} alt='img' className="architectureImg" />
                <div className="architectureDesc">
                    <div className="architectureDescTitle">架构解读</div>
                    {
                        description.map((item, i) => {
                            return (
                                <div className="architectureItem" key={i}>
                                    {item}
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </div>
    );
};

export default Architecture;
