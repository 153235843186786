import { useState } from 'react';
import rightActiveImg from '../../assets/images/components/rightActive.png';
import rightImg from '../../assets/images/components/right.png';
import leftActiveImg from '../../assets/images/components/leftActive.png';
import leftImg from '../../assets/images/components/left.png';
import './style.css';

const ProductCarousel = ({ id, title, items }) => {
    const [currentId, setCurrentId] = useState(0);
    const [leftArrow, setLeftArrow] = useState(leftImg);
    const [rightArrow, setRightArrow] = useState(rightImg);
    let cards = [...items.slice(currentId + 1, items.length), ...items.slice(0, currentId)].slice(0, 3);

    return (
        <div className="productCarousel" id={id}>
            <div className="productCarouselTitle">{title}</div>
            <div className="productCarouselContent">
                <img className="productCarouselImg" src={items[currentId].img} />
                <div className="productCarouselRight">
                    <div className="productCarouselRightTop">
                        <div className="productCarouselRightTitle">{items[currentId].title}</div>
                        <div className="productCarouselRightDesc">{items[currentId].desc}</div>
                        <div className="productCarouselButton">
                            <img
                                className="carouselButtonImg"
                                src={leftArrow}
                                onMouseOver={() => setLeftArrow(leftActiveImg)}
                                onMouseOut={() => setLeftArrow(leftImg)}
                                onClick={() => setCurrentId(currentId > 0 ? currentId - 1 : items.length - 1)}
                            />
                            <img
                                className="carouselButtonImg"
                                src={rightArrow}
                                onMouseOver={() => setRightArrow(rightActiveImg)}
                                onMouseOut={() => setRightArrow(rightImg)}
                                onClick={() => setCurrentId(currentId < items.length - 1 ? currentId + 1 : 0)}
                            />
                        </div>
                    </div>
                    <div className="carouselCards">
                        {
                            cards.map(item => {
                                return (
                                    <div
                                        className="carouselCard"
                                        key={item.id}
                                        onClick={() => setCurrentId(item.id)}
                                    >
                                        <img src={item.img} className="carouselCardImg" />
                                        <div className="carouselCardTitle">
                                            <img className="carouselCardTitleImg" src={item.icon} />
                                            <div className="carouselCardTitleText">{item.title}</div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProductCarousel;
