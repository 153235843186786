import dataImg from '../../../../assets/images/aboutus/data.png';
import testImg from '../../../../assets/images/aboutus/test.png';
import developImg from '../../../../assets/images/aboutus/develop.png';
import operationImg from '../../../../assets/images/aboutus/operation.png';
import supervisionImg from '../../../../assets/images/aboutus/supervision.png';
const AboutUsData = [
    {
        title: '数据服务',
        description: '自动驾驶领域的全流程数据服务平台，提供”采标存管用”一体化服务，具备业界领先的采集工具和质检算法、上万人标注团队规模、千 TB 级稳定存储、安全可控的管理机制，以及可适配各行各业的数据应用场景。',
        img: dataImg,
        bgColor: '#ffff',
        direction: 'row'
    },
    {
        title: '研发服务',
        description: '提供深度学习训练全流程的服务与功能，为用户提供统一的算法训练、模型仓库、任务管理等服务，致力于建设高效率、高算力、高稳定性的自动驾驶深度学习计算平台。',
        img: developImg,
        bgColor: '#f8f8f8',
        direction: 'row-reverse',
    },
    {
        title: '测试服务',
        description: '基于真实世界数据生成虚拟测试场景，提供海量场景库、度量指标及高保真模型，以支持基于场景的测试和基于大尺度空间范围的里程测试，支持MIL、SIL、HIL测试条件输入，轻松实现”日行百万公里”。',
        img: testImg,
        bgColor: '#fff',
        direction: 'row'
    },
    {
        title: '运营服务',
        description: '运营服务提供车辆、订单、路线、安全、监控等各方面运营管理，全面覆盖安全员、乘客、车队等用户，助力客户提升MPI、用户体感和订单量。',
        img: operationImg,
        bgColor: '#f8f8f8',
        direction: 'row-reverse'
    },
    {
        title: '监管服务',
        description: '监管服务通过对车辆、多变且复杂的路侧情况进行监控，将多种信息进行融合监管，助力企业在自动驾驶的监管层面实现自主可控、安全可靠。',
        img: supervisionImg,
        bgColor: '#fff',
        direction: 'row'
    }
];

export default AboutUsData;
