/**
 * @file 常量配置
 * @author zhangzhichao06(zhangzhichao06@baidu.com)
 */

// partner
import partnerBanner01 from '../assets/images/partner/partnerBanner-01.png';
import partnerBand01 from '../assets/images/partner/band-01.svg';

import partnerBand02 from '../assets/images/partner/band-02.svg';
import partnerBand03 from '../assets/images/partner/band-03.svg';
import partnerBand04 from '../assets/images/partner/band-04.svg';
import icv from '../assets/images/partner/icv.png';
import partnerBand05 from '../assets/images/partner/band-05.svg';
import partnerBand06 from '../assets/images/partner/band-06.svg';

// partner 首页顶部 banner 配置
const partnerBannerItems = [
    {
        src: partnerBanner01,
        title: 'Apollo Cloud客户案例',
        desc: '数字化、网联化、自动化是未来交通出行的巨大机遇，期待与您的合作'
    }
];

// partner 品牌数据配置
const partnerBandItems = [
    {
        topTitle: '数据',
        data: [
            {
                icon: partnerBand01,
                title: 'Apollo Scape数据集',
                labels: ['Apollo', '精细标注'],
                desc: '支撑百度内部Apollo 平台完成数据采标任务，覆盖了来自三个城市站点的周围10KM的地域，'
                    + '包括感知分类和路网数据等数十万帧逐像素语义分割标注的高分辨率图像数据，以及与其对应的逐'
                    + '像素语义标注，最终形成目前行业内环境最复杂、标注最精准、数据量最大的三维自动驾驶公开数据集——Apollo Scape。'
            },
            {
                icon: partnerBand02,
                title: '某自动驾驶解决方案商',
                labels: ['3D障碍物框选', '高效标注'],
                desc: '为解决客户数据量不稳定导致人员维护成本过高，质量和产能把控难度大等问题，定制化提供标'
                    + '注基地专项人力方案，大幅减少企业管理工作，标注成本相比自建模式下降20%，产量和质量双提升。'
            },
            {
                icon: partnerBand03,
                title: '某汽车厂商',
                labels: ['2D/3D道路数据采标', '采标一体化'],
                desc: '厂商无测绘资质无法上路合法采集3D点云数据，本方案采用2辆自有采集车获取2000公里道路数据，'
                    + '通过私有化标注平台接口加密将验收通过数据上线标注，结合平台流程和工具，实现数量和质量双保障。'
            },
            {
                icon: partnerBand04,
                title: 'Apollo',
                labels: ['Apollo', '仿真'],
                desc: '支撑百度内部Apollo平台完成海量车端数据训练验证，实现汽车智能化升级及无人驾驶的多场景应用，'
                    + '打通数据生产、共享和云端数据应用闭环，面向开发者提供完整的自动驾驶数据解决方案，加速创新迭代效率。'
            },
            {
                icon: icv,
                title: '上海汽车城',
                labels: ['数据传输', '数据集', '工作流'],
                desc: '提供汽车城规模化监管诉求和业务平台研发迭代的自动驾驶数据中心，实现汽车城自动驾驶车辆上路测试、'
                    + 'LTE-V大规模集成示范、智慧交通的应用，为人工智能、深度学习、云端控制等提供创新试验环境。'
            },
        ]
    },
    {
        topTitle: '研发',
        data: [
            {
                icon: partnerBand05,
                title: '某龙头汽车厂商',
                labels: ['仿真平台', '自动驾驶场景库'],
                desc: '合作搭建仿真云平台，实现资产适配下的在线仿真、数据回灌仿真、大规模自动化仿真、'
                    + '孪生城市仿真模块场景化应用，协同并行建立满足产品开发需求的自动驾驶场景库，'
                    + '计划三年实现1000w 公里/天仿真量支撑。'
            },
            {
                icon: partnerBand06,
                title: 'L3仿真案例',
                labels: ['L3场景'],
                desc: '支撑百度内部实现面向自动驾驶车辆测试的虚拟静态场景，场景可覆盖支撑L3/L4算法训练，'
                    + '包括道路场景，泊车场景、交通元素、周边元素、交通参与者等。具备基于高精地图的场景编'
                    + '辑能力并自动导入GameEngine(美研渲染引擎)。'
            }
        ]
    }
];


/**
 * 基础常量配置
 */
export {
    // partner
    partnerBannerItems,
    partnerBandItems,
};

