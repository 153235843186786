import React, { useState } from 'react';;
import Apollo from '../../../../assets/apollo.mp4';
import cover from '../../../../assets/images/videocover.png';
// import closeIcon from '../../../../assets/images/home/close.svg';
import bannerImg from '../../../../assets/images/aboutus/banner.jpg';
import './style.css';

const Banner = () => {
    const [showModal, setShowModal] = useState(false);
    const [play, setPlay] = useState(false);

    function videoControl(e) {
        e.stopPropagation();
        let video = document.getElementById('apollo');
        if (play) {
            video.play();
            setPlay(false);
        } else {
            video.pause();
            setPlay(true);

        }
    }
    return (
        <div className="imgBanner">
            <img src={bannerImg} alt="banner" className="bannerImg" />
            <div className="bannerTitle">
                <div className="aboutTitle">Apollo自动驾驶云</div>
                <div className="aboutMiniTitle">助力车企搭建汽车智能化新产线</div>
                <div className="titleButton">
                    <button className="showVideo" onClick={() => setShowModal(true)}>查看视频</button>
                </div>
            </div>
            <div className="aboutDetail">
                <div className="aboutWords">Apollo自动驾驶云，百度集团倾力打造的自动驾驶云品牌，面向全世界各个国家和地区的车企、评测机构及政府部门，提供从数据、研发、测试、运营到监管的端到端全栈解决方案，助力企业实现自动驾驶智能新发展。</div>
                <div className="aboutNums">
                    <div className="aboutNum">
                        <div className="aboutNumTop">
                            <div className="numTitle">1800</div>
                            <div className="numUnit">万公里</div>
                        </div>
                        <div className="numName">测试里程</div>
                    </div>
                    <div className="aboutNum">
                        <div className="aboutNumTop">
                            <div className="numTitle">500+</div>
                            <div className="numUnitNone">.</div>
                        </div>
                        <div className="numName">测试车队规模</div>
                    </div>
                    <div className="aboutNum">
                        <div className="aboutNumTop">
                            <div className="numTitle">600+</div>
                            <div className="numUnitNone">.</div>
                        </div>
                        <div className="numName">合作车型</div>
                    </div>
                    <div className="aboutNum">
                        <div className="aboutNumTop">
                            <div className="numTitle">210+</div>
                            <div className="numUnitNone">.</div>
                        </div>
                        <div className="numName">产业合作伙伴</div>
                    </div>
                    <div className="aboutNum">
                        <div className="aboutNumTop">
                            <div className="numTitle">70+</div>
                            <div className="numUnitNone">.</div>
                        </div>
                        <div className="numName">合作车企</div>
                    </div>
                </div>
            </div>
            {
                !showModal ? <></> :
                <div className="videoModel" onClick={() => setShowModal(false)}>
                    {/* <img className="closeVideo" src={closeIcon} alt="close" /> */}
                    <video
                        className='videoTag'
                        preload="auto"
                        autoPlay={false}
                        poster={cover}
                        id="apollo"
                        onClick={(e) => videoControl(e)}
                        loop
                        muted
                        controls
                    >
                        <source src={Apollo} type='video/mp4' />
                    </video>
                </div>
            }
        </div>
    )
};

export default Banner;
