import { useState } from 'react';
import rightActiveImg from '../../assets/images/components/rightActive.png';
import rightImg from '../../assets/images/components/right.png';
import leftActiveImg from '../../assets/images/components/leftActive.png';
import leftImg from '../../assets/images/components/left.png';
import './style.css';

const ProductFeatures = ({ id, title, children }) => {
    const [currentId, setCurrentId] = useState(null);
    const [leftArrow, setLeftArrow] = useState(leftImg);
    const [rightArrow, setRightArrow] = useState(rightImg);
    const [begin, setBegin] = useState(0);
    let cards = children.slice(begin, begin + 4);
    return (
        <div className="productFeatures" id={id}>
            <div className="featureTitle">{title}</div>
            <div className="featureContent">
                {
                    children.length > 4 ?
                        <img
                            className="carouselButtonImg"
                            src={leftArrow}
                            onMouseOver={() => setLeftArrow(leftActiveImg)}
                            onMouseOut={() => setLeftArrow(leftImg)}
                            onClick={() => setBegin(begin > 0 ? begin - 1 : 1)}
                        /> : null
                }
                {
                    cards.map(item => {
                        return (
                            <div
                                className="featureCard"
                                style={{
                                    width: `${100 / children.length}%`,
                                }}
                                key={item.id}
                                onMouseOver={() => setCurrentId(item.id)}
                                onMouseOut={() => setCurrentId(null)}
                            >
                                <img className="featureCardLogo" src={item.id === currentId ? item.activeImg : item.img} />
                                <div className="featureCardTitle">
                                    {item.title}
                                </div>
                                <div className="featureCardDetail">{item.desc}</div>
                            </div>
                        )

                    })
                }
                {
                    children.length > 4 ?
                        <img
                            className="carouselButtonImg"
                            src={rightArrow}
                            onMouseOver={() => setRightArrow(rightActiveImg)}
                            onMouseOut={() => setRightArrow(rightImg)}
                            onClick={() => setBegin(begin === 0 ? begin + 1 : 0)}
                        /> : null
                }
            </div>
        </div>
    );
}

export default ProductFeatures;
