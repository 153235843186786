import bannerImg from '../../../assets/images/offline/banner.png';
import featureImg1 from '../../../assets/images/offline/featureImg1.png';
import featureActiveImg1 from '../../../assets/images/offline/featureActiveImg1.png';
import featureImg2 from '../../../assets/images/offline/featureImg2.png';
import featureActiveImg2 from '../../../assets/images/offline/featureActiveImg2.png';
import featureImg3 from '../../../assets/images/offline/featureImg3.png';
import featureActiveImg3 from '../../../assets/images/offline/featureActiveImg3.png';
import carouselImg1 from '../../../assets/images/offline/carousel1.png';
import carouselImg2 from '../../../assets/images/offline/carousel2.png';
import carouselIcon1 from '../../../assets/images/offline/carouselIcon1.png';
import carouselIcon2 from '../../../assets/images/offline/carouselIcon2.png';
import scenariosImg1 from '../../../assets/images/offline/scenarios1.png';


export const bannerData = {
    title: '离线数据上传',
    description: ['测试车辆或路侧设备产生的落盘数据，递送至数据上传节点，支持通过插拔盘形式，上传至数据中心。'],
    img: bannerImg,
    contact: true,
};

export const menuData = [
    {
        id: 'feature',
        title: '产品功能介绍',
    },
    {
        id: 'advantage',
        title: '产品优势',
    },
    {
        id: 'scenarios',
        title: '应用场景',
    },
];

export const featureData = {
    id: 'feature',
    title: '产品功能',
    children: [
        {
            id: 0,
            img: featureImg1,
            activeImg: featureActiveImg1,
            title: '数据读取',
            desc: '新硬盘挂载后自动读取硬盘，以列表形式呈现并展示待传硬盘信息，并定时检查上传节点中各服务器的卡槽状态。',
        },
        {
            id: 1,
            img: featureImg2,
            activeImg: featureActiveImg2,
            title: '上传任务',
            desc: '面向多种数据源/存储介质做上传管理，为其提供数据分级上传、优先级排序等功能，可对上传任务做管理、进度预览及查询。',
        },
        {
            id: 2,
            img: featureImg3,
            activeImg: featureActiveImg3,
            title: '数据统计',
            desc: '支持全览高优/全部任务的数据总量及时间，并对当前任务处理阶段做实时统计，以实现入库数据量的全流程把控。',
        }
    ]
}

export const advantageData = {
    title: '产品优势',
    id: 'advantage',
    items: [
        {
            id: 0,
            title: '数据分级',
            desc: '数据上传支持按照优先级进行传输，用户可自定义需要保存或遗弃的数据，从而减少无效数据，提供上传数据质量。',
            img: carouselImg1,
            icon: carouselIcon1,
        },
        {
            id: 1,
            title: '数据清洗',
            desc: '对不同级别的数据进行清洗，产出高价值数据，减少数据量，降低数据存储成本，支撑各种数据应用场景。',
            img: carouselImg2,
            icon: carouselIcon2,
        },
    ]
};

export const scenariosData = {
    title: '应用场景',
    id: 'scenarios',
    img: scenariosImg1,
    scenarios: [
        {
            id: 0,
            title: '数据大屏',
            img: scenariosImg1,
            desc: '落盘数据上传后，为数据大屏及业务分析数据，提供原始数据。',
        },
        {
            id: 1,
            title: '数据标注',
            img: scenariosImg1,
            desc: '感知训练需要使用大量数据，上传后的原始数据经过加工处理、标注完成后，可用于模型训练、模型评估等。',
        },
    ]
};


