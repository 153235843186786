import bannerImg from '../../../assets/images/sceneplatform/banner.png';
import featureImg1 from '../../../assets/images/sceneplatform/featureImg1.png';
import featureActiveImg1 from '../../../assets/images/sceneplatform/featureActiveImg1.png';
import featureImg2 from '../../../assets/images/sceneplatform/featureImg2.png';
import featureActiveImg2 from '../../../assets/images/sceneplatform/featureActiveImg2.png';
import featureImg3 from '../../../assets/images/sceneplatform/featureImg3.png';
import featureActiveImg3 from '../../../assets/images/sceneplatform/featureActiveImg3.png';
import featureImg4 from '../../../assets/images/sceneplatform/featureImg4.png';
import featureActiveImg4 from '../../../assets/images/sceneplatform/featureActiveImg4.png';
import featureImg5 from '../../../assets/images/sceneplatform/featureImg5.png';
import featureActiveImg5 from '../../../assets/images/sceneplatform/featureActiveImg5.png';
import carouselImg1 from '../../../assets/images/sceneplatform/carousel1.png';
import carouselImg2 from '../../../assets/images/sceneplatform/carousel2.png';
import carouselImg3 from '../../../assets/images/sceneplatform/carousel3.png';
import carouselImg4 from '../../../assets/images/sceneplatform/carousel4.png';
import carouselIcon1 from '../../../assets/images/sceneplatform/carouselIcon1.png';
import carouselIcon2 from '../../../assets/images/sceneplatform/carouselIcon2.png';
import carouselIcon3 from '../../../assets/images/sceneplatform/carouselIcon3.png';
import carouselIcon4 from '../../../assets/images/sceneplatform/carouselIcon4.png';
import scenariosImg1 from '../../../assets/images/sceneplatform/scenarios1.png';


export const bannerData = {
    title: '场景设计平台',
    description: ['可基于百度独有自动驾驶语义场景&测试需求，编辑障碍物属性，生成丰富的人工设计测试场景。'],
    img: bannerImg,
    contact: true,
};

export const menuData = [
    {
        id: 'feature',
        title: '产品功能介绍',
    },
    {
        id: 'advantage',
        title: '产品优势',
    },
    {
        id: 'scenarios',
        title: '应用场景',
    }
];

export const featureData = {
    id: 'feature',
    title: '产品功能',
    children: [
        {
            id: 0,
            img: featureImg1,
            activeImg: featureActiveImg1,
            title: '场景搭建',
            desc: '用户可以通过配置场景主要元素（地图、主车、障碍物）以及属性来构建丰富的仿真场景。',
        },
        {
            id: 1,
            img: featureImg2,
            activeImg: featureActiveImg2,
            title: '节点编辑',
            desc: '利用触发器和动作组合的形式，可以更加形象与自如的控制主车和障碍物的行为。',
        },
        {
            id: 2,
            img: featureImg3,
            activeImg: featureActiveImg3,
            title: 'fuzzing',
            desc: '设置固定fuzzing参数，通过对参数的泛化、在原有场景的基础上，衍生出更多在不同参数条件下的类似场景。',
        },
        {
            id: 3,
            img: featureImg4,
            activeImg: featureActiveImg4,
            title: '场景预览',
            desc: '可以快速生成动态结果进行播放，使用户在构建场景时不再盲目。',
        },
        {
            id: 4,
            img: featureImg5,
            activeImg: featureActiveImg5 ,
            title: 'script面板',
            desc: '批量展示语义场景设计稿，可参考语义场景设计稿进行快速场景搭建。',
        }
    ]
}

export const advantageData = {
    title: '产品优势',
    id: 'advantage',
    items: [
        {
            id: 0,
            title: '链式场景表达准确',
            desc: '场景元素通过链式连接，场景表达准确。',
            img: carouselImg1,
            icon: carouselIcon1,
        },
        {
            id: 1,
            title: '配置多样性',
            desc: '提供丰富的场景配置元素。',
            img: carouselImg2,
            icon: carouselIcon2,
        },
        {
            id: 2,
            title: '支持车流仿真',
            desc: '场景支持加入车流仿真元素，模拟真实车流。',
            img: carouselImg3,
            icon: carouselIcon3,
        },
        {
            id: 3,
            title: '云端实时验证预览',
            desc: '场景搭建完成无需保存即可播放预览，并进行修改。',
            img: carouselImg4,
            icon: carouselIcon4,
        },
    ]
};

export const scenariosData = {
    title: '应用场景',
    id: 'scenarios',
    img: scenariosImg1,
    scenarios: [
        {
            id: 0,
            title: '自动驾驶能力测试评估',
            img: scenariosImg1,
            desc: '对自动驾驶模型进行海量真实&极端场景测试，保证模型能力稳定性与准确性达到上线标准。',
        },
        {
            id: 1,
            title: '场景教学实训',
            img: scenariosImg1,
            desc: '面向产学研机构，构建教育实训一体机，培养高校学生对自动驾驶场景认知与搭建能力。',
        },
    ]
};

