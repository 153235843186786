import bannerImg from '../../../assets/images/autocar/banner.png';
import featureImg1 from '../../../assets/images/autocar/featureImg1.png';
import featureActiveImg1 from '../../../assets/images/autocar/featureActiveImg1.png';
import featureImg2 from '../../../assets/images/autocar/featureImg2.png';
import featureActiveImg2 from '../../../assets/images/autocar/featureActiveImg2.png';
import featureImg3 from '../../../assets/images/autocar/featureImg3.png';
import featureActiveImg3 from '../../../assets/images/autocar/featureActiveImg3.png';
import carouselImg1 from '../../../assets/images/autocar/carousel1.png';
import carouselImg2 from '../../../assets/images/autocar/carousel2.png';
import carouselImg3 from '../../../assets/images/autocar/carousel3.png';
import carouselIcon1 from '../../../assets/images/autocar/carouselIcon1.png';
import carouselIcon2 from '../../../assets/images/autocar/carouselIcon2.png';
import carouselIcon3 from '../../../assets/images/autocar/carouselIcon3.png';
import scenariosImg1 from '../../../assets/images/autocar/scenarios1.png';
import scenariosImg2 from '../../../assets/images/autocar/scenarios2.png';



export const bannerData = {
    title: '无人车资源管理',
    description: ['针对无人驾驶运营车辆的日常管理及信息维护，通过车辆运维信息线上统计及流程监控提高车辆维修速度及使用效率，通过对安全员信息的维护及管理保障人力资源合理使用。'],
    img: bannerImg,
    contact: true,
};

export const menuData = [
    {
        id: 'feature',
        title: '产品功能介绍',
    },
    {
        id: 'advantage',
        title: '产品优势',
    },
    {
        id: 'scenarios',
        title: '应用场景',
    },
    {
        id: 'customer',
        title: '客户案例',
    }
];

export const featureData = {
    id: 'feature',
    title: '产品功能',
    children: [
        {
            id: 0,
            img: featureImg1,
            activeImg: featureActiveImg1,
            title: '车辆管理',
            desc: '展示车辆详细信息，主要包括车辆基本信息以及配置硬件等信息， 查看车辆的交付和维修进度。',
        },
        {
            id: 1,
            img: featureImg2,
            activeImg: featureActiveImg2,
            title: '司机管理',
            desc: '在平台创建司机角色账号，并关联司机信息，实现司机信息在线管理。',
        },
        {
            id: 2,
            img: featureImg3,
            activeImg: featureActiveImg3,
            title: '运维流程管理',
            desc: '发现车辆出现异常情况时，可在系统中上报车辆异常信息，通过车辆报修创建报修并进行线上维修流程管理。',
        }
    ]
}

export const advantageData = {
    title: '产品优势',
    id: 'advantage',
    items: [
        {
            id: 0,
            title: '车辆资源线上管理',
            desc: '保障车辆信息透明化，提高车辆使用效率。',
            img: carouselImg1,
            icon: carouselIcon1,
        },
        {
            id: 1,
            title: '人员合规性管理',
            desc: '保障信息安全性，提高人力管理效率。',
            img: carouselImg2,
            icon: carouselIcon2,
        },
        {
            id: 2,
            title: '车辆运维在线管理',
            desc: '保障异常状态及时发现并上报，提高车辆使用安全性，实时监控运维进度，提高车辆维修效率。',
            img: carouselImg3,
            icon: carouselIcon3,
        },
    ]
};

export const scenariosData = {
    title: '应用场景',
    id: 'scenarios',
    img: scenariosImg1,
    scenarios: [
        {
            id: 0,
            title: '资源管理场景',
            img: scenariosImg1,
            desc: '车辆信息管理：相关权限人员将车辆信息进行录入平台，实现在线维护管理车辆信息。\n司机资源管理：在平台创建司机角色账号，并将司机信息录入，实现司机信息在线管理。',
        },
        {
            id: 1,
            title: '车辆运维场景',
            img: scenariosImg2,
            desc: '车辆报修：发现车辆出现异常情况时，可在系统中上报车辆异常信息，创建报修单，运维人员进行车辆异常状态的诊断，并开始车辆维修。\n车辆维修：车辆下线后，进行车辆维修环节，运维人员进行车辆维修，按维修流程在线依次录入维修信息，维修完成后，进行车辆上线，执行出车任务。',
        },
    ]
};

export const customerData = {
    title: '客户案例',
    id: 'customer',
    name: '某地方交管部门',
    desc: '为了缓解交通压力，方便市民出行，推进城市智能交通建设。建设试点区域，探索、研究智能网联云控平台以智能网联车辆作为采集终端的自动化驾驶无人车辆的城市道路运营。'
}
