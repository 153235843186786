import React from 'react';
import { Link } from 'react-router-dom';
import CountUp from "react-countup";
import VisibilitySensor from 'react-visibility-sensor';
import trybg from '../../../../assets/images/home/tryfree.jpg';
import './style.css';

const Tryfree = () => {
    return (
        <div className="trybody" style={{ backgroundImage: `url(${trybg})` }}>
            <div className="tryBox">
                <div className="tryData">
                    <div className="tryDeveloper">
                        <div className="tryWords">测试里程</div>
                        <div className="tryNumber">
                            <CountUp end={1800} duration={1} redraw>
                                {({ countUpRef, start }) => (
                                    <VisibilitySensor onChange={start} delayedCall>
                                        <span ref={countUpRef} />
                                    </VisibilitySensor>
                                )}
                            </CountUp>
                            <span className="unitW">万</span>
                            <span className="unitKm">公里</span>
                        </div>
                        <div className="tryUnderLine"></div>
                    </div>
                    <div className="tryDeveloper">
                        <div className="tryWords">开发者</div>
                        <div className="tryNumber">
                            <CountUp end={55000} duration={1} redraw>
                                {({ countUpRef, start }) => (
                                    <VisibilitySensor onChange={start} delayedCall>
                                        <span ref={countUpRef} />
                                    </VisibilitySensor>
                                )}
                            </CountUp>
                            <span className="tryUnit">+</span>
                        </div>
                        <div className="tryUnderLine"></div>
                    </div>
                    <div className="tryDeveloper">
                        <div className="tryWords">无人车测试牌照</div>
                        <div className="tryNumber">
                            <CountUp end={200} duration={1} redraw={false}>
                                {({ countUpRef, start }) => (
                                    <VisibilitySensor onChange={start} delayedCall>
                                        <span ref={countUpRef} />
                                    </VisibilitySensor>
                                )}
                            </CountUp>
                            <span className="tryUnit">+</span>
                        </div>
                        <div className="tryUnderLine"></div>
                    </div>
                </div>
                <div className="try">
                    <div className="tryTitle">选择Apollo驾驶云 开发稳定的产品</div>
                    <Link target="_blank" to="/contact">
                        <div className="tryButton">立即咨询</div>
                    </Link>
                </div>
            </div>
        </div>
    );

}

export default Tryfree;
