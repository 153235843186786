import { useState } from 'react';
import { useNavigate } from 'react-router';
import classNames from 'classnames';
import { isPhone } from '../../utils/reg';
import contactBg from '../../assets/images/contact/contactbg.png';
import successIcon from '../../assets/images/contact/success.svg';
import Message from '../../components/Message';
import './style.css';

let imgUrl = '';
if (process.env.NODE_ENV === 'development') {
    imgUrl = 'https://apollocloud.baidu-int.com';
} else {
    imgUrl = window.location.origin;
}

const Contact = () => {
    let navigate = useNavigate();
    // 场景
    const [scene, setScene] = useState('');
    const [sceneState, setSceneState] = useState(true);
    // 验证码图片后缀
    const [captcha, setCaptcha] = useState(Math.random());
    // 验证码
    const [captchaCode, setCaptchaCode] = useState('');
    const [captchaState, setCaptchaState] = useState(true);
    // 问题
    const [question, setQuestion] = useState('');
    const [questionState, setQuestionState] = useState(true);
    const [name, setName] = useState('');
    const [nameState, setNameState] = useState(true);
    const [phone, setPhone] = useState('');
    const [phoneState, setPhoneState] = useState(true);
    const [phoneMsg, setPhoneMsg] = useState('');
    const [company, setCompany] = useState('');
    const [submit, setSubmit] = useState(false);

    const handlePhoneChange = (value) => {
        if (!isPhone(value)) {
            setPhoneState(false);
            setPhoneMsg('输入手机号有误，请输入正确手机号');
        } else {
            setPhoneState(true);
            setPhoneMsg('');
        }
        setPhone(value);
    }

    const submitData = () => {
        let formdata = new FormData();

        formdata.append("product_line", "91123");
        formdata.append("feedback_type", "2");
        formdata.append("extend_telnum", "18600001111");
        formdata.append('mobile_os_name', `联系人：${name}`);
        formdata.append('description', `公司名称：${company}\n应用场景：${scene}\n咨询的问题：${question}\n联系电话：${phone}`);

        const contactData = {
            subject: scene,
            name: name,
            phone: phone,
            company: company,
            question: question,
            captcha: captchaCode,
        };

        fetch('/api/v1/feedback/contactus', {
            method: 'POST',
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify(contactData)
        }).then(response => response.json())
        .then(res => {
            if (res.status === true) {
                Message.success('提交成功');
                setSubmit(true);
            } else {
                Message.error(res.message);
                setCaptcha(Math.random());
                setCaptchaCode('');
            }
        });

        fetch('https://ufosdk.baidu.com/?m=Api&a=addfeedback', {
            method: "POST",
            headers: {
                "origin": "https://apollocloud.baidu-int.com/",
                //   "content-type": "multipart/form-data"
            },
            body: formdata
        })
            .then(res => {})
            .catch(err => {});
    };

    const checkSubmit = () => {
        if (scene === '') {
            setSceneState(false);
        }
        if (question === '') {
            setQuestionState(false);
        }
        if (name === '') {
            setNameState(false);
        }
        if (phone === '') {
            setPhoneState(false);
        }
        if (captchaCode === '') {
            setCaptchaState(false);
        }
        if (scene === '' || question === '' || name === '' || phone === '' || captchaCode === '') {
            Message.error('请填写完整信息');
            // console.log('Please enter')
        } else {
            submitData()
        }
    }
    return (
        <div className="contactBackground">
            <img className="contactTop" src={contactBg} />
            <div className="contactBottom"></div>
            {
                !submit &&
                <div className="contactSurvey">
                    <div className="surveyFormTitle">让我们与您联系</div>
                    <div className="surveyMiniTitle">留下您的联系方式，我们的专属顾问会在1个工作日内跟您联系</div>
                    <form className="surveyBody">
                        <label className="surveyItem">
                            <span className="surveyItemTitle">
                                <span className="surveyImportant">*</span>
                                应用场景
                            </span>
                            <input
                                type="text"
                                className={sceneState ? "surveyItemResult" : "surveyItemNoResult"}
                                placeholder="请简要描述具体应用场景"
                                value={scene}
                                onChange={(e) => {
                                    setScene(e.target.value);
                                    setSceneState(true);
                                }}
                            />
                        </label>
                        <label className="surveyItem">
                            <span className="surveyItemTitle">
                                <span className="surveyImportant">*</span>
                                咨询问题
                            </span>
                            <textarea
                                className={classNames("textArea",questionState ? "surveyItemResult": "surveyItemNoResult")}
                                placeholder="请将您的需求和关注点反馈给我们"
                                maxLength='400'
                                value={question}
                                onChange={(e) => {
                                    setQuestion(e.target.value);
                                    setQuestionState(true);
                                }}
                            />
                            <div className="textCount">{question.length}/400</div>
                        </label>
                        <label className="surveyItem">
                            <span className="surveyItemTitle name">
                                <span className="surveyImportant">*</span>
                                联系人
                            </span>
                            <input
                                type="text"
                                className={nameState ? "surveyItemResult" : "surveyItemNoResult"}
                                placeholder="请输入联系人姓名"
                                value={name}
                                onChange={(e) => {
                                    setName(e.target.value);
                                    setNameState(true);
                                }}
                            />
                        </label>
                        <label className="surveyItem">
                            <span className="surveyItemTitle">
                                <span className="surveyImportant">*</span>
                                联系电话
                            </span>
                            <input
                                type="text"
                                className={phoneState ? "surveyItemResult" : classNames("surveyItemErrorResult", " surveyItemNoResult")}
                                placeholder="请输入联系电话"
                                value={phone}
                                onChange={(e) => handlePhoneChange(e.target.value)}
                            />
                        </label>
                        <div className={phoneMsg === "" ? "trueNum" : "errorNum"}>{phoneMsg}</div>
                        <label className="surveyItem">
                            <span className="surveyItemTitle">
                                <span className="surveyUnimportant">*</span>
                                公司名称
                            </span>
                            <input
                                type="text"
                                className="surveyItemResult"
                                placeholder="请输入公司全称"
                                value={company}
                                onChange={(e) => setCompany(e.target.value)}
                            />
                        </label>
                        <label className="surveyItem">
                            <span className="surveyItemTitle">
                                <span className="surveyImportant">*</span>
                                验证码
                            </span>
                            <input
                                type="text"
                                className={captchaState ? "surveyCaptchaResult" : classNames("surveyCaptchaErrorResult", " surveyCaptchaNoResult")}
                                placeholder="请输入验证码"
                                value={captchaCode}
                                onChange={(e) => {
                                    setCaptchaCode(e.target.value);
                                    setCaptchaState(true);
                                }}
                            />
                            <img
                                src={`${imgUrl}/api/v1/captcha?${captcha}`}
                                onClick={() => setCaptcha(Math.random())}
                                className="captchaImg"
                            />
                        </label>
                    </form>
                    <button
                        className="surveySubmit"
                        onClick={() => checkSubmit()}
                    >
                        提交
                    </button>
                </div>
            }
            {
                submit &&
                <div className="contactSurvey">
                    <div className="surveySuccess">
                        <img className="successIcon" src={successIcon} />
                        <div className="successTitle">提交成功</div>
                        <div className="successDesc">感谢您的支持，我们会尽快与您联系</div>
                        <button className="successBtn" onClick={() => navigate('/')}>确定</button>
                    </div>
                </div>
            }
        </div>
    )
}

export default Contact;
