import bannerImg from '../../../assets/images/scenelibrary/banner.png';
import featureImg1 from '../../../assets/images/scenelibrary/featureImg1.png';
import featureActiveImg1 from '../../../assets/images/scenelibrary/featureActiveImg1.png';
import featureImg2 from '../../../assets/images/scenelibrary/featureImg2.png';
import featureActiveImg2 from '../../../assets/images/scenelibrary/featureActiveImg2.png';
import featureImg3 from '../../../assets/images/scenelibrary/featureImg3.png';
import featureActiveImg3 from '../../../assets/images/scenelibrary/featureActiveImg3.png';
import carouselImg1 from '../../../assets/images/scenelibrary/carousel1.png';
import carouselImg2 from '../../../assets/images/scenelibrary/carousel2.png';
import carouselImg3 from '../../../assets/images/scenelibrary/carousel3.png';
import carouselImg4 from '../../../assets/images/scenelibrary/carousel4.png';
import carouselIcon1 from '../../../assets/images/scenelibrary/carouselIcon1.png';
import carouselIcon2 from '../../../assets/images/scenelibrary/carouselIcon2.png';
import carouselIcon3 from '../../../assets/images/scenelibrary/carouselIcon3.png';
import carouselIcon4 from '../../../assets/images/scenelibrary/carouselIcon4.png';
import scenariosImg1 from '../../../assets/images/scenelibrary/scenarios1.png';


export const bannerData = {
    title: '场景库',
    description: ['基于真实&虚拟数据，通过场景挖掘、场景编辑能力，构建高价值可用的自动驾驶场景库，支持PB级以上场景数据存储，方便实线数据共享，高效检索处理。'],
    img: bannerImg,
    contact: true,
};

export const menuData = [
    {
        id: 'feature',
        title: '产品功能介绍',
    },
    {
        id: 'advantage',
        title: '产品优势',
    },
    {
        id: 'scenarios',
        title: '应用场景',
    }
];

export const featureData = {
    id: 'feature',
    title: '产品功能',
    children: [
        {
            id: 0,
            img: featureImg1,
            activeImg: featureActiveImg1,
            title: '语义场景库&管理',
            desc: '基于百度多年概念分层场景设计方法，结合自动驾驶实际应用需求，将自动驾驶场景设计为语义级场景 (语义场景=功能场景+逻辑场景)，形成场景设计文件，驱动场景搭建。当前覆盖城市、高速、园区三大场景，可支持语义场景库的灵活管理。',
        },
        {
            id: 1,
            img: featureImg2,
            activeImg: featureActiveImg2,
            title: '场景管理',
            desc: '提高场景的测试效率，自动化对相同特征信息场景进行聚类，生成场景集。支持依照车型进行目录分类、标签分类管理，支持场景统计，可全览数量变化趋势。',
        },
        {
            id: 2,
            img: featureImg3,
            activeImg: featureActiveImg3,
            title: '场景开放',
            desc: '发布“1-X”数据开放战略，开放百度内部高价值场景数据，实现200万+场景开放（源自千万公里路测数据挖掘及百万仿真工程师构建而成）。',
        }
    ]
}

export const advantageData = {
    title: '产品优势',
    id: 'advantage',
    items: [
        {
            id: 0,
            title: '高价值场景开放',
            desc: '八年积累沉淀千级逻辑场景，千万级case场景，对外脱敏开放。',
            img: carouselImg1,
            icon: carouselIcon1,
        },
        {
            id: 1,
            title: '场景孵化',
            desc: '成熟的场景孵化机制，人工设计+挖掘双向驱动，场景覆盖率>95%。',
            img: carouselImg2,
            icon: carouselIcon2,
        },
        {
            id: 2,
            title: '场景跟踪评价',
            desc: 'ADS的生命周期管理，包括新建、质检、评价、召回、修复完善。',
            img: carouselImg3,
            icon: carouselIcon3,
        },
        {
            id: 3,
            title: '多层级管理',
            desc: '灵活的场景管理工具，可实现多层级多权限管理。',
            img: carouselImg4,
            icon: carouselIcon4,
        },
    ]
};

export const scenariosData = {
    title: '应用场景',
    id: 'scenarios',
    img: scenariosImg1,
    scenarios: [
        {
            id: 0,
            title: '自动驾驶能力测试评估',
            img: scenariosImg1,
            desc: '对自动驾驶模型进行海量真实&极端场景测试，保证模型能力稳定性与准确性达到上线标准。',
        },
        {
            id: 1,
            title: '场景教学实训',
            img: scenariosImg1,
            desc: '面向产学研机构，构建教育实训一体机，培养高校学生对自动驾驶场景认知与搭建能力。',
        },
    ]
};

