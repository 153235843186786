import React, {Component} from 'react';
import './banner.css';
import BannerBox from './bannerbox';

/**
 * 顶部 banner
 */
class Banner extends Component {
    render() {
        return (
            <BannerBox
                cancleTransition={this.props.cancleTransition}
                items={this.props.items}
                delay={5}
                speed={2}
                autoplay
                dots
                className={'banner-box'}
            />
        );
    }
}

export default Banner;
