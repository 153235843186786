import dataImg from '../../assets/images/aboutus/data.png';
import testImg from '../../assets/images/aboutus/test.png';
import developImg from '../../assets/images/aboutus/develop.png';
import operationImg from '../../assets/images/aboutus/operation.png';
import supervisionImg from '../../assets/images/aboutus/supervision.png';


export const productData = [
    {
        id: 0,
        title: '数据平台',
        backgroundImage: dataImg,
        detail: [
            {
                detailId: 0,
                name: '数据采集',
                content: [
                    {
                        contentId: 0,
                        name: '采集计划',
                        link: '/product/collectionplan'
                    },
                    {
                        contentId: 1,
                        name: '采集车队',
                        link: ''
                    }
                ]
            },
            {
                detailId: 1,
                name: '数据传输',
                content: [
                    {
                        contentId: 0,
                        name: '落盘数据上传',
                        link: '/product/offline'
                    },
                    {
                        contentId: 1,
                        name: '实时数据回传',
                        link: ''
                    }
                ]
            },
            {
                detailId: 2,
                name: '数据标注',
                content: [
                    {
                        contentId: 0,
                        link: '/product/annotationtool',
                        name: '标注工具'
                    },
                    {
                        contentId: 1,
                        link: '',
                        name: '算法能力'
                    },
                    {
                        contentId: 2,
                        link: '/product/visualization',
                        name: '可视化配置'
                    },
                    {
                        contentId: 3,
                        link: '',
                        name: '标注流程自动化'
                    },
                    {
                        contentId: 4,
                        link: '',
                        name: '标注管理'
                    },
                ],
            },
            {
                detailId: 3,
                name: '数据管理',
                content: [
                    {
                        contentId: 0,
                        name: '智驾数据集',
                        link: '/product/drivingdataset'
                    },
                    {
                        contentId: 1,
                        name: '源数据管理',
                        link: ''
                    },
                    {
                        contentId: 2,
                        name: '高精地图',
                        link: ''
                    },
                    {
                        contentId: 3,
                        name: '工作流引擎',
                        link: '/product/workflow'
                    },
                    {
                        contentId: 4,
                        name: '数据湖管理与分析',
                        link: ''
                    }
                ]
            },
            {
                detailId: 4,
                name: '数据应用',
                content: [
                    {
                        contentId: 0,
                        name: '数据回放',
                        link: '/product/issuefinder'
                    },
                    {
                        contentId: 1,
                        name: '数据闭环',
                        link: ''
                    },
                    {
                        contentId: 2,
                        name: '数据挖掘',
                        link: ''
                    }
                ]
            },
        ]
    },
    {
        id: 1,
        title: '研发平台',
        backgroundImage: developImg,
        detail: [
            {
                detailId: 0,
                name: 'AI训练',
                content: [
                    {
                        contentId: 0,
                        link: '/product/aitrain',
                        name: '模型训练'
                    },
                    {
                        contentId: 1,
                        link: '',
                        name: '模型评测'
                    },
                    {
                        contentId: 2,
                        link: '',
                        name: '模型仓库'
                    },
                    {
                        contentId: 3,
                        link: '',
                        name: '数据集管理'
                    },
                    {
                        contentId: 4,
                        link: '/product/badcase',
                        name: 'badcase闭环'
                    },
                ]
            },
            {
                detailId: 1,
                name: 'DevOps',
                content: [
                    {
                        contentId: 0,
                        link: '',
                        name: '项目管理'
                    },
                    {
                        contentId: 1,
                        link: '',
                        name: '代码服务'
                    },
                    {
                        contentId: 2,
                        link: '',
                        name: '持续交付'
                    },
                ],
            },
            {
                detailId: 2,
                name: 'Apollo研发',
                content: [
                    {
                        contentId: 0,
                        link: '',
                        name: '环境运行'
                    },
                    {
                        contentId: 1,
                        link: '',
                        name: '代码部署'
                    },
                    {
                        contentId: 2,
                        link: '',
                        name: 'OTA'
                    },
                ],
            },
        ]
    },
    {
        id: 2,
        title: '仿真平台',
        backgroundImage: testImg,
        detail: [
            {
                detailId: 0,
                name: '自动驾驶综合测试',
                content: [
                    {
                        contentId: 0,
                        link: '/product/sil',
                        name: '软件在环SIL'
                    },
                    {
                        contentId: 1,
                        link: '',
                        name: '硬件在环HIL'
                    },
                    {
                        contentId: 2,
                        link: '',
                        name: '车辆在环VIL'
                    },
                    {
                        contentId: 3,
                        link: '',
                        name: '无人化测试场'
                    },
                ]
            },
            {
                detailId: 1,
                name: '场景',
                content: [
                    {
                        contentId: 0,
                        link: '/product/scenelibrary',
                        name: '场景库'
                    },
                    {
                        contentId: 1,
                        link: '/product/scenemining',
                        name: '场景挖掘'
                    },
                    {
                        contentId: 2,
                        link: '/product/sceneplatform',
                        name: '场景设计平台'
                    },
                ]
            },
            {
                detailId: 2,
                name: '仿真验证',
                content: [
                    {
                        contentId: 0,
                        link: '/product/designsimulation',
                        name: '人工设计仿真'
                    },
                    {
                        contentId: 1,
                        link: '/product/playback',
                        name: '数据回放仿真'
                    },
                    {
                        contentId: 2,
                        link: '',
                        name: '真实数据重建仿真'
                    },
                    {
                        contentId: 3,
                        link: '',
                        name: '虚拟城市仿真'
                    },
                    {
                        contentId: 4,
                        link: '',
                        name: '传感器仿真'
                    },
                ],
            },
            {
                detailId: 3,
                name: '测试管理',
                content: [
                    {
                        contentId: 0,
                        link: '',
                        name: '离线测试管理'
                    },
                    {
                        contentId: 1,
                        link: '',
                        name: '封闭场地测试管理'
                    },
                    {
                        contentId: 2,
                        link: '',
                        name: '开放道路测试管理'
                    },
                ],
            },
            {
                detailId: 4,
                name: '评估',
                content: [
                    {
                        contentId: 0,
                        link: '',
                        name: '能力评估'
                    },
                    {
                        contentId: 1,
                        link: '/product/standardmetrics',
                        name: '标准化度量指标'
                    },
                    {
                        contentId: 2,
                        link: '',
                        name: '评估报告'
                    },
                ],
            },
        ]
    },
    {
        id: 3,
        title: '运营平台',
        backgroundImage: operationImg,
        detail: [
            {
                detailId: 0,
                name: '运营资源管理',
                content: [
                    {
                        contentId: 0,
                        link: '/product/autocarmanage',
                        name: '无人车资源管理'
                    },
                    {
                        contentId: 1,
                        link: '',
                        name: '运营区域信息管理'
                    },
                    {
                        contentId: 2,
                        link: '',
                        name: '项目资源管理'
                    },
                ]
            },
            {
                detailId: 1,
                name: '车辆运营管理',
                content: [
                    {
                        contentId: 0,
                        link: '/product/planmanage',
                        name: '出车计划管理'
                    },
                    {
                        contentId: 1,
                        link: '',
                        name: '车辆调度管理'
                    },
                    {
                        contentId: 2,
                        link: '',
                        name: '运营订单管理'
                    },
                ],
            },
            {
                detailId: 2,
                name: '车辆运营统计',
                content: [
                    {
                        contentId: 0,
                        link: '',
                        name: '车辆使用统计'
                    },
                    {
                        contentId: 1,
                        link: '',
                        name: '订单信息统计'
                    },
                    {
                        contentId: 2,
                        link: '',
                        name: '运营里程统计'
                    },
                    {
                        contentId: 3,
                        link: '',
                        name: '自动驾驶指标统计'
                    },
                ]
            },
        ]
    },
    {
        id: 4,
        title: '监管平台',
        backgroundImage: supervisionImg,
        detail: [
            {
                detailId: 0,
                name: '车辆监控',
                content: [
                    {
                        contentId: 0,
                        link: '',
                        name: '车载单元集成维护'
                    },
                    {
                        contentId: 1,
                        link: '',
                        name: '数据获取和上传'
                    },
                ]
            },
            {
                detailId: 1,
                name: '路侧监控',
                content: [
                    {
                        contentId: 0,
                        link: '',
                        name: '设备部署及维护'
                    },
                    {
                        contentId: 1,
                        link: '',
                        name: '路侧设备监控'
                    },
                    {
                        contentId: 2,
                        link: '',
                        name: '路侧数据采集'
                    },
                ]
            },
            {
                detailId: 2,
                name: '信息融合',
                content: [
                    {
                        contentId: 0,
                        link: '',
                        name: '高精地图路网管理'
                    },
                    {
                        contentId: 1,
                        link: '',
                        name: '交通规则数据管理'
                    },
                    {
                        contentId: 2,
                        link: '',
                        name: '测试牌照管理'
                    },
                    {
                        contentId: 3,
                        link: '',
                        name: '测试计划管理'
                    },
                ]
            },
            {
                detailId: 3,
                name: '管控服务',
                content: [
                    {
                        contentId: 0,
                        link: '',
                        name: '测试运营数据分析'
                    },
                    {
                        contentId: 1,
                        link: '',
                        name: '行驶效果监控'
                    },
                    {
                        contentId: 2,
                        link: '',
                        name: '车辆状态监控'
                    },
                    {
                        contentId: 3,
                        link: '',
                        name: '违章行为监控'
                    },
                ]
            },
        ]
    }
];

export const solutionData = [
    {
        id: 0,
        title: '数据闭环解决方案',
        description: '提供自动驾驶数据采集、处理、标注、管理、应用的一站式服务',
        link: '/solution/data'
    },
    {
        id: 1,
        title: 'AI研发解决方案',
        description: '一站式研发训练平台，快速构建自动驾驶AI能力',
        link: '/solution/develop'
    },
    {
        id: 2,
        title: '数据采集标注解决方案',
        description: '支持灵活模版配置和自动化筛选的业内最强实践全标注能力云端标注工具',
        link: '/solution/annotation'
    },
    {
        id: 3,
        title: '云仿真测试解决方案',
        description: '提供真实世界交通场景下的大规模并行仿真测试服务',
        link: '/solution/test'
    },
    {
        id: 4,
        title: '运营监管解决方案',
        description: '为自动驾驶车队商业化提供一站式运营和监管解决方案',
        link: '/solution/operation'
    },
    {
        id: 5,
        title: '数据智搜解决方案',
        description: '基于百度大模型能力，在自动驾驶场景下提供长尾数据发掘的解决方案',
        link: '/solution/search'
    },
    {
        id: 6,
        title: '智驾合规解决方案',
        description: '脱敏脱密监管与安全审校一体化，渗透式保障智驾链路强合规',
        link: '/solution/legal'
    }
];


