export const throtte = (fn, wait) => {
    let prev = new Date();
    return function () {
        const now = new Date();
        if (now - prev > wait) {
            fn.apply(this, arguments);
            prev = new Date();
        }
    };
};

// table hover toggle
export const tabjs = (e, dom) => {
    const current = e.target.getAttribute('data-index');
    const tabDom = dom.querySelectorAll('.tab li');
    const conDom = dom.querySelectorAll('.con li');
    if (current) {
        Array.from(conDom).forEach((item, index) => {
            item.style.display = 'none';
            tabDom[index].setAttribute('class', '');
        });
        conDom[current].style.display = 'block';
        tabDom[current].setAttribute('class', 'hover');
    }
};
// navFixed
export const navFixed = () => {
    const navBody = document.getElementById('header');
    const navBodyBar = document.getElementById('headerBar');
    const adjustNav = function () {
        if (window.location.pathname !== '/') {
            navBody.style.position = 'fixed';
            navBodyBar.style.background = '#fff';
            navBodyBar.style.color = '#16181a';
        } else {
            const currentY = document.documentElement.scrollTop || document.body.scrollTop;
            if (currentY > 80) {
                navBody.style.position = 'fixed';
                navBodyBar.style.background = '#fff';
                navBodyBar.style.color = '#16181a';
            } else {
                navBody.style.position = 'absolute';
                navBodyBar.style.background = '';
                navBodyBar.style.color = '';
            }
        }
    };
    window.addEventListener('scroll', adjustNav);
    adjustNav();
};
// headerfixed
export const navFn = top => {
    const $step = document.querySelectorAll('.steps');
    const navDom = document.querySelector('.HeaderFixed');
    const navDomSpan = navDom.querySelectorAll('span');
    const navDomTop = top;
    navDomSpan[0].setAttribute('class', 'hover');
    navDomSpan.forEach((item, index) => {
        item.setAttribute('data-index', index);
    });
    const adjustLeftFloat = function () {
        const arr = [0];
        let cur = 0;
        let currentY =
            document.documentElement.scrollTop || document.body.scrollTop;
        currentY = currentY + 50;
        if (navDomTop >= currentY) {
            navDom.style.position = 'static';
            navDom.style.marginTop = '0px';
        } else {
            navDom.style.position = 'fixed';
            navDom.style.marginTop = '60px';
        }
        $step.forEach((item, index) => {
            if (currentY > parseInt(item.offsetTop) - 150) {
                if (index !== 0) {
                    arr.push(index);
                }
            }
        });
        if (arr.length) {
            cur = Math.max.apply(Math, arr);
        }
        navDomSpan.forEach((item, index) => {
            item.setAttribute('class', '');
        });
        if (arr.length) {
            navDomSpan[cur].setAttribute('class', 'hover');
        }
    };
    // 监听滚动
    window.addEventListener('scroll', adjustLeftFloat);
    // click 回滚
    navDom.addEventListener('click', e => {
        window.removeEventListener('scroll', adjustLeftFloat);
        const getIndex = e.target.getAttribute('data-index');
        // console.log("getIndex", getIndex);
        // navDomSpan.forEach((item, index) => {
        //   if (index === parseInt(getIndex)) {mei
        //     item.setAttribute("class", "hover");
        //   } else {
        //     item.setAttribute("class", "");
        //   }
        // });
        // 根据nav上每个tab的step进行点击跳转
        if (getIndex && $step[parseInt(getIndex)].offsetTop) {
            const targetY = $step[parseInt(getIndex)].offsetTop - 150;
            scrollAnimation(targetY);
        }

    });
    // animate
    let timer = null;
    function scrollAnimation(targetY) {
        clearInterval(timer);
        let currentY =
            document.documentElement.scrollTop || document.body.scrollTop;

        const needScrollTop = targetY - currentY;

        timer = setInterval(() => {
            let dist = Math.ceil(needScrollTop / 20);
            dist = dist > 0 ? Math.ceil(dist) : Math.floor(dist);

            if (Math.abs(currentY - targetY) < 20) {
                clearInterval(timer);
            } else {
                window.scrollTo(0, currentY + dist);
            }

            currentY = currentY + dist;
            window.addEventListener('scroll', adjustLeftFloat);
        }, 20);

    }
    adjustLeftFloat();
};
