import bannerImg from '../../../assets/images/workflow/banner.png';
import featureImg1 from '../../../assets/images/workflow/featureImg1.png';
import featureActiveImg1 from '../../../assets/images/workflow/featureActiveImg1.png';
import featureImg2 from '../../../assets/images/workflow/featureImg2.png';
import featureActiveImg2 from '../../../assets/images/workflow/featureActiveImg2.png';
import featureImg3 from '../../../assets/images/workflow/featureImg3.png';
import featureActiveImg3 from '../../../assets/images/workflow/featureActiveImg3.png';
import carouselImg1 from '../../../assets/images/workflow/carousel1.png';
import carouselImg2 from '../../../assets/images/workflow/carousel2.png';
import carouselImg3 from '../../../assets/images/workflow/carousel3.png';
import carouselIcon1 from '../../../assets/images/workflow/carouselIcon1.png';
import carouselIcon2 from '../../../assets/images/workflow/carouselIcon2.png';
import carouselIcon3 from '../../../assets/images/workflow/carouselIcon3.png';
import scenariosImg1 from '../../../assets/images/workflow/scenarios1.png';



export const bannerData = {
    title: '工作流引擎',
    description: ['基于百度智能驾驶多产品线数据处理经验，打造灵活可扩展的工作流引擎，支持自定义算子、流程配置、任务调度及监控，满足多维数据组织、再加工等场景。'],
    img: bannerImg,
    contact: true,
};

export const menuData = [
    {
        id: 'feature',
        title: '产品功能介绍',
    },
    {
        id: 'advantage',
        title: '产品优势',
    },
    {
        id: 'scenarios',
        title: '应用场景',
    },
];

export const featureData = {
    id: 'feature',
    title: '产品功能',
    children: [
        {
            id: 0,
            img: featureImg1,
            activeImg: featureActiveImg1,
            title: '工作流画板',
            desc: '画板将工具、资源通过连线方式组合成工作流，支持拖拽协助式地创建工作流，在画板内可对资源、工具、连线进行增删改操作，满足数据质检、清洗、融合等场景。',
        },
        {
            id: 1,
            img: featureImg2,
            activeImg: featureActiveImg2,
            title: '算子仓库',
            desc: '提供100+算子模版，可根据模板实现自定义算子开发，满足个性化场景下的算子扩展需求。',
        },
        {
            id: 2,
            img: featureImg3,
            activeImg: featureActiveImg3,
            title: '工作流空间',
            desc: '支持以项目纬度对工作流任务做细分管理，并将用户按照权限进行划分隔离，每个项目可以单独配置可读、可写、管理三种角色，满足项目的灵活性、安全性等场景。',
        }
    ]
}

export const advantageData = {
    title: '产品优势',
    id: 'advantage',
    items: [
        {
            id: 0,
            title: '资源丰富',
            desc: '工作流引擎对公司内各个业务线的数据处理场景进行统一运维和资源管理，提供资源的利用率，降低用户的使用成本。',
            img: carouselImg1,
            icon: carouselIcon1,
        },
        {
            id: 1,
            title: '功能全面',
            desc: '提供数据全流程处理服务，支持统一的资源调度、算子管理、日志管理等服务，致力于建设高效率、高算力、高稳定性的工作流计算平台。',
            img: carouselImg2,
            icon: carouselIcon2,
        },
        {
            id: 2,
            title: '使用高效',
            desc: '工作流作业执行过程、执行时间可配置，用户定义完工作流逻辑后，自动帮用户完成整个流程的执行，并可视化直观呈现流程进展，持续赋能数据处理。',
            img: carouselImg3,
            icon: carouselIcon3,
        },
    ]
};

export const scenariosData = {
    title: '应用场景',
    id: 'scenarios',
    img: scenariosImg1,
    scenarios: [
        {
            id: 0,
            title: '数据送标与回灌',
            img: scenariosImg1,
            desc: '感知算法迭代需要用到大量标注数据，工作流引擎支持原始数据送标、标注结果回灌、数据流程管理等能力，以推动数据在标注服务和数据服务之间的流转，从而连通两个平台。',
        },
        {
            id: 1,
            title: '数据质检',
            img: scenariosImg1,
            desc: '自动驾驶车辆每天都会产生海量数据，数据中存在大量黑图、画图、点云扭曲、断层、混乱等问题，工作流引擎支持对采集或路测数据质量进行检查，过滤掉问题数据，提升数据质量。',
        },
        {
            id: 2,
            title: '数据融合',
            img: scenariosImg1,
            desc: '自动驾驶数据来自传感器、摄像头等多种采集设备，不同设备产生的数据无法关联使用，通过数据融合可以提取图像、点云，构造2D3D融合数据，便于数据进一步加工和使用。',
        },
        {
            id: 3,
            title: '数据解算',
            img: scenariosImg1,
            desc: '提供原始数据包的计算和提取功能算子，支持多种数据类型和格式，数据类型包括视频、点云、自动驾驶数据包以及设备数据的计算提取，支持自定义数据解算。',
        },
    ]
};


