import './style.css';

const Card = ({ title, description, img, bgColor, direction }) => {
    return (
        <>
            <div
                className="functionCard"
                style={{
                    background: `${bgColor}`,
                    flexDirection: `${direction}`,
                }}
            >
                <div className="functionCardLeft">
                    <div className="functionCardLeftTitle">{title}</div>
                    <div className="functionCardLeftDescription">{description}</div>
                </div>
                <div className="functionCardRight">
                    <img src={img} alt="" className="functionCardImg" />
                </div>
            </div>
        </>
    );
};

export default Card;
