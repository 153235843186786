import bannerImg from '../../../assets/images/designsimulation/banner.png';
import featureImg1 from '../../../assets/images/designsimulation/featureImg1.png';
import featureActiveImg1 from '../../../assets/images/designsimulation/featureActiveImg1.png';
import featureImg2 from '../../../assets/images/designsimulation/featureImg2.png';
import featureActiveImg2 from '../../../assets/images/designsimulation/featureActiveImg2.png';
import featureImg3 from '../../../assets/images/designsimulation/featureImg3.png';
import featureActiveImg3 from '../../../assets/images/designsimulation/featureActiveImg3.png';
import featureImg4 from '../../../assets/images/designsimulation/featureImg4.png';
import featureActiveImg4 from '../../../assets/images/designsimulation/featureActiveImg4.png';
import carouselImg1 from '../../../assets/images/designsimulation/carousel1.png';
import carouselImg2 from '../../../assets/images/designsimulation/carousel2.png';
import carouselImg3 from '../../../assets/images/designsimulation/carousel3.png';
import carouselImg4 from '../../../assets/images/designsimulation/carousel4.png';
import carouselIcon1 from '../../../assets/images/designsimulation/carouselIcon1.png';
import carouselIcon2 from '../../../assets/images/designsimulation/carouselIcon2.png';
import carouselIcon3 from '../../../assets/images/designsimulation/carouselIcon3.png';
import carouselIcon4 from '../../../assets/images/designsimulation/carouselIcon4.png';
import scenariosImg1 from '../../../assets/images/designsimulation/scenarios1.png';


export const bannerData = {
    title: '人工设计仿真',
    description: ['通过道路+场景+车辆&传感器工具搭建场景，运用仿真技术在虚拟场景环境中重现车辆驾驶过程，面向待测模块做细致度量，快速验证版本好坏。'],
    img: bannerImg,
    contact: true,
};

export const menuData = [
    {
        id: 'feature',
        title: '产品功能介绍',
    },
    {
        id: 'advantage',
        title: '产品优势',
    },
    {
        id: 'scenarios',
        title: '应用场景',
    }
];

export const featureData = {
    id: 'feature',
    title: '产品功能',
    children: [
        {
            id: 0,
            img: featureImg1,
            activeImg: featureActiveImg1,
            title: '场景设计工具',
            desc: '构建与自动驾驶系统相互作用的内外部因素和条件，从而能在不同程度上模拟自动驾驶车辆面临的多种场景。',
        },
        {
            id: 1,
            img: featureImg2,
            activeImg: featureActiveImg2,
            title: '一键测试',
            desc: '测试执行、结果分析一键完成，使测试效率有了大幅提升。',
        },
        {
            id: 2,
            img: featureImg3,
            activeImg: featureActiveImg3,
            title: '参数泛化仿真',
            desc: '提供参数泛化能力，以支持构造大量相似场景进行能力边界实验。',
        },
        {
            id: 3,
            img: featureImg4,
            activeImg: featureActiveImg4,
            title: '自动化结果',
            desc: '系统可自动收集任务执行结果并对任务执行过程中的重要监控项做收集和分析产出报表。',
        }
    ]
}

export const advantageData = {
    title: '产品优势',
    id: 'advantage',
    items: [
        {
            id: 0,
            title: '易用性界面',
            desc: '可帮助工程师实现快速验证，快速调试，快速输出结果。',
            img: carouselImg1,
            icon: carouselIcon1,
        },
        {
            id: 1,
            title: '大规模运行',
            desc: '百度云仿真平台提供提供稳定、高效的云仿真集群能力支持用户大规模的测试需要。',
            img: carouselImg2,
            icon: carouselIcon2,
        },
        {
            id: 2,
            title: '高通用性',
            desc: '除了运行测试之外，还可以灵活定制其他运行插件，具有很高的通用性。',
            img: carouselImg3,
            icon: carouselIcon3,
        },
        {
            id: 3,
            title: '多维度量方式',
            desc: '提供不同评价方式，让解问题更聚焦，问题定位更加准确。',
            img: carouselImg4,
            icon: carouselIcon4,
        },
    ]
};

export const scenariosData = {
    title: '应用场景',
    id: 'scenarios',
    img: scenariosImg1,
    scenarios: [
        {
            id: 0,
            title: '算法调试过程',
            img: scenariosImg1,
            desc: '关注研发阶段开发具体功能时遇到的单一场景，可通过编辑器搭建环境，快速验证所开发功能好坏，主要存在形式为单机仿真形式。',
        },
        {
            id: 1,
            title: '自动驾驶算法迭代',
            img: scenariosImg1,
            desc: '人工设计仿真后生成的指标报告，确定版本的准出条件，实现道路测试版本发布。',
        },
    ]
};

