import React from 'react';
// import deploymentData from './data';
import './style.css';


import privateCloud from '../../../../assets/images/home/privatecloud.png';
import publicCloud from '../../../../assets/images/home/publiccloud.png';
import hybridCloud from '../../../../assets/images/home/hybridcloud.png';

const Deployment = () => {
    return (
        <div className="deployment">
            <div className="deploymentTitle">灵活部署  按需选择</div>
            <div className="deploymentContent">
                <div className="deploymentCard">
                    <div className="deploymentCardCon">
                        <div className="deploymentCardTitle">公有云</div>
                        <div className="deploymentCardDetails">
                            <div className="deploymentCardDetail">
                                <div className="deploymentCardDetailImg"></div>
                                <div>简单可用</div>
                            </div>
                            <div className="deploymentCardDetail">
                                <div className="deploymentCardDetailImg"></div>
                                <div>服务稳定</div>
                            </div>
                        </div>
                    </div>
                    <img className="deploymentIcon" src={publicCloud} alt="icon" />
                </div>
                <div className="deploymentCard">
                    <div className="deploymentCardCon">
                        <div className="deploymentCardTitle">私有云</div>
                        <div className="deploymentCardDetails">
                            <div className="deploymentCardDetail">
                                <div className="deploymentCardDetailImg"></div>
                                <div>数据可控</div>
                            </div>
                            <div className="deploymentCardDetail">
                                <div className="deploymentCardDetailImg"></div>
                                <div>高安全性</div>
                            </div>
                        </div>
                    </div>
                    <img className="deploymentIcon" src={privateCloud} alt="icon" />
                </div>
                <div className="deploymentCard">
                    <div className="deploymentCardCon">
                        <div className="deploymentCardTitle">混合云</div>
                        <div className="deploymentCardDetails">
                            <div className="deploymentCardDetail">
                                <div className="deploymentCardDetailImg"></div>
                                <div>灵活扩展</div>
                            </div>
                            <div className="deploymentCardDetail">
                                <div className="deploymentCardDetailImg"></div>
                                <div>高性价比</div>
                            </div>
                        </div>
                    </div>
                    <img className="deploymentIcon" src={hybridCloud} alt="icon" />
                </div>
            </div>
        </div>
    );
}

export default Deployment;
