import SolutionBanner from '../../../components/SolutionBanner';
import SolutionMenu from '../../../components/SolutionMenu';
import ProductFeatures from '../../../components/ProductFeatures';
import ProductCarousel from '../../../components/ProductCarousel';
import ProductScenarios from '../../../components/ProductScenarios';
import {bannerData, menuData, featureData, advantageData, scenariosData} from './data';

const SceneLibrary = () => {
    return (
        <div>
            <SolutionBanner {...bannerData} />
            <SolutionMenu menuData={menuData} />
            <ProductFeatures {...featureData} />
            <ProductCarousel {...advantageData} />
            <ProductScenarios {...scenariosData} />
        </div>
    );
};

export default SceneLibrary;

