import bannerImg from '../../../assets/images/visualization/banner.png';
import featureImg1 from '../../../assets/images/visualization/featureImg1.png';
import simulationActiveImg from '../../../assets/images/visualization/featureActiveImg1.png';
import featureImg2 from '../../../assets/images/visualization/featureImg2.png';
import featureActiveImg2 from '../../../assets/images/visualization/featureActiveImg2.png';
import featureImg3 from '../../../assets/images/visualization/featureImg3.png';
import featureActiveImg3 from '../../../assets/images/visualization/featureActiveImg3.png';
import carouselImg1 from '../../../assets/images/visualization/carousel1.png';
import carouselImg2 from '../../../assets/images/visualization/carousel2.png';
import carouselIcon1 from '../../../assets/images/visualization/carouselIcon1.png';
import carouselIcon2 from '../../../assets/images/visualization/carouselIcon2.png';
import scenariosImg from '../../../assets/images/visualization/scenarios.png';

export const bannerData = {
    title: '可视化配置',
    description: ['本产品针对不同场景下的数据标注服务需求，可进行页面级标注规则的配置，操作简易。'],
    img: bannerImg,
    contact: true,
};

export const menuData = [
    {
        id: 'feature',
        title: '产品功能介绍',
    },
    {
        id: 'advantage',
        title: '产品优势',
    },
    {
        id: 'scenarios',
        title: '应用场景',
    }
];

export const featureData = {
    id: 'feature',
    title: '产品功能',
    children: [
        {
            id: 0,
            img: featureImg1,
            activeImg: simulationActiveImg,
            title: '任务分配配置',
            desc: '针对大批量数据标注需求，可配置多团队协作标注，并根据标注规则进行多轮次流程配置。',
        },
        {
            id: 1,
            img: featureImg2,
            activeImg: featureActiveImg2,
            title: '标注规则配置',
            desc: '针对不同的业务场景，可配置不同的标注工具、属性信息，标注功能来进行标注作业。',
        },
        {
            id: 2,
            img: featureImg3,
            activeImg: featureActiveImg3,
            title: '公共模板配置',
            desc: '针对共性的业务场景，可抽象通用组件进行标注模板创建，提高工具的可复用性，提高配置效率。',
        }
    ]
}

export const advantageData = {
    title: '产品优势',
    id: 'advantage',
    items: [
        {
            id: 0,
            title: '操作简易型',
            desc: '标注规则配置全部实现页面可视化操作，操作难度低，内容展示直观易用性强。',
            img: carouselImg1,
            icon: carouselIcon1,
        },
        {
            id: 1,
            title: '内容丰富性',
            desc: '可根据标注需求进行『标注方式』『属性信息』『标注流程』等多维度的规则配置，适用场景丰富。',
            img: carouselImg2,
            icon: carouselIcon2,
        },
    ]
};

export const scenariosData = {
    title: '应用场景',
    id: 'scenarios',
    img: scenariosImg,
    scenarios: [
        {
            id: 0,
            title: '预置模板配置',
            img: scenariosImg,
            desc: '提供预置标注模板，针对常规标注场景可使用预置模板进行标注规则创建。',
        },
        {
            id: 1,
            title: '自定义规则配置',
            img: scenariosImg,
            desc: '针对特定信息或指定case标注的场景下的标注需求，产品支持创建自定义标注规则进行标注。',
        }
    ]
};

