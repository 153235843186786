import {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import './style.css';


const ProductsHeader = ({HeaderData, setShowProducts,setWhiteHeader,setActive}) => {
    // 产品下拉选项
    const [productActive, setProductActive] = useState(0);
    const navigate = useNavigate();

    return (
        <div
            className="headerContent"
            onMouseLeave={() => {
                setShowProducts(false);
                setWhiteHeader(false);
                setActive('');
            }}
        >
            <img className="contentImg" src={HeaderData[productActive].backgroundImage} alt="img" />
            <div className="headerContentLeft">
                {
                    HeaderData.map(header => {
                        return (
                            <div
                                key={header.id}
                                onClick={() => setProductActive(header.id)}
                                className="headerTitles"
                            >
                                {
                                    header.id === productActive ?
                                        <>
                                            <div className="headerTitleActive">{header.title}</div>
                                            <div className="titleSelected"></div>
                                        </>
                                        :
                                        <div className="headerTitle">{header.title}</div>
                                }
                            </div>
                        )
                    })
                }
            </div>
            <div className="headerContentRight">
                {
                    HeaderData[productActive].detail.map(product => {
                        return (
                            <div className="headerProduct" key={product.detailId}>
                                <div className="headerProductTitle">{product.name}</div>
                                <div className="headerProductDetails">
                                    {
                                        product.content.map(content => {
                                            return (
                                                <span
                                                    className={
                                                        content.link ? "productDetail" : "productDetailNoLink"
                                                    }
                                                    key={content.contentId}
                                                    onClick={() => navigate(content.link)}
                                                    // href={content.link !== '' ? content.link : 'javascript:void(0);'}
                                                    // rel="noreferrer"
                                                    // target="_blank"
                                                >
                                                    {content.name}
                                                </span>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
};

export default ProductsHeader;
